import React, { useEffect, useRef, useState } from "react";
import "./BoatBookingRequest.css";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Skeleton,
} from "@mui/material";
import Footer from "../../../Component/Footer/Footer";
import {
  ArrowBack,
  ArrowForward,
  Cancel,
  Done,
  Edit,
  Fullscreen,
} from "@material-ui/icons";
import moment, { weekdays } from "moment";
import WeekDays from "../../Common/WeekDays";
import IMAGES from "../../Images";
import { PageHeader } from "../page-header/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import {
  boat_booking_API,
  boat_booking_details,
  boat_booking_slots_API,
  boat_list_filter,
  city_list_API,
  notifications_count,
  user_details,
  user_updateUser,
} from "../../../Service/api";
import { UserDetailsAPI } from "../../../utils/UserDetailsAPI";
import {
  GetUserCity,
  GetUserDetails,
  boat_booking_request_details,
  boat_owner_id,
} from "../../../redux/slices";
import { toast } from "react-toastify";
import Loader from "../../Loader";
import { API } from "../../../Service/constants";
import socket from "../../../Service/SocketIo";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";

const start_space_Validation = new RegExp(/^(?!\s).*/);
const numberValidation = /^[0-9]*$/;
const nameValidationPattern = /^(?! )[A-Za-z-' ]+( [A-Za-z-' ]*)?$/;
const emailIdValidation = new RegExp(
  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i
);
const NotNumber = new RegExp(/^[^\d]*$/)

const styles = (theme) => ({
  root: {
    //outer
    // borderBottom: "none",
    // height: "100%",
    height: "auto",
    // width: "100%",

    // Styles for the input text
    "& .MuiInputBase-input": {
      // padding: "3% 3%",

      fontSize: "clamp(14px, 0.9vw, 20px)",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      textAlign: "left",
      // paddingLeft: "20px",
      color: "rgba(66, 70, 81, 0.87)",
      // color: "red",
      // borderBottom: "none",

      // padding: "2.5%",
      // height: "10%",
    },

    // Styles for the placeholder text
    "& .MuiFormLabel-root": {
      // borderBottom: "none",
    },

    // Styles for the text field box (border and background)
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      borderBottom: "none",
      fontSize: "clamp(12px, 0.9vw, 20px)",
    },

    // Remove the notched outline
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
      fontSize: "clamp(12px, 0.9vw, 20px)",
      // borderBottom: "none",
      border: "none",
      // border: "solid 1px rgba(66, 70, 81, 0.2)",
    },

    "& input::placeholder": {
      fontSize: "8px",
      color: "rgba(66, 70, 81, 0.4)",
      borderBottom: "none",
    },

    // Apply styles for small screens
    [theme.breakpoints.down("sm")]: {
      height: "70%",
      "& .MuiInputBase-input": {},
      "& input::placeholder": {
        fontSize: "8px",
        color: "rgba(66, 70, 81, 0.4)",
      },
      borderRadius: "8px",
    },

    // Adjust for medium screens (md)
    [theme.breakpoints.up("md")]: {
      "& .MuiInputBase-input": {},
      "& input::placeholder": {
        fontSize: "12px",
        color: "rgba(66, 70, 81, 0.4)",
      },
      borderRadius: "15px",
    },

    // Adjust for large screens (lg)
    [theme.breakpoints.up("lg")]: {
      height: "60px",
      "& .MuiInputBase-input": {},
      "& input::placeholder": {
        fontSize: "16px",
        color: "rgba(66, 70, 81, 0.4)",
      },
      borderRadius: "15px",
    },
  },
});

const CustomTextField = withStyles(styles)(TextField);

const hours = Array.from({ length: 12 }, (_, index) => index + 1);
const minute = Array.from({ length: 60 }, (_, index) => index + 1);
const period = Array.from({ length: 2 }, (_, index) => index + 1);

export const BoatBookingRequest = () => {
  const UPDATE_CITY = "UPDATE_CITY";
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const divRef = useRef(null);

  const dash = useSelector((state) => state?.dashboard);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const [selecteHowLongSpendTime, setSelecteHowLongSpendTime] = useState("");
  const [endTime, setEndTime] = useState("00:00 AM");
  const [openTimeModal, setOpenTimeModal] = useState(false);
  const auth = useSelector((state) => state?.auth);
  const [selecteNoOfAdultes, setSelecteNoOfAdultes] = useState(0);
  const [selecteNoOfSeniors, setSelecteNoOfSeniors] = useState(0);
  const [selecteNoOfChildren, setSelecteNoOfChildren] = useState(0);
  const [selecteNoOfInfants, setSelecteNoOfInfants] = useState(0);
  const [selected_total_members, setSelected_total_members] = useState(0);
  const [specialRequests, setSpecialRequests] = useState("");
  const [moreThan10hDate, setMoreThan10hDate] = useState(null);
  const [howLongHrs, setHowLongHrs] = useState(null);
  const [showErr, setShowErr] = useState(false);
  const [totalPrice, setTotalPrice] = useState(
    Number(dash?.single_boat_details?.price_per_hour)
  );
  const [boatOwnerId, setBoatOwnerId] = useState(null);
  //
  const [personName, setPersonName] = useState(
    auth?.userDetails?.first_name ?? ""
  );
  const [personCityName, setPersonCityName] = useState(
    auth?.userDetails?.city ?? ""
  );
  const [personPhoneNo, setPersonPhoneNo] = useState(
    auth?.userDetails?.phone_number ?? ""
  );
  const [personEmail, setPersonEmail] = useState(
    auth?.userDetails?.email ?? ""
  );
  const [editPersonalInfo, setEditPersonalInfo] = useState(true);
  const [moveWeeks, setMoveWeeks] = useState(0);
  const [weekDays, setWeekDays] = useState([]);
  const [endTimePeroid, setEndTimePeroid] = useState([]);
  const [selectTimeToStart, setSelectTimeToStart] = useState([]);
  const [selected_members_capacity, setSelected_members_capacity] = useState(0);
  const [errorMsg, setErrorMsg] = useState(false);
  const [emailValdiErrorMsg, setEmailValdiErrorMsg] = useState(false);
  const [max_capaticy_err, setMax_capaticy_err] = useState(false);
  const [time_slot_loader, settime_slot_loader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModalForCityNames, setShowModalForCityNames] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [ytLink, setYtLink] = useState(null);
  const [phoneNumberLength, setPhoneNumberLength] = useState(10);
  const [notifyCount, setNotifyCount] = useState(0);
  const [duration, setDuration] = useState(null);
  const [hourString, setHourString] = useState('00')
  const [minuteString, setMinuteString] = useState('00')
  const [timePeriod, setTimePeriod] = useState('AM')
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // console.log("boatOwnre is", boatOwnerId);
  useEffect(() => {
    setBoatOwnerId(dash?.boat_owner_id?.boat_owner_id);
  }, [dash?.boat_owner_id]);

  // socket
  useEffect(() => {
    socket.connect(auth?.AuthToken);

    return () => {
      // socket.disconnect();
    };
    // socket.client.on("connect", () => {
    //   console.log("connected");
    // });

    // return () => {
    //   // User leaves room
    //   socket.client.disconnect();

    //   socket.client.off("disconnect", () => {
    //     console.log("disconnected");
    //   });
    // };

    // }
  }, [window.location.search]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
    if (auth?.userDetails?.country_code === "+91") {
      setPhoneNumberLength(10);
    } else if (auth?.userDetails?.country_code === "+966") {
      setPhoneNumberLength(10);
    } else {
      setPhoneNumberLength(10);
    }
  }, []);

  useEffect(() => {
    setCityList([]);
    city_list_API()
      .then((res) => {
        // console.log("res", res?.data);
        if (res?.data?.success) {
          setCityList(res?.data?.parameters);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    // console.log(cityList)
    cityList.map((item) => {
      if (item?.city === personCityName) {
        setCityId(item.city_id);
      }
    });
  });
  // console.log(cityId)

  useEffect(() => {
    if (personPhoneNo.length > phoneNumberLength) {
      setPersonPhoneNo("");
    }
  }, [personPhoneNo]);

  // ==========================   generateWeekDays
  useEffect(() => {
    const generateWeekDays = () => {
      const days = [
        "Saturday",
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
      ];
      const currentDate = new Date();
      const currentDayIndex = currentDate.getDay();

      // console.log("currentDayIndex", currentDayIndex);
      // Find the index of Saturday
      const saturdayIndex = days.indexOf("Saturday");

      // Calculate the number of days to subtract from the start day to reach Saturday
      const numberOfDays = (currentDayIndex + 7 - saturdayIndex) % 7;

      // Generate the week days array starting from Saturday and ending at Friday
      const newWeekDays = [];

      let dayCount = 1;
      let currentWeekDays = [];

      // Add missed week days with "isValid": false
      for (let i = 0; i < numberOfDays; i++) {
        currentWeekDays.push({
          index: i,
          day: moment()
            .subtract(numberOfDays - i, "days")
            .format("ddd"),
          date: moment().subtract(numberOfDays - i, "days"),
          isValid: false,
        });
      }

      // Add current week days with "isValid": true
      for (let i = numberOfDays; dayCount <= 35; i = (i + 1) % 7) {
        currentWeekDays.push({
          index: i,
          day: moment()
            .add(dayCount - 1, "days")
            .format("ddd"),
          date: moment().add(dayCount - 1, "days"),
          isValid: true,
        });

        if (i === 6) {
          // Saturday, start a new week
          newWeekDays.push({
            name: `${Math.ceil(dayCount / 7)}th week`,
            days: currentWeekDays,
          });
          currentWeekDays = [];
        }

        dayCount++;
      }

      return newWeekDays;
    };

    const weekDays = generateWeekDays();
    setWeekDays(weekDays);
  }, []);
  // console.log(weekDays,'week days')

  // ==========================  UserDetailsAPI
  useEffect(() => {
    // console.log('user update'

    console.log(dash?.single_boat_details?.youtybe_link, "youtubelink");
    const ytlink = dash?.single_boat_details?.youtybe_link;
    const splitYtlink = ytlink?.split("/");
    if(dash?.single_boat_details?.youtybe_link){
      setYtLink(
        `https://www.youtube.com/embed/${splitYtlink[splitYtlink?.length - 1]}`
      );
    }
  }, [
    auth?.AuthToken,
    auth?.userDetails?.user_id,
    dispatch,
    editPersonalInfo === false,
  ]);

  // ==========================  boat_booking_slots_API
  // console.log(moment(selectedDate).format("DD-MM-YYYY"))
  useEffect(() => {
    if (selectedDate) {
      settime_slot_loader(true);
      toast.dismiss();
      let payload = {
        boat_id: dash?.boat_id,
        booking_date: moment(selectedDate).format("YYYY-MM-DD"),
        booking_type: 1,
      };
      console.log("boat booking slots payload", payload);
      boat_booking_slots_API(auth?.AuthToken, payload)
        .then((res) => {
          console.log("boat booking slots res", res?.data);
          if (res?.data?.success) {
            setSelectedStartTime("");
            setSelectTimeToStart(res?.data?.data);
            // toast.success(res?.data?.message, {
            //   position: toast.POSITION.TOP_RIGHT,
            //   autoClose: 2000,
            // });
          } else {
            toast.error(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
          settime_slot_loader(false);
        })
        .catch((err) => {
          console.log("boat booking slots err", err);
          settime_slot_loader(false);
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        });
    }
  }, [auth?.AuthToken, dash?.boat_id, selectedDate]);

  // event listener    =>  open ||  closed  Time modal
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setOpenTimeModal(false);
      }
    }
    if (openTimeModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openTimeModal]);

  //set error max boat capaticy
  useEffect(() => {
    setMax_capaticy_err(false);
    setSelected_total_members(
      selecteNoOfAdultes +
        selecteNoOfSeniors +
        selecteNoOfInfants +
        selecteNoOfChildren
    );

    if (
      selected_total_members <= dash?.single_boat_details?.boat_max_capacity
    ) {
      setMax_capaticy_err(false);
    } else {
      setMax_capaticy_err(true);
    }
  }, [
    dash?.single_boat_details?.boat_max_capacity,
    selecteNoOfAdultes,
    selecteNoOfChildren,
    selecteNoOfInfants,
    selecteNoOfSeniors,
    selected_total_members,
  ]);

  // console.log(auth?.AuthToken)

  useEffect(() => {
    const startingDate = moment(selectedDate).format("DD-MM-YYYY");
    const startingTime = selectedStartTime;
    const endingTime = endTime;
    const endingDate = moment(selectedDate).add(Number(selecteHowLongSpendTime?.split(' ')[0]),'days').format('DD-MM-YYYY')
    const funStartTime = moment(
      `${startingDate} ${startingTime}`,
      "DD-MM-YYYY hh:mm A"
    );
    const funEndTime = moment(
      `${endingDate} ${endingTime}`,
      "DD-MM-YYYY hh:mm A"
    );
    const duration = funEndTime.diff(funStartTime, "hours");
    // const hours = duration.hours();
    setHowLongHrs(duration);
    console.log(startingDate,endingDate,startingTime,endingTime,'end date',duration)
  }, [endTime, moreThan10hDate, selectedDate, selectedStartTime,selecteHowLongSpendTime]);

  useEffect(() => {
    if(selecteHowLongSpendTime?.includes('hour')){
      let hour = selecteHowLongSpendTime.split(' ')[0]
      setTotalPrice(dash?.single_boat_details?.price_per_hour * Number(hour))
    } else if(selecteHowLongSpendTime?.includes('days')){
      setTotalPrice(dash?.single_boat_details?.price_per_hour * howLongHrs)
    }
    // if (selecteHowLongSpendTime === "Less than 2 h") {
    //   setTotalPrice(dash?.single_boat_details?.price_per_hour * 2);
    // } else if (selecteHowLongSpendTime === "2 - 4 h") {
    //   setTotalPrice(dash?.single_boat_details?.price_per_hour * 4);
    // } else if (selecteHowLongSpendTime === "5 - 7 h") {
    //   setTotalPrice(dash?.single_boat_details?.price_per_hour * 7);
    // } else if (selecteHowLongSpendTime === "8 - 10 h") {
    //   setTotalPrice(dash?.single_boat_details?.price_per_hour * 10);
    // } else if (selecteHowLongSpendTime === "more than 10 h") {
    //   setTotalPrice(dash?.single_boat_details?.price_per_hour * howLongHrs);
    // }
    // console.log(totalPrice)
  }, [selecteHowLongSpendTime, howLongHrs, moreThan10hDate, endTime]);

  // duration

  useEffect(() => {
    if(selecteHowLongSpendTime?.includes('hour')){
      setDuration(Number(selecteHowLongSpendTime.split(' ')[0]))
    } else {
      setDuration(howLongHrs)
    }
    // if (selecteHowLongSpendTime === "Less than 2 h") {
    //   setDuration(2);
    // } else if (selecteHowLongSpendTime === "2 - 4 h") {
    //   setDuration(4);
    // } else if (selecteHowLongSpendTime === "5 - 7 h") {
    //   setDuration(7);
    // } else if (selecteHowLongSpendTime === "8 - 10 h") {
    //   setDuration(10);
    // } else if (selecteHowLongSpendTime === "more than 10 h") {
    //   setDuration(howLongHrs);
    // }
  }, [selecteHowLongSpendTime, howLongHrs]);
  // console.log(duration,'duration')

  useEffect(() => {
    handleHistory();
  }, [
    personName,
    cityId,
    personPhoneNo,
    personEmail,
    selectedDate,
    selecteHowLongSpendTime,
    endTime,
    selectedStartTime,
    duration,
    selecteNoOfAdultes,
    selecteNoOfChildren,
    selecteNoOfInfants,
    selecteNoOfSeniors,
    boatOwnerId,
    specialRequests,
    totalPrice,
  ]);

  // useEffect(()=>{

  //   if(dash?.boat_booking_request_details?.user_name){
  //     setPersonName(dash?.boat_booking_request_details?.user_name)
  //   }
  //   if(dash?.boat_booking_request_details?.user_city){
  //     setCityId(dash?.boat_booking_request_details?.user_city)
  //   }
  //   if(dash?.boat_booking_request_details?.user_phone){
  //     setPersonPhoneNo(dash?.boat_booking_request_details?.user_phone)
  //   }
  //   if(dash?.boat_booking_request_details?.user_email){
  //     setPersonEmail(dash?.boat_booking_request_details?.user_email)
  //   }
  //   if(dash?.boat_booking_request_details?.selectedDate){
  //     setSelectedDate(moment(dash?.boat_booking_request_details?.selectedDate).format())
  //   }
  //   if(dash?.boat_booking_request_details?.selecteHowLongSpendTime){
  //     setSelecteHowLongSpendTime(dash?.boat_booking_request_details?.selecteHowLongSpendTime)
  //   }
  //   if(dash?.boat_booking_request_details?.endTime){
  //     setEndTime(dash?.boat_booking_request_details?.endTime)
  //   }
  //   if(dash?.boat_booking_request_details?.start_time){
  //     setSelectedStartTime(dash?.boat_booking_request_details?.start_time)
  //   }
  //   if(dash?.boat_booking_request_details?.duration){
  //     setDuration(dash?.boat_booking_request_details?.duration)
  //   }
  //   if(dash?.boat_booking_request_details?.adults){
  //     setSelecteNoOfAdultes(dash?.boat_booking_request_details?.adults)
  //   }
  //   if(dash?.boat_booking_request_details?.seniors){
  //     setSelecteNoOfSeniors(dash?.boat_booking_request_details?.seniors)
  //   }
  //   if(dash?.boat_booking_request_details?.children){
  //     setSelecteNoOfChildren(dash?.boat_booking_request_details?.children)
  //   }
  //   if(dash?.boat_booking_request_details?.infants){
  //     setSelecteNoOfInfants(dash?.boat_booking_request_details?.infants)
  //   }
  //   if(dash?.boat_booking_request_details?.special_request){
  //     setSpecialRequests(dash?.boat_booking_request_details?.special_request)
  //   }
  //   if(dash?.boat_booking_request_details?.boat_owner_id){
  //     setBoatOwnerId(dash?.boat_booking_request_details?.boat_owner_id)
  //   }
  //   if(dash?.boat_booking_request_details?.price){
  //     setTotalPrice(dash?.boat_booking_request_details?.price)
  //   }

  // },[])
  // console.log(dash?.boat_booking_request_details)

  const Check_Email_Validation = () => {
    setEmailValdiErrorMsg(false);
    if (!emailIdValidation.test(personEmail)) {
      setEmailValdiErrorMsg("Invalid email address");
    }
  };

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  const handleHistory = () => {
    dispatch(
      boat_booking_request_details({
        user_name: personName,
        user_city: cityId,
        user_phone: personPhoneNo,
        user_email: personEmail,
        selectedDate: selectedDate,
        selecteHowLongSpendTime: selecteHowLongSpendTime,
        endTime: endTime,
        // booking_date: moment(selectedDate).format("DD-MM-YYYY"),
        // end_date:
        //   selecteHowLongSpendTime === "more than 10 h"
        //     ? moment(moreThan10hDate).format("DD-MM-YYYY")
        //     : moment(selectedDate).format("DD-MM-YYYY"),
        start_time: selectedStartTime,
        // end_time: selecteHowLongSpendTime === "more than 10 h" ? endTime : "",
        duration: duration,
        adults: selecteNoOfAdultes,
        seniors: selecteNoOfSeniors,
        children: selecteNoOfChildren,
        infants: selecteNoOfInfants,
        special_request: specialRequests,
        boat_owner_id: boatOwnerId,
        price: totalPrice,
      })
    );
  };

  const handleUpdateUser = () =>{
    if(editPersonalInfo){
      setEditPersonalInfo(!editPersonalInfo)
      return
    }
    if(personPhoneNo?.toString()?.length !== 10 && !editPersonalInfo){
      toast.error("Please enter 10 digit phone number", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
      return 
    }
    let payload = new FormData();

    payload.append("first_name", personName);
    payload.append("last_name", auth?.userDetails?.last_name);
    payload.append("email", personEmail);
    payload.append("phone_number", personPhoneNo);
    payload.append("country_code", auth?.userDetails?.country_code);
    payload.append("city", personCityName);

    for (const [key, value] of payload.entries()) {
      console.log(key, ":", `'${value}'`);
    }

    user_updateUser(auth?.AuthToken, payload)
      .then((res) => {
        console.log(res?.data,'update user res')
        if (res?.data?.message === "user details updated successfully") {
          // toast.success(res?.data?.message, {
          //   position: toast.POSITION.TOP_RIGHT,
          //   autoClose: 2000,
          // });
          console.log(payload, "payload");
          setEditPersonalInfo(!editPersonalInfo)
          UserDetailsAPI(
            auth?.userDetails?.user_id,
            auth?.AuthToken,
            (err, ress) => {
              dispatch(GetUserDetails(ress));
              console.log(ress, "res");
            }
          );
        } 
        else {
          toast.error(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {
        console.log("update user error", err);
        toast.error(err?.response?.data?.message??"Something went wrong, please try again later", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  }

  const handleSubmit_boat_booking = () => {
    // setErrorMsg(false);
    console.log(howLongHrs);
    setShowErr(false);
    toast.dismiss();
    if (!selectedStartTime) {
      // console.log(howLongHrs)
      setErrorMsg(true);
      toast.error("Please Select Start Time", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (personName === "") {
      setErrorMsg(true);
      toast.error("Please Enter Your Name", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (personCityName === "") {
      setErrorMsg(true);
      toast.error("Please Select Your City", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (personPhoneNo === "") {
      setErrorMsg(true);
      toast.error("Please Enter Your Phone Number", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (personEmail === "") {
      setErrorMsg(true);
      toast.error("Please Enter Your Email", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (selectedDate === null) {
      setErrorMsg(true);
      toast.error("Please Select Booking Date", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (emailValdiErrorMsg !== false) {
      setErrorMsg(true);
      toast.error("Please Verify Your Email", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (selecteHowLongSpendTime === "") {
      setErrorMsg(true);
      toast.error("Please Select How long you want to spend time", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (max_capaticy_err === true) {
      setErrorMsg(true);
      toast.error("Please Select maximum capacity correctly", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (specialRequests === "") {
      setErrorMsg(true);
      toast.error("Please leave your thoughts in special request", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (selected_total_members === 0) {
      setErrorMsg(true);
      toast.error("Please Select Total Members", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (
      selecteHowLongSpendTime.includes('days') &&
      endTime === "00:00 AM"
    ) {
      setErrorMsg(true);
      setShowErr(true);
      toast.error("Please Select End Time", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
    //  else if (
    //   selecteHowLongSpendTime === "more than 10 h" &&
    //   !moreThan10hDate
    // ) {
    //   setErrorMsg(true);
    //   setShowErr(true);
    //   toast.error("Please Select End Date", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 2000,
    //   });
    // } 
    // else if (
    //   selecteHowLongSpendTime === "more than 10 h" &&
    //   howLongHrs <= 10
    // ) {
    //   setErrorMsg(true);
    //   setShowErr(true);
    //   toast.error("Please Select End Time more than 10 hours", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 2000,
    //   });
    // } 
    // else if (selecteHowLongSpendTime === "more than 10 h" && howLongHrs < 0) {
    //   setErrorMsg(true);
    //   setShowErr(true);
    //   toast.error("Please Select more than Start Date", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 2000,
    //   });
    // } 
    else {
      console.log(errorMsg, "errormsg");
      setIsLoading(true);
      setErrorMsg(false);
      setShowErr(false);
      let payload = {
        boat_id: dash?.boat_id,
        user_name: personName,
        // user_city: "city",
        user_city: cityId,
        // user_city: "riyadh", //personCityName
        user_phone: personPhoneNo,
        user_email: personEmail,
        booking_date: moment(selectedDate).format("DD-MM-YYYY"), //
        // end_date:
        //   selecteHowLongSpendTime === "more than 10 h"
        //     ? moment(moreThan10hDate).format("DD-MM-YYYY")
        //     : moment(selectedDate).format("DD-MM-YYYY"),
        end_date: selecteHowLongSpendTime?.includes('days')? 
        moment(selectedDate).add(Number(selecteHowLongSpendTime?.split(' ')[0]),'days').format('DD-MM-YYYY'):
        moment(selectedDate).add(Number(selecteHowLongSpendTime?.split(' ')[0]),'hours').format('DD-MM-YYYY'),
        start_time: selectedStartTime, // selectedStartTime
        end_time: selecteHowLongSpendTime.includes('days') ? endTime : null,
        duration: duration,
        duration_type: selecteHowLongSpendTime, // selecteHowLongSpendTime
        adults: selecteNoOfAdultes,
        seniors: selecteNoOfSeniors,
        children: selecteNoOfChildren,
        infants: selecteNoOfInfants,
        special_request: specialRequests,
        boat_owner_id: boatOwnerId,
        price: totalPrice,
      };

      console.log("boat_booking_API payload => ", payload);
      boat_booking_API(auth?.AuthToken, payload)
        .then((res) => {
          console.log("boat_booking_API res => ", res?.data);
          if (res?.data?.success) {
            setMoreThan10hDate(null);
            setEndTime("00:00 AM");
            setSelecteHowLongSpendTime("");
            toast.success(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            socket.client.emit(
              "getUnreadCountNotification",
              boatOwnerId,
              (err) => {
                console.log("getunread count notification err", err);
              }
            );
            socket.client.emit(
              "getUnreadCountNotification",
              auth?.userId,
              (err) => {
                console.log("getunread count notification err", err);
              }
            );
            // handleNotificationCount()
            dispatch(boat_owner_id(null));
            dispatch(boat_booking_request_details(null));
            navigate("/allMessage",
            // {state:{boat_booking_id:res?.data?.boat_booking_id}}
            );
          } else {
            toast.error(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("boat_booking_API err => ", err);
          setIsLoading(false);
          toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        });
    }
  };

  const handleCallBack = (name) => {
    if (name === "Home") {
      navigate(-1);
    } else if (name === "My Listings") {
      navigate("/myListings");
    } else if (name === "List a Boat Offer" || name === "Boat Offers") {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleCityChange = (values) => {
    const city_Code = values;
    const selectedCity = cityList.find((city) => city.city_id === city_Code);
    setPersonCityName(selectedCity.city);
    setShowModalForCityNames(false);
    dispatch(GetUserCity(selectedCity.city));
  };

  const renderStartTime = (time, index) => {
    return (
      <div
        key={index}
        style={{
          backgroundColor: 
          time?.booked? 'rgba(236,236,236,1)':
            selectedStartTime === time?.label ? "#4f82af" : "white",
        }}
        onClick={
          () => {
            time?.booked?
          setSelectedStartTime(null):
          setSelectedStartTime(time?.label)
        }
      }
        className={class_name.howLong_container}
      >
        <Typography
          style={{
            color:
            time?.booked? 
            'GrayText':
            selectedStartTime === time?.label ? "white" : "#424651",
            width: "100%",
            fontWeight: 
            time?.booked? null:
            selectedStartTime === time?.label ? "600" : null,
          }}
          className={class_name.lableTxt}
        >
          {time?.label}
        </Typography>
      </div>
    );
  };

  const renderHowLongSpendTime = (time, index) => {
    return (
      <div
        key={index}
        style={{
          backgroundColor:
            selecteHowLongSpendTime === time?.data ? "#4f82af" : "white",
        }}
        onClick={() => {
          setSelecteHowLongSpendTime(time?.data);
        }}
        className={class_name.howLong_container}
      >
        <Typography
          style={{
            color: selecteHowLongSpendTime === time?.data ? "white" : "#424651",
            width: "100%",
            textAlign: "center",
            fontWeight: selecteHowLongSpendTime === time?.data ? "600" : null,
          }}
          className={class_name.lableTxt}
        >
          {time?.data}
        </Typography>
      </div>
    );
  };

  const handleNextWeek = () => {
    setMoveWeeks((moveWeeks) => moveWeeks + 1);
  };

  const handlePreviousWeek = () => {
    setMoveWeeks((moveWeeks) => moveWeeks - 1);
  };

  // console.log("end TIme", endTime);

  useEffect(() => {
    const lastPart = endTime.match(/([APap][Mm])$/);
    const amPm = lastPart ? lastPart[0] : "";
    setEndTimePeroid(amPm);
  }, [endTime]);

  const handleTimeChange = (value, timeInput) => {
    const [time, period] = endTime.split(" ");
    const [hourString, minuteString] = time.split(":");
    const hours = parseInt(hourString, 10).toString().padStart(2, "0");
    const minutes = parseInt(minuteString, 10).toString().padStart(2, "0");

    if (timeInput === "hour") {
      setEndTime(
        `${value.toString().padStart(2, "0")}:${minutes ? minutes : "00"} ${
          period ? period : "AM"
        }`
      );
    } else if (timeInput === "minutes") {
      setEndTime(
        `${hours ? hours : "00"}:${value.toString().padStart(2, "0")} ${
          period ? period : "AM"
        }`
      );
    } else {
      setEndTime(
        `${hours ? hours : "00"}:${minutes ? minutes : "00"} ${value}`
      );
    }
  };

  // const handleOpenVideo = () => {
  //   toast.dismiss();
  //   if (dash?.single_boat_details?.youtybe_link || true) {
  //     // window.open(dash?.single_boat_details?.youtybe_link, "_blank");
  //     // window.open('https://youtu.be/jR_o5G-b_Z0?si=xz8I8iNiAfRV1uNm', "_blank");
  //   } else {
  //     toast.error("Youtube link not available", {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 20000,
  //     });
  //   }
  // };

  const updateHour = async (value) => {
    setHourString(value)
    setEndTime(`${value}${endTime.slice(2)}`)
  };

  const updateMinutes = (value) => {
    setMinuteString(value)
    setEndTime(`${endTime.slice(0,3)}${value}${endTime.slice(5)}`)
  };

  const updateAMorPM = (value) => {
    setTimePeriod(value)
    setEndTime(`${endTime.slice(0,6)}${value}`)
  };


  return (
    <div style={{ backgroundColor: "#f6f6f6" }}>
      {isLoading ? <Loader loading={isLoading} /> : null}
      <PageHeader
        showLoginSignUp={auth?.AuthToken ? false : true}
        handle_navigation={handleCallBack}
        presentPage={"Home"}
        link1={"Boat Offers"}
        // link2={"My Listings"}
        link2={"List a Boat Offer"}
        link3={
          auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
            ? "My Listings"
            : null
        }
        notificationCount={notifyCount}
      />
      <div className={class_name.FullContainer}>
        <Typography className={class_name.titleTxt}>
          You're close to booking your most enjoyable experience ever!
        </Typography>
        <div className={class_name.personalInfo_payment_Container}>
          <div className={class_name.personalInfo_Container}>
            <>
              {/* =================================================== personal Info =================================================== */}
              <div style={{ marginTop: "0px" }} className={class_name.rowStyle}>
                <Typography 
                className={class_name.contentTitleTxt}
                style={{
                  whiteSpace: 'nowrap'
                }}
                >
                  Personal Information
                </Typography>
                <IconButton
                  style={{ marginLeft: "64px" }}
                  onClick={() => {
                    // setEditPersonalInfo(!editPersonalInfo);
                    handleUpdateUser()
                  }}
                >
                  {editPersonalInfo ? <Edit /> : <Done />}
                </IconButton>
              </div>
              <div
                style={{
                  marginTop: "4%",
                  paddingBottom: "2%",
                }}
                className={class_name.rowStyle}
              >
                <Typography className={class_name.lableTxt}>Name</Typography>
                <div className={class_name.txtField_errorMsg}>
                  <CustomTextField
                    variant={"outlined"}
                    margin="normal"
                    fullWidth
                    id="name"
                    name="name"
                    placeholder="Name"
                    value={personName}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      if (start_space_Validation.test(inputValue) && NotNumber.test(inputValue)) {
                        setPersonName(inputValue);
                      }
                    }}
                    className={class_name.placeholder}
                    InputProps={{
                      style: {
                        borderRadius: "5px",
                        width: "100%",
                        // alignSelf: "center",
                        border: editPersonalInfo
                          ? "none"
                          : "solid 1px rgba(66, 70, 81, 0.2)",
                        color: "#424651",
                        // borderBottom: "none",
                        margin: "0% 0",
                        // borderTop: "solid 1px rgba(66, 70, 81, 0.2)",
                        // borderTopStyle: "10%",
                      },
                      // classes: {
                      //   input: class_name.placeholder
                      // }
                    }}
                    style={{
                      margin: "0px",
                      padding: "0px",
                      borderBottom: "none",
                      borderTopStyle: "10%",
                      // backgroundColor: "lightcoral",
                    }}
                    disabled={editPersonalInfo ? true : false}
                  />
                  {errorMsg && personName === "" ? (
                    <>
                      <Typography className={class_name.Error_msg_txt}>
                        Please enter name
                      </Typography>
                    </>
                  ) : null}
                </div>
              </div>
              <div
                className={class_name.rowStyle}
                style={{ paddingBottom: "2%" }}
              >
                <Typography className={class_name.lableTxt}>City</Typography>
                <div className={class_name.txtField_errorMsg}>
                  {/* <CustomTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="personCityName"
                    name="personCityName"
                    placeholder="City Name"
                    onClick={() => {
                      setShowModalForCityNames(true);
                    }}
                    value={personCityName}
                    // onChange={(event) => {
                    //   const inputValue = event.target.value;
                    //   if (start_space_Validation.test(inputValue)) {
                    //     setPersonCityName(inputValue);
                    //   }
                    // }}
                    InputProps={{
                      readOnly: true,
                      style: {
                        borderRadius: "5px",
                        alignSelf: "center",
                        border: editPersonalInfo
                          ? "none"
                          : "solid 1px rgba(66, 70, 81, 0.2)",
                        color: "#424651",
                        borderBottom: "none",
                        margin: "2% 0",
                      },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{
                            position: "absolute",
                            top: "50%",
                            display: editPersonalInfo? 'none':'flex',
                            transform: "translateY(-50%)",
                            right: 0,
                          }}
                          onClick={() => {
                            setShowModalForCityNames(true);
                          }}
                        >
                          <IconButton>
                            <img
                              alt="down"
                              src={IMAGES.DOWN}
                              style={{ width: "15px", height: "15px" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    style={{
                      margin: "0px",
                      padding: "0px",
                      borderBottom: "none",
                    }}
                    disabled={editPersonalInfo ? true : false}
                  /> */}
                  {!editPersonalInfo?
                  <CustomTextField
                    // label={
                    //   personCityName === "" ? <span style={{color: 'rgba(223,224,225,1)'}}>City</span> : null
                    // }
                    select
                    className={class_name.select}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="PersonCityName"
                    name="personCityName"
                    value={personCityName}
                    style={{
                      margin: "0px 0px 0px 0px",
                      marginBottom: "0",
                      border: "none",
                    }}
                    SelectProps={{
                      onOpen: false,
                      displayEmpty: true,
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "center",
                        },
                        // anchorPosition:"bottom",
                        getContentAnchorEl: null,
                      },
                      IconComponent: () => null,
                    }}
                    // onChange={(e) => {
                    //   setPersonCityName(e.)
                    // }}
                    // error={
                    //   formik.touched.city && Boolean(formik.errors.city)
                    // }
                    // helperText={formik.touched.city && formik.errors.city}
                    InputProps={{
                      style: {
                        // ...textFieldStyles,
                        backgroundColor: "white",
                        borderRadius: "5px",
                        color: personCityName ? "#212121" : "#bcbcbc",
                        border: editPersonalInfo ? "none" : "solid 1px #d9dadc",
                      },
                    }}
                    InputLabelProps={{
                      shrink: false,
                      style: {
                        // top: width < 767 ? "-25%" : "-5%",
                        fontSize: "clamp(8px, 2vw, 16px)",
                        color: "#757575",
                        // height: width > 992? '10px': width> 768? "6px": width > 480? "0px": "-20px",
                        // translate: width>992? '0px -20%': width>768? '0px -30%': width>480? '0px -60%':'0px -80%',
                        display: "flex",
                        alignItems: "center",
                        // className: class_name.input_label_boatType
                      },
                    }}
                  >
                    <MenuItem
                      disabled
                      value=""
                      style={{
                        padding: "5px 10px",
                      }}
                    >
                      City
                    </MenuItem>
                    {cityList?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item.city}
                        selected={personCityName === item.city}
                        onClick={() => handleCityChange(item.city_id)}
                        style={{
                          width: "100%",
                          height: "auto",
                          textAlign: "left",
                          alignItems: "flex-start",
                          padding: "5px 10px",
                          display: editPersonalInfo ? "none" : "flex",
                          flexDirection: "column",
                          fontSize: "clamp(16px, 3vw, 15px)",
                          color: "#424651",
                          overflowX: "hidden",
                          overflowY: "auto",
                        }}
                      >
                        {item?.city}
                      </MenuItem>
                    ))}
                  </CustomTextField>:
                  <span
                  style={{
                    color: 'rgba(66, 70, 81, 0.87)',
                    fontSize: 'clamp(14px, 0.9vw, 20px)',
                    padding: '18.5px 14px'
                  }}
                  >
                    {personCityName}
                  </span>
                  }
                  {errorMsg && personCityName === "" ? (
                    <>
                      <Typography className={class_name.Error_msg_txt}>
                        Please enter city name
                      </Typography>
                    </>
                  ) : null}
                </div>
                {/* <Dialog
                  open={showModalForCityNames && !editPersonalInfo}
                  onClose={() => {
                    setShowModalForCityNames(false);
                  }}
                  scroll="paper"
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <DialogTitle>Select City</DialogTitle>
                    <IconButton
                      onClick={() => {
                        setShowModalForCityNames(false);
                      }}
                      style={{
                        alignContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    >
                      <Cancel />
                    </IconButton>
                  </div>
                  <DialogContent
                    dividers
                    style={{ minHeight: "auto", minWidth: "100%" }}
                  >
                    {cityList.map((city) => (
                      <MenuItem
                        key={city?.city_id}
                        value={city?.city}
                        style={{ width: "100%" }}
                        onClick={() => {
                          handleCityChange(city.city_id);
                        }}
                      >
                        <Typography>{city.city} </Typography>
                      </MenuItem>
                    ))}
                  </DialogContent>
                </Dialog> */}
              </div>
              <div
                className={class_name.rowStyle}
                style={{ paddingBottom: "2%" }}
              >
                <Typography className={class_name.lableTxt}>
                  Phone
                </Typography>
                <div className={class_name.txtField_errorMsg}>
                  <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: !editPersonalInfo? 'center':'start',
                    color: 'rgba(66, 70, 81, 0.87)',
                    fontSize: 'clamp(14px, 0.9vw, 20px)',
                  }}
                  >
                    <span
                    className={`${editPersonalInfo? 'd-flex':'d-none'} ps-2 py-3`}
                    >
                      {auth?.userDetails?.country_code} {personPhoneNo}
                    </span>
                    {!editPersonalInfo?
                    <CustomTextField
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="personPhoneNo"
                      name="personPhoneNo"
                      placeholder="Phone Number"
                      value={personPhoneNo}
                      className={class_name.placeholder}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        if (start_space_Validation.test(inputValue)) {
                          if (
                            start_space_Validation.test(inputValue) &&
                            inputValue.length <= 10 &&
                            /^\d*$/.test(inputValue)
                          ) {
                            setPersonPhoneNo(inputValue);
                          }
                        }
                      }}
                      InputProps={{
                        style: {
                          borderRadius: "5px",
                          alignSelf: "center",
                          border: editPersonalInfo
                            ? "none"
                            : "solid 1px rgba(66, 70, 81, 0.2)",
                          color: "#424651",
                          // borderBottom: "none",
                          marginBottom: "0%",
                        },
                      }}
                      style={{ margin: "0px", padding: "0px" }}
                      disabled={editPersonalInfo ? true : false}
                    />:null
                  }
                  </div>
                  {errorMsg && personPhoneNo === "" ? (
                    <>
                      <Typography className={class_name.Error_msg_txt}>
                        Please enter phone number
                      </Typography>
                    </>
                  ) : null}
                </div>
              </div>

              <div className={class_name.rowStyle}>
                <Typography className={class_name.lableTxt}>E-mail</Typography>
                <div className={class_name.txtField_errorMsg}>
                  <CustomTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    multiline={true}
                    id="personEmail"
                    name="personEmail"
                    placeholder="Email"
                    value={personEmail}
                    className={class_name.placeholder}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      if (start_space_Validation.test(inputValue)) {
                        setPersonEmail(inputValue);
                      }
                    }}
                    InputProps={{
                      style: {
                        borderRadius: "5px",
                        alignSelf: "center",
                        border: editPersonalInfo
                          ? "none"
                          : "solid 1px rgba(66, 70, 81, 0.2)",
                        color: "#424651",
                        borderBottom: "none",
                        margin: "0% 0",
                      },
                    }}
                    style={{
                      margin: "0px",
                      padding: "0px",
                      borderBottom: "none",
                    }}
                    disabled={true}
                    onBlur={() => Check_Email_Validation()}
                  />
                  {errorMsg && personEmail === "" ? (
                    <>
                      <Typography className={class_name.Error_msg_txt}>
                        Please enter email
                      </Typography>
                    </>
                  ) : null}

                  {emailValdiErrorMsg && personEmail !== "" ? (
                    <Typography className={class_name.Error_msg_txt}>
                      {emailValdiErrorMsg}
                    </Typography>
                  ) : null}
                </div>
              </div>
            </>
            {/* =================================================== Select Date  =================================================== */}
            <div
              style={{
                marginTop: "10%",
              }}
              className={class_name.rowStyle}
            >
              <Typography className={class_name.selected_date_txt}>
                Select Date -
              </Typography>
              <Typography
                style={{
                  width: "100%",
                  // marginLeft: "5%"
                  color: 'gray'
                }}
                className={class_name.lableTxt}
              >
                {moment(weekDays[moveWeeks]?.days[weekDays[moveWeeks]?.days?.length-1]?.date?._d).format('MMMM YYYY')}
                {/* {moment().format("MMMM YYYY")} */}
              </Typography>
            </div>
            <div
              style={{
                // backgroundColor: "red",
                justifyContent: "space-between",
                display: "flex",
                marginTop: "3%",
              }}
            >
              <IconButton
                style={{ marginLeft: "0" }}
                onClick={handlePreviousWeek}
                disabled={moveWeeks === 0 ? true : false}
              >
                <ArrowBack style={{ fontSize: "clamp(18px, 1.5vw, 24px)" }} />
              </IconButton>

              <IconButton
                style={{ marginRight: "0" }}
                onClick={handleNextWeek}
                disabled={moveWeeks + 1 < weekDays.length ? false : true}
              >
                <ArrowForward
                  style={{ fontSize: "clamp(18px, 1.5vw, 24px)" }}
                />
              </IconButton>
            </div>
            <div>
              {weekDays?.map((item, index) => {
                if (index === moveWeeks) {
                  return (
                    <>
                      <WeekDays
                        key={index}
                        item={item}
                        index={index}
                        setSelectedDate={setSelectedDate}
                        selectedDate={selectedDate}
                      />
                    </>
                  );
                } else {
                  return null;
                }
              })}
            </div>

            {errorMsg && selectedDate === null ? (
              <>
                <Typography className={class_name.Error_msg_txt}>
                  Please select date
                </Typography>
              </>
            ) : null}
            {/* =================================================== Select start Time  =================================================== */}
            <div
              style={{ display: "flex", flexWrap: "wrap", marginTop: "10%" }}
            >
              <Typography className={class_name.contentTitleTxt}>
                Select Time To Start Your Endless Fun
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  marginTop: "3%",
                }}
              >
                {time_slot_loader ? (
                  <>
                    <Skeleton
                      variant="rect"
                      width="15%"
                      height={35}
                      animation="wave"
                      style={{ borderRadius: "10px" }}
                    />
                    <Skeleton
                      variant="rect"
                      width="15%"
                      height={35}
                      animation="wave"
                      style={{ borderRadius: "10px", marginLeft: "5%" }}
                    />
                    <Skeleton
                      variant="rect"
                      width="15%"
                      height={35}
                      animation="wave"
                      style={{ borderRadius: "10px", marginLeft: "5%" }}
                    />
                    <Skeleton
                      variant="rect"
                      width="15%"
                      height={35}
                      animation="wave"
                      style={{ borderRadius: "10px", marginLeft: "5%" }}
                    />
                  </>
                ) : (
                  <>
                    {selectedDate ? (
                      <>
                        {selectTimeToStart?.length ? (
                          <>
                            {selectTimeToStart?.map((item, index) => {
                              return renderStartTime(item, index);
                            })}
                          </>
                        ) : (
                          <Typography className={class_name.Error_msg_txt}>
                            Start time not available{" "}
                          </Typography>
                        )}
                      </>
                    ) : (
                      <Typography className={class_name.Error_msg_txt}>
                        Please select date to show available Start Time{" "}
                      </Typography>
                    )}
                  </>
                )}
              </div>
              {errorMsg && selectedStartTime === "" ? (
                <>
                  <Typography className={class_name.Error_msg_txt}>
                    Please select, Start Time
                  </Typography>
                </>
              ) : null}
            </div>

            {/* =================================================== How Long Do You Want =================================================== */}
            <div
              style={{ display: "flex", flexWrap: "wrap", marginTop: "10%" }}
              className="w-100"
            >
              <Typography className={class_name.contentTitleTxt}>
                How Long Do You Want To Have Fun On This Boat
              </Typography>
              <div className="row w-100">
                <div className="col-lg-7 mt-2">
                  <CustomSelectTextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="Adults"
                    name="Adults"
                    placeholder="Adults"
                    value={selecteHowLongSpendTime}
                    select
                    style={{
                      marginTop: '3%',
                      width: '100%'
                    }}
                    className={class_name.dropDownBoxStyle}
                  >
                    {hours_options?.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item?.id}
                        onClick={() => setSelecteHowLongSpendTime(item?.id)}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: "5px 10px",
                        }}
                      >
                        {item?.id}
                      </MenuItem>
                    ))}
                  </CustomSelectTextField>
                </div>
                {selecteHowLongSpendTime.includes('days') ? (
                <div className="col-lg-5">
                  <div className="w-100 flex flex-col gap-4">
                    <div className="mb-2">
                      End Time
                    </div>
                    <div className="d-flex gap-1">
                      {/* Hours */}
                      <TextField
                        select
                        size="small"
                        variant="outlined"
                        value={hourString}
                        className={class_name.time_box}
                        // style={{fontSize: "clamp(1px, 2vw, 16px)","&MuiSelect-select":{fontSize: '10px'}}}
                        onChange={(e) => {
                          // setHour({
                          //   hr: e.target.value,
                          //   index: timeIndex,
                          // });

                          updateHour(
                            e.target.value
                          );
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // MenuProps={{
                        //   style: { maxHeight: 300 },
                        //   anchorOrigin: {
                        //     vertical: "bottom",
                        //     horizontal: "left",
                        //   },
                        //   getContentAnchorEl: null,
                        // }}
                        SelectProps={{
                          MenuProps: {
                            style: { maxHeight: 300 },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "center",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                            anchorPosition: "bottom",
                            getContentAnchorEl: null,
                          },
                        }}
                      >
                        {Array.from(
                          { length: 13 },
                          (_, index) => (
                            <MenuItem
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              padding: "5px 10px",
                            }}
                              key={index}
                              value={index
                                .toString()
                                .padStart(2, "0")}
                            >
                              {index
                                .toString()
                                .padStart(2, "0")}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                      {
                        <span className="fw-bold fs-4 text-secondary">
                          :
                        </span>
                      }
                      {/* Minutes */}
                      <TextField
                        size="small"
                        select
                        // label="MM"
                        placeholder="MM"
                        variant="outlined"
                        value={minuteString}
                        className={class_name.time_box}
                        onChange={(e) => {
                          // setMinutes({
                          //   min: e.target.value,
                          //   index: timeIndex,
                          // })
                          updateMinutes(
                            e.target.value
                          );
                        }}
                        // MenuProps={{
                        //   style: { maxHeight: 300 },
                        //   anchorOrigin: {
                        //     vertical: "bottom",
                        //     horizontal: "left",
                        //   },
                        //   getContentAnchorEl: null,
                        // }}
                        SelectProps={{
                          MenuProps: {
                            style: { maxHeight: 300 },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "center",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                            anchorPosition: "bottom",
                            getContentAnchorEl: null,
                          },
                        }}
                      >
                        {Array.from(
                          { length: 61 },
                          (_, index) => (
                            <MenuItem
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-start',
                              padding: "5px 10px",
                            }}
                              key={index}
                              value={index
                                .toString()
                                .padStart(2, "0")}
                            >
                              {index
                                .toString()
                                .padStart(2, "0")}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                      {/* AM / PM */}
                      <TextField
                        size="small"
                        select
                        // label="00"
                        variant="outlined"
                        value={timePeriod}
                        className={class_name.time_box}
                        onChange={(e) => {
                          // setFormat({
                          //   format: e.target.value,
                          //   index: timeIndex,
                          // })
                          updateAMorPM(
                            e.target.value
                          );
                        }}
                        // MenuProps={{
                        //   style: { maxHeight: 300 },
                        //   anchorOrigin: {
                        //     vertical: "bottom",
                        //     horizontal: "left",
                        //   },
                        //   getContentAnchorEl: null,
                        // }}
                        SelectProps={{
                          MenuProps: {
                            style: { maxHeight: 300 },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "center",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                            anchorPosition: "bottom",
                            getContentAnchorEl: null,
                          },
                        }}
                      >
                        <MenuItem 
                        value={"AM"}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          padding: "5px 10px",
                        }}
                        >
                          AM
                        </MenuItem>
                        <MenuItem 
                        value={"PM"}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          padding: "5px 10px",
                        }}
                        >
                        PM
                        </MenuItem>
                      </TextField>
                    </div>
                        {/* <CustomTextField
                          variant="outlined"
                          margin="normal"
                          // fullWidth
                          id="endTime"
                          name="endTime"
                          placeholder="End Time"
                          value={endTime}
                          onChange={(e) => {
                            setEndTime(e.target.value);
                            setOpenTimeModal(true);
                          }}
                          onFocus={() => setOpenTimeModal(true)}
                          InputProps={{
                            style: {
                              // ...textFieldStyles,

                              margin: 0,
                              padding: 0,
                              border: false
                                ? "solid 1px red"
                                : "solid 1px rgba(66, 70, 81, 0.3)",
                            },
                          }}
                          style={{
                            // width: "15%",
                            padding: 0,
                            // margin: 0,
                          }}
                        /> */}
                        {selecteHowLongSpendTime.includes('days') &&
                        showErr &&
                        endTime === "00:00 AM" ? (
                          <div className="text-danger" style={{ fontSize: "15px" }}>
                            Please Select End Time
                          </div>
                        ) : selecteHowLongSpendTime === "more than 10 h" &&
                          showErr &&
                          howLongHrs <= 10 ? (
                          <div className="text-danger" style={{ fontSize: "15px" }}>
                            Please Select End Time More than 10 hours
                          </div>
                        ) : null}
                  </div>
                </div>):null}
              </div>

              {/* <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
                {howLongList?.map((item, index) => {
                  return renderHowLongSpendTime(item, index);
                })}
              </div> */}

              {selecteHowLongSpendTime.includes('days') ? (
                <div className="position-relative d-flex flex-sm-row flex-column gap-2 mt-3">
                  {" "}
                  {/*style={{ position: "relative", width: "auto",display: 'flex', gap: '10px' }}*/}
                  {/* <div className="d-flex flex-column">
                    <div>
                      <div>
                        End Date
                      </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                      <MobileDatePicker
                        // label={moreThan10hDate ? "Date" : null}
                        sx={{
                          "& fieldset": { border: "none" },
                          "& .Mui-readOnly": {
                            padding: "8px",
                            paddingRight: "15px",
                          },
                        }}
                        // className={{root:class_name.date_picker}}
                        disablePast={true}
                        format="DD-MM-YYYY"
                        // placeholder="date"
                        value={moreThan10hDate}
                        onChange={(datevalue) => {
                          setMoreThan10hDate(datevalue.$d);
                        }}
                        slotProps={{
                          field: {
                            // format: false,
                            // label: "date"
                            InputProps: {
                              endAdornment: (
                                <img
                                  src={IMAGES.DOWN_ARROW_2}
                                  style={{ width: "10px", height: "10px" }}
                                />
                              ),
                            },
                          },
                          textField: {
                            InputProps: {
                              style: {
                                fontSize: "13px",
                                borderRadius: "8px",
                                // ...textFieldStyles,
                                marginBottom: "8px",
                                // padding: '10px'
                                // paddingRight: '13px'
                              },
                            },
                            InputLabelProps: {
                              style: {
                                transform: moreThan10hDate
                                  ? "translateY(-1rem)"
                                  : null,
                                fontSize: "clamp(12px, 0.9vw, 20px)",
                                color: "black",
                                // paddingLeft: "3%",
                              },
                            },
                            error: false,
                            style: {
                              // className: class_name.date_picker,
                              width: "100%",
                              // padding: '10px'
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                    </div>
                    {selecteHowLongSpendTime === "more than 10 h" &&
                    showErr &&
                    !moreThan10hDate ? (
                      <div className="text-danger" style={{ fontSize: "15px" }}>
                        Please Select End date
                      </div>
                    ) : selecteHowLongSpendTime === "more than 10 h" &&
                      showErr &&
                      howLongHrs < 0 ? (
                      <div className="text-danger" style={{ fontSize: "15px" }}>
                        Please Select Start day or more than Start day
                      </div>
                    ) : null}
                  </div> */}
                  {openTimeModal ? (
                    <>
                      {/* <Grid
                        ref={modalRef}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          border: "1px solid black",
                          borderRadius: "10px",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                          padding: "5px",
                          backgroundColor: "white",
                          zIndex: "9999",
                          position: "absolute",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className={class_name.time_selection_column_box}>
                          {hours.map((hourItem, hourIndex) => {
                            const [hourString, minuteString] =
                              endTime.split(":");
                            return (
                              <div
                                key={hourIndex + "%"}
                                value={hourItem}
                                onClick={() => {
                                  handleTimeChange(hourItem, "hour");
                                }}
                                style={{
                                  backgroundColor:
                                    hourString ===
                                    hourItem.toString().padStart(2, "0")
                                      ? "#3973A5"
                                      : "white",
                                  color:
                                    hourString ===
                                    hourItem.toString().padStart(2, "0")
                                      ? "white"
                                      : "black",
                                }}
                                className={class_name.time_selection_value_txt}
                              >
                                {hourItem.toString().padStart(2, "0")}
                              </div>
                            );
                          })}
                        </div>
                        <div className={class_name.time_selection_column_box}>
                          {minute.map((minutesItem, minutesIndex) => {
                            const [hourString, minuteString] = endTime
                              .replace(/\s/g, "")
                              .replace(/AM/g, "")
                              .split(":");

                            return (
                              <div
                                key={minutesIndex + "%"}
                                value={minutesItem}
                                onClick={() => {
                                  handleTimeChange(minutesItem, "minutes");
                                }}
                                style={{
                                  backgroundColor:
                                    minuteString ===
                                    minutesItem.toString().padStart(2, "0")
                                      ? "#3973A5"
                                      : "white",
                                  color:
                                    minuteString ===
                                    minutesItem.toString().padStart(2, "0")
                                      ? "white"
                                      : "black",
                                }}
                                className={class_name.time_selection_value_txt}
                              >
                                {minutesItem.toString().padStart(2, "0")}
                              </div>
                            );
                          })}
                        </div>
                        <div
                          className={class_name.time_selection_column_box}
                          style={{ border: "none" }}
                        >
                          <div
                            onClick={() => {
                              handleTimeChange("AM", " ");
                            }}
                            style={{
                              backgroundColor:
                                endTimePeroid ===
                                "AM".toString().padStart(2, "0")
                                  ? "#3973A5"
                                  : "white",
                              color:
                                endTimePeroid ===
                                "AM".toString().padStart(2, "0")
                                  ? "white"
                                  : "black",
                            }}
                            className={class_name.time_selection_value_txt}
                          >
                            {"AM"}
                          </div>
                          <div
                            onClick={() => {
                              handleTimeChange("PM", " ");
                            }}
                            style={{
                              backgroundColor:
                                endTimePeroid ===
                                "PM".toString().padStart(2, "0")
                                  ? "#3973A5"
                                  : "white",
                              color:
                                endTimePeroid ===
                                "PM".toString().padStart(2, "0")
                                  ? "white"
                                  : "black",
                            }}
                            className={class_name.time_selection_value_txt}
                          >
                            {"PM"}
                          </div>
                        </div>
                      </Grid> */}
                      <Grid
                        ref={modalRef}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          border: "1px solid black",
                          borderRadius: "10px",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                          padding: "5px",
                          backgroundColor: "white",
                          zIndex: "9999",
                          position: "absolute",
                        }}
                      >
                        <Grid
                          item
                          xs={4}
                          style={{
                            borderRight: "1px solid black",
                            width: "33%",
                            height: "200px",
                            overflowY: "auto",
                          }}
                        >
                          {hours.map((hourItem, hourIndex) => {
                            const [hourString, minuteString] =
                              endTime.split(":");
                            return (
                              <div
                                key={hourIndex + "%"}
                                value={hourItem}
                                // onClick={() => {
                                //   handleHourChange(
                                //     index,
                                //     timeIndex,
                                //     hourItem,
                                //     "hour"
                                //   );
                                // }}
                                onClick={() => {
                                  handleTimeChange(hourItem, "hour");
                                }}
                                style={{
                                  ...timeTxtStyle,
                                  backgroundColor:
                                    hourString ===
                                    hourItem.toString().padStart(2, "0")
                                      ? "#3973A5"
                                      : "white",
                                  color:
                                    hourString ===
                                    hourItem.toString().padStart(2, "0")
                                      ? "white"
                                      : "black",
                                  // backgroundColor:
                                  //   hourString ===
                                  //   hourItem
                                  //     .toString()
                                  //     .padStart(2, "0")
                                  //     ? "#3973A5"
                                  //     : "white",
                                  // color:
                                  //   hourString ===
                                  //   hourItem
                                  //     .toString()
                                  //     .padStart(2, "0")
                                  //     ? "white"
                                  //     : "black",
                                }}
                              >
                                {hourItem.toString().padStart(2, "0")}
                              </div>
                            );
                          })}
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{
                            borderRight: "1px solid black",
                            width: "33%",
                            height: "200px",
                            overflowY: "auto",
                          }}
                        >
                          {minute.map((minutesItem, minutesIndex) => {
                            const [hourString, minuteString] = endTime
                              .replace(/\s/g, "")
                              .replace(/AM/g, "")
                              .replace(/PM/g, "")
                              .split(":");

                            return (
                              <div
                                key={minutesIndex + "%"}
                                value={minutesItem}
                                onClick={() => {
                                  handleTimeChange(minutesItem, "minutes");
                                }}
                                style={{
                                  ...timeTxtStyle,
                                  backgroundColor:
                                    minuteString ===
                                    minutesItem.toString().padStart(2, "0")
                                      ? "#3973A5"
                                      : "white",
                                  color:
                                    minuteString ===
                                    minutesItem.toString().padStart(2, "0")
                                      ? "white"
                                      : "black",
                                }}
                              >
                                {minutesItem.toString().padStart(2, "0")}
                              </div>
                            );
                          })}
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          style={{
                            width: "30%",
                            height: "200px",
                            overflowY: "auto",
                          }}
                        >
                          <div
                            onClick={() => {
                              handleTimeChange("AM", " ");
                            }}
                            style={{
                              ...timeTxtStyle,
                              backgroundColor:
                                endTimePeroid ===
                                "AM".toString().padStart(2, "0")
                                  ? "#3973A5"
                                  : "white",
                              color:
                                endTimePeroid ===
                                "AM".toString().padStart(2, "0")
                                  ? "white"
                                  : "black",
                            }}
                          >
                            {"AM"}
                          </div>
                          <div
                            onClick={() => {
                              handleTimeChange("PM", " ");
                            }}
                            style={{
                              ...timeTxtStyle,
                              backgroundColor:
                                endTimePeroid ===
                                "PM".toString().padStart(2, "0")
                                  ? "#3973A5"
                                  : "white",
                              color:
                                endTimePeroid ===
                                "PM".toString().padStart(2, "0")
                                  ? "white"
                                  : "black",
                            }}
                          >
                            {"PM"}
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
            {errorMsg && selecteHowLongSpendTime === "" ? (
              <>
                <Typography className={class_name.Error_msg_txt}>
                  Please select, How Long Do You Want?
                </Typography>
              </>
            ) : null}
          </div>

          {/* =================================================== youtube and payment detail =================================================== */}

          <div style={{}} className={class_name.payment_detail_Container}>
            {dash?.single_boat_details?.youtybe_link ? (
              <div
              // onClick={() => handleOpenVideo()}
              >
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    height: "100%",
                  }}
                >
                  <iframe
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    src={ytLink}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen="true"
                    webkitallowfullscreen="true"
                    mozallowfullscreen="true"
                  ></iframe>
                  {/* <img
                  alt="youtube video link"
                  src={
                    // `${API.baseUrls[API.currentEnv]}${
                    //   dash?.single_boat_details?.front_image
                    // }` ?? IMAGES.PROFILE_ICON
                    require('../../../assets/Images/boat-background-img.jpeg')
                  }
                  className={class_name.youTube_image}
                />
                <img  
                  alt="youtube"
                  src={IMAGES.YOUTUBE_COLOR_ICON}
                  className={class_name.youtube_icon_style}
                /> */}
                </div>
              </div>
            ) : null}
            <div
              style={{
                padding: "5%",
              }}
            >
              <div className={class_name.rowStyle}>
                <Typography 
                className={class_name.boatName}
                style={{paddingRight: '4px'}}
                >
                  {/* Night Light - */}
                  {`${dash?.single_boat_details?.boat_name} - `}
                </Typography>
                <Typography
                  className={class_name.boatName}
                  style={{ fontWeight: "normal" }}
                >
                  {` ${dash?.single_boat_details?.boat_type}`}
                  {/* Jeddah */}
                </Typography>
              </div>
              <div
                className={class_name.boat_details_data}
                style={{ marginTop: "10%" }}
              >
                <Typography className={class_name.boat_details_label}>
                  Guests
                </Typography>
                <Typography className={class_name.boat_details_values}>
                  {selected_total_members}
                </Typography>
              </div>
              <div className={class_name.boat_details_data}>
                <Typography className={class_name.boat_details_label}>
                  Duration
                </Typography>
                <Typography className={class_name.boat_details_values}>
                  {selecteHowLongSpendTime? selecteHowLongSpendTime:'-'}
                </Typography>
              </div>
              <div className={class_name.boat_details_data}>
                <Typography className={class_name.boat_details_label}>
                  Check in
                </Typography>
                <Typography className={class_name.boat_details_values}>
                  {/* 13 Jan- 10 AM */}
                  {selectedDate
                    ? moment(selectedDate).format("DD-MM-YYYY")
                    : "-"}
                  {}
                </Typography>
              </div>
              <div className={class_name.boat_details_data}>
                <Typography className={class_name.boat_details_label}>
                  Price per hour
                </Typography>
                <Typography className={class_name.boat_details_values}>
                  {/* 13 Jan- 10 AM */}
                  {dash?.single_boat_details?.price_per_hour} SAR
                </Typography>
              </div>
              <Divider
                variant="fullWidth"
                style={{
                  backgroundColor: "rgba(66, 70, 81, 0.32)",
                  height: "1px",
                  border: "solid 1px rgba(66, 70, 81, 0.32)",
                }}
              />
              <div className={class_name.boat_details_data}>
                <Typography className={class_name.boat_details_label}>
                  Total hour Price:
                </Typography>
                <Typography className={class_name.boat_details_values}>
                  {/* {!totalPrice
                    ? dash?.single_boat_details?.price_per_hour
                    : totalPrice} */}
                    {totalPrice}
                    {" "}
                  SAR
                </Typography>
              </div>
            </div>
            {boatOwnerId !== auth?.userId?
            <div
              className={class_name?.send_a_booking_req}
              onClick={() => {
                handleSubmit_boat_booking();
              }}
            >
              <Typography
                className={`${class_name.send_a_booking_req_txt} hoverEffect`}
              >
                Proceed to Pay
              </Typography>
            </div>:null
            }
          </div>
        </div>

        {/* =================================================== selection box =================================================== */}
        <div
          style={{
            marginTop: "5%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            width: "100%",
            // padding: "50px 30px",
            padding: "4% 3%",
            boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.09)",
          }}
        >
          <Typography className={class_name.contentTitleTxt}>
            How many of you want to make this day memorable day?
          </Typography>

          <div style={{}} className={class_name.selection_box_container}>
            <div className={class_name.each_selection_box}>
              <InputLabel
                htmlFor="boatType"
                style={{
                  textAlign: "center",
                  // width: "50%",
                }}
                className={class_name.personInfoTxt}
              >
                Adults
              </InputLabel>
              <CustomSelectTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="Adults"
                name="Adults"
                placeholder="Adults"
                value={selecteNoOfAdultes}
                select
                className={class_name.dropDownBoxStyle}
              >
                {Adults_options?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={index}
                    onClick={() => setSelecteNoOfAdultes(index)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "5px 10px",
                    }}
                  >
                    {index}
                  </MenuItem>
                ))}
              </CustomSelectTextField>
            </div>
            <div className={class_name.each_selection_box}>
              <InputLabel
                htmlFor="boatType"
                style={{
                  textAlign: "center",
                }}
                className={class_name.personInfoTxt}
              >
                Seniors
              </InputLabel>
              <CustomSelectTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="Seniors"
                name="Seniors"
                placeholder="Seniors"
                value={selecteNoOfSeniors}
                select
                className={class_name.dropDownBoxStyle}
              >
                {Adults_options?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={index}
                    onClick={() => setSelecteNoOfSeniors(item.id)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "5px 10px",
                    }}
                  >
                    {index}
                  </MenuItem>
                ))}
              </CustomSelectTextField>
            </div>
            <div className={class_name.each_selection_box}>
              <InputLabel
                htmlFor="boatType"
                style={{
                  textAlign: "center",
                }}
                className={class_name.personInfoTxt}
              >
                Children
              </InputLabel>
              <CustomSelectTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="Children"
                name="Children"
                placeholder="Children"
                value={selecteNoOfChildren}
                select
                className={class_name.dropDownBoxStyle}
              >
                {Adults_options?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={index}
                    onClick={() => setSelecteNoOfChildren(item.id)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "5px 10px",
                    }}
                  >
                    {index}
                  </MenuItem>
                ))}
              </CustomSelectTextField>
            </div>
            <div className={class_name.each_selection_box}>
              <InputLabel
                htmlFor="boatType"
                style={{
                  textAlign: "center",
                }}
                className={class_name.personInfoTxt}
              >
                Infants
              </InputLabel>
              <CustomSelectTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="Infants"
                name="Infants"
                placeholder="Infants"
                value={selecteNoOfInfants}
                select
                className={class_name.dropDownBoxStyle}
              >
                {Adults_options?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={index}
                    onClick={() => setSelecteNoOfInfants(item.id)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "5px 10px",
                    }}
                  >
                    {index}
                  </MenuItem>
                ))}
              </CustomSelectTextField>
            </div>
          </div>
          {errorMsg && max_capaticy_err === true ? (
            <>
              <Typography className={class_name.Error_msg_txt}>
                Selected boat capacity exceeds the maximum allowed capacity. The
                maximum capacity is{" "}
                {dash?.single_boat_details?.boat_max_capacity}.
              </Typography>
            </>
          ) : null}

          {errorMsg && selected_total_members === 0 ? (
            <>
              <Typography className={class_name.Error_msg_txt}>
                Please select an option
              </Typography>
            </>
          ) : null}
        </div>

        {/* =================================================== Special Requests =================================================== */}
        <div
          style={{
            marginTop: "5%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "white",
            width: "100%",
            height: "100%",
            padding: "5% 3%",
            boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.09)",
          }}
        >
          <Typography className={class_name.contentTitleTxt}>
            Special Requests
          </Typography>
          <CustomTextField
            margin="normal"
            fullWidth
            id="SpecialRequests"
            name="SpecialRequests"
            placeholder="Let the boat owner know anything you want him to pay an attention for to take care of you."
            value={specialRequests}
            multiline
            // maxRows={5}
            rows={7}
            // onChange={(event) => {
            //   if (start_space_Validation.test(event.target.value)) {

            //   }
            // }}
            onChange={(event) => {
              const inputValue = event.target.value;
              if (nameValidationPattern.test(inputValue) && inputValue?.length < 101) {
                setSpecialRequests(event.target.value);
              } else if (inputValue === "") {
                setSpecialRequests("");
              }
            }}
            variant="standard"
            InputProps={{
              disableUnderline: true,
              style: {
                backgroundColor: "white",
                borderRadius: "5px",
                padding: "5% 5%",
                border: "solid 1px rgba(66, 70, 81, 0.36)",
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              fontSize: "40px",
              // fontFamily: "Poppins",
              color: "#424651",
              borderBottom: "none",

              backgroundColor: "#fff",
              border: "solid 1px rgba(66, 70, 81, 0.36)",
              // width: "50%",

              borderRadius: "10px",
              margin: 0,
              padding: 0,
            }}
            style={{
              display: "flex",
              margin: 0,
              padding: 0,
              marginTop: "5%",
              height: "100%",
            }}
          />
          {errorMsg && specialRequests === "" ? (
            <>
              <Typography className={class_name.Error_msg_txt}>
                Please leave some message
              </Typography>
            </>
          ) : null}
        </div>

        {/* =================================================== show below payment detail =================================================== */}
        <div className={class_name.show_below_payment_detail}>
          {dash?.single_boat_details?.youtybe_link ? (
            <div
            // onClick={() => handleOpenVideo()}
            >
              <div
                style={{
                  position: "relative",
                  // display: "flex",
                  // flexDirection: "column",
                  // alignItems: "center",
                  // justifyContent: "center",
                  // cursor: "pointer",
                  paddingBottom: "56.25%",
                  height: "0",
                  overflow: "hidden",
                }}
              >
                <iframe
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                  }}
                  src={ytLink}
                  title="YouTube video player"
                  frameborder="0"
                  aria-controls="true"
                  allow="accelerometer; 
                  autoplay; 
                  clipboard-write; 
                  encrypted-media; 
                  gyroscope; 
                  picture-in-picture; 
                  web-share"
                  allowfullscreen="true"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
                ></iframe>
              </div>
              {/* <img
                alt="youtube video link"
                src={
                  `${API.baseUrls[API.currentEnv]}${
                    dash?.single_boat_details?.front_image
                  }` ?? IMAGES.PROFILE_ICON
                }
                className={class_name.youTube_image}
              />
              <img
                alt="youtube"
                src={IMAGES.YOUTUBE_COLOR_ICON}
                className={class_name.youtube_icon_style}
              /> */}
            </div>
          ) : null}
          <div
            style={{
              padding: "5%",
            }}
          >
            <div className={class_name.rowStyle}>
              <Typography 
              className={class_name.boatName}
              style={{paddingRight: '4px'}}
              >
              {`${dash?.single_boat_details?.boat_name} -`}
              </Typography>
              <Typography
                className={class_name.boatName}
                style={{ fontWeight: "normal" }}
              >
                {` ${dash?.single_boat_details?.boat_type}`}
              </Typography>
            </div>
            <div
              className={class_name.boat_details_data}
              style={{ marginTop: "10%" }}
            >
              <Typography className={class_name.boat_details_label}>
                Guests
              </Typography>
              <Typography className={class_name.boat_details_values}>
              {selected_total_members}
              </Typography>
            </div>
            <div className={class_name.boat_details_data}>
              <Typography className={class_name.boat_details_label}>
                Duration
              </Typography>
              <Typography className={class_name.boat_details_values}>
              {selecteHowLongSpendTime? selecteHowLongSpendTime:'-'}
              </Typography>
            </div>
            <div className={class_name.boat_details_data}>
              <Typography className={class_name.boat_details_label}>
                Check in
              </Typography>
              <Typography className={class_name.boat_details_values}>
              {selectedDate
                    ? moment(selectedDate).format("DD-MM-YYYY")
                    : "-"}
              </Typography>
            </div>
            <div className={class_name.boat_details_data}>
                <Typography className={class_name.boat_details_label}>
                  Price per hour
                </Typography>
                <Typography className={class_name.boat_details_values}>
                  {/* 13 Jan- 10 AM */}
                  {dash?.single_boat_details?.price_per_hour} SAR
                </Typography>
              </div>
            <Divider
              variant="fullWidth"
              style={{
                backgroundColor: "rgba(66, 70, 81, 0.32)",
                height: "1px",
                border: "solid 1px rgba(66, 70, 81, 0.32)",
              }}
            />
            <div className={class_name.boat_details_data}>
              <Typography className={class_name.boat_details_label}>
                Total Price:
              </Typography>
              <Typography className={class_name.boat_details_values}>
                {!totalPrice
                  ? dash?.single_boat_details?.price_per_hour
                  : totalPrice}{" "}
                SAR
              </Typography>
            </div>
          </div>
          {boatOwnerId !== auth?.userId?
          <div
            className={class_name?.send_a_booking_req}
            onClick={() => {
              handleSubmit_boat_booking();
            }}
          >
            <Typography
              className={`${class_name.send_a_booking_req_txt} hoverEffect`}
            >
              Proceed to Pay
            </Typography>
          </div>:null
          }
        </div>
      </div>
      <div className="footer-style-hide">
        <Footer />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  FullContainer: {
    backgroundColor: "#f6f6f6",
    display: "flex",
    // flexWrap: 'wrap',
    flexDirection: "column",
    padding: "5% 10% 10% 5%",
    width: "100%",
    height: "auto",
  },

  personalInfo_payment_Container: {
    marginTop: "5%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },

  personalInfo_Container: {
    display: "flex",
    flexDirection: "column",
    margin: "0px",
    width: "63%",
    padding: "3% 4%",
    backgroundColor: "white",
    boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.09)",
  },

  payment_detail_Container: {
    display: "flex",
    flexDirection: "column",
    margin: "0px",
    width: "35%",
    padding: "0px",
    backgroundColor: "white",
    boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.09)",
  },

  show_below_payment_detail: {
    display: "none",
  },

  selection_box_container: {
    marginTop: "5%",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },

  each_selection_box: {
    width: "13%",
    marginRight: "5%",
  },
  titleTxt: {
    fontSize: "clamp(16px, 1.5vw, 30px)",
    fontWeight: "600",
    color: "#424651",
    paddingLeft: "1%",
    [theme.breakpoints.down("767")]: {
      paddingTop: "5%",
      padding: '0% 2%'
    },
  },

  contentTitleTxt: {
    fontSize: "clamp(16px, 2.5vw, 22px)",
    fontWeight: "600",
    color: "#424651",
  },

  rowStyle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },

  howLong_container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px rgba(66, 70, 81, 0.36)",
    borderRadius: "15px",
    padding: "1.5% 4.5%",
    marginRight: "2%",
    marginTop: "3%",
    cursor: "pointer", // Add cursor pointer to indicate it's clickable
    transition: "background-color 0.3s", // Add a smooth background color transition for click effect
    // "&:hover": {
    //   backgroundColor: "#4f82af",
    // },

    "&:hover": {
      boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.3)",
      border: "solid .5px rgba(66, 70, 81, 0.36)",
      // Change the background color on hover
      "& $lableTxt": {
        // fontWeight: "600",
      },
    },
  },
  lableTxt: {
    fontSize: "clamp(14px, 1.5vw, 18px)",
    color: "rgba(66, 70, 81, 0.6)",
    width: "100%",
    transition: "color 0.3s", // Add a smooth color transition for the hover effect
    // "&:hover": {
    //   fontWeight: "600", // Change the color on hover
    // },
    [theme.breakpoints.down("480")]: {
      width: "39%",
    },
  },

  boatName: {
    fontSize: "clamp(16px, 1.3vw, 26px)",
    fontWeight: "600",
    color: "#424651",
  },

  boat_details_data: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    margin: "5% 0",
  },

  boat_details_label: {
    fontSize: "clamp(16px, 1.5vw, 18px)",
    color: "rgba(66, 70, 81, 0.6)",
  },

  boat_details_values: {
    fontSize: "clamp(16px, 1.5vw, 18px)",
    color: "#424651",
  },

  selected_date_txt: {
    fontSize: "clamp(14px, 2.5vw, 22px)",
    fontWeight: "600",
    color: "#424651",
    width: "100%",
  },

  personInfoTxt: {
    // fontFamily: "Poppins",
    fontSize: "clamp(14px, 2.5vw, 20px)",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.15,
    letterSpacing: "normal",
    color: "#424651",
  },

  dropDownBoxStyle: {
    borderRadius: "5px",
    marginTop: "10%",
  },

  borderRemove: {
    border: "none !important",
    outline: "none !important",
    width: "50%",
  },

  send_a_booking_req: {
    width: "80%",
    marginTop: "5%",
    borderRadius: "3px",
    border: "solid 1px #026b93",
    padding: "5% 10%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    alignSelf: "center",
    transition: "background-color 0.3s",
    backgroundColor: "#026b93", // Add a smooth transition for the background color
    "&:hover": {
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.34)",
      borderRadius: "5px",
      // Change the background color on hover
      "& $send_a_booking_req_txt": {
        fontWeight: "600",
      },
    },
  },
  send_a_booking_req_txt: {
    fontSize: "clamp(16px, 1vw, 20px)", // Adjust the range as needed
    fontWeight: "400",
    textAlign: "center",
    color: "white",
  },

  youTube_image: {
    width: "100%",
    // height: "50%",
  },

  time_selection_column_box: {
    borderRight: "1px solid black",
    width: "auto",
    height: "200px",
    overflowY: "auto",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      width: "0", // WebKit (Safari, Chrome)
    },
  },

  time_selection_value_txt: {
    cursor: "pointer",
    letterSpacing: 0.5,
    padding: 5,
    fontSize: "clamp(12px, 1.5vw, 18px)",
  },

  txtField_errorMsg: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  Error_msg_txt: {
    fontSize: "clamp(10px, 1.5vw, 12px)",
    color: "#dc143c",
  },

  youtube_icon_style: {
    position: "absolute",
    width: "clamp(46px, 5vw, 89px)", // Adjust the range as needed
    height: "clamp(32px, 4vw, 62px)",
    alignContent: "center",
    color: "red",
    backgroundColor: "red",
    borderRadius: "10px",
  },
  placeholder: {
    '& input::placeholder': {
      fontSize: 'clamp(14px, 0.9vw, 20px)',
      color: '#474747'
    },
  },
  time_box: {
    width: '100%'
  },
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    FullContainer: {
      padding: "0.2%",
    },

    payment_detail_Container: {
      display: "none",
    },

    personalInfo_Container: {
      width: "100%",
      // padding:
    },

    show_below_payment_detail: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      backgroundColor: "white",
      boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.09)",
      margin: "10% 0",
      paddingBottom: "10%",
    },

    send_a_booking_req: {
      width: "80%",
      marginTop: "5%",
      borderRadius: "1px",
      border: "solid 1px #026b93",
      padding: "3% 20%",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
      transition: "background-color 0.3s",
      backgroundColor: "#026b93", // Add a smooth transition for the background color
      "&:hover": {
        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.24)",
        // Change the background color on hover
        "& $send_a_booking_req_txt": {
          fontWeight: "800",
        },
      },
    },

    youTube_image_below: {
      width: "100%",
      height: "50%",
    },

    selection_box_container: {
      marginTop: "5%",
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },

    each_selection_box: {
      width: "100%",
      marginRight: "5%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },

    dropDownBoxStyle: {
      width: "30%",
      borderRadius: "5px",
    },
  },
  "@media (max-height: 575.98px) and (orientation: landscape)": {
    show_below_payment_detail: {
      display: "flex",
    },
  },
}));

const dateList = [
  { id: 1, day: "SAT" },
  { id: 2, day: "SUN" },
  { id: 3, day: "MON" },
  { id: 4, day: "TUE" },
  { id: 5, day: "WED" },
  { id: 6, day: "THU" },
  { id: 7, day: "FRI" },
];

const howLongList = [
  { id: 1, data: "Less than 2 h" },
  { id: 2, data: "2 - 4 h" },
  { id: 3, data: "5 - 7 h" },
  { id: 4, data: "8 - 10 h" },
  { id: 5, data: "more than 10 h" },
];

const Adults_options = [
  { id: 0 },
  { id: 1 },
  { id: 2 },
  { id: 3 },
  { id: 4 },
  { id: 5 },
  { id: 6 },
  { id: 7 },
  { id: 8 },
];

const hours_options = [
  { id: '1 hour' },
  { id: '2 hour' },
  { id: '3 hour' },
  { id: '4 hour' },
  { id: '5 hour' },
  { id: '6 hour' },
  { id: '7 hour' },
  { id: '8 hour' },
  { id: '9 hour' },
  { id: '10 hour' },
  { id: '11 hour' },
  { id: '12 hour' },
  { id: '13 hour' },
  { id: '14 hour' },
  { id: '15 hour' },
  { id: '16 hour' },
  { id: '17 hour' },
  { id: '18 hour'},
  { id: '19 hour' },
  { id: '20 hour' },
  { id: '21 hour' },
  { id: '22 hour' },
  { id: '23 hour' },
  { id: '24 hour' },
  { id: '2 days' },
  { id: '3 days' },
  { id: '4 days' },
  { id: '5 days' },
  { id: '6 days' },
  { id: '7 days' },
];

const timeTxtStyle = {
  cursor: "pointer",
  letterSpacing: 0.5,
  padding: 5,
  fontSize: 14,
};

const selectStyles = (theme) => ({
  root: {
    //outer

    // width: "100%",

    // Styles for the input text
    "& .MuiInputBase-input": {
      // padding: "3% 3%",
      fontSize: "clamp(12px, 0.9vw, 20px)",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      textAlign: "center",
      color: "#424651",
      borderBottom: "none",
      // backgroundColor: "red",
    },

    // "& .MuiInputBase-input MuiOutlinedInput-input": {},

    // Styles for the placeholder text
    "& .MuiFormLabel-root": {
      borderBottom: "none",
      // color: "red",
    },

    // Styles for the text field box (border and background)
    "& .MuiOutlinedInput-root": {
      fontSize: "clamp(12px, 0.9vw, 20px)",
      border: "solid 1.5px rgba(66, 70, 81, 0.2)",
    },

    // Remove the notched outline
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
      fontSize: "clamp(12px, 0.9vw, 20px)",
      border: "none",
    },
  },
});

const CustomSelectTextField = withStyles(selectStyles)(TextField);
// // Apply styles for small screens
// [theme.breakpoints.down("sm")]: {
//   "& .MuiInputBase-input": {
//     fontSize: "12px",
//   },
//   "& .MuiOutlinedInput-root": {},
//   // Add styles for the error message span element
//   "& .Mui-error": {
//     padding: 0, // Remove padding
//     margin: 0, // Remove margin
//   },
// },

// // Adjust for medium screens (md)
// [theme.breakpoints.up("md")]: {
//   "& .MuiInputBase-input": {
//     fontSize: "14px",
//     // color: "black",
//   },
//   "& .MuiOutlinedInput-root": {},
// },

// // Adjust for large screens (lg)
// [theme.breakpoints.up("lg")]: {
//   "& .MuiInputBase-input": {
//     fontSize: "16px",
//     // color: "pink",
//   },
//   "& .MuiOutlinedInput-root": {},
// },
