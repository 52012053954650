import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { PageHeader } from "../../page-header/PageHeader";
import { FileCopy, Done } from "@material-ui/icons";
import {
  Instagram,
  Star,
  StarOutline,
  Twitter,
  YouTube,
} from "@material-ui/icons";
import IMAGES from "../../../Images";
import { StarRating } from "../../../../UI kit/5Star/StarRating";
import { HeaderContent } from "../../../Common/map/HeaderContent";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Footer from "../../../../Component/Footer/Footer";
import { useEffect } from "react";
import { useState } from "react";
import {
  boat_booking_detail_API,
  cancel_trip,
  city_list_API,
  create_refund,
  recent_activity_API,
  user_bookedBoat_detail_API,
  refund_details
} from "../../../../Service/api";
import moment from "moment";
import Loader from "../../../Loader";
import PopupPage from "../../../Common/PopupPage/PopupPage";
import ConfirmationModal from "../../../Common/PopupPage/confirmation"
import { popup_page } from "../../../../redux/slices";
import socket from '../../../../Service/SocketIo'

export const BookedReqDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const dashboard = useSelector((state) => state?.dashboard);
  const classes = useStyles({ min: 10, max: 60, unit: "px" });
  const auth = useSelector((state) => state?.auth);
  const [isLoading, setIsLoading] = useState(false);
  const [boatBookingDetail, setBoatBookingDetail] = useState('');
  const [cityList, setCityList] = useState([]);
  const [cityName, setCityName] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openmodal,setopenmodal]=useState(false)
  const [recentActivity, setRecentActivity] = useState([]);
  const [copyIcon, setCopyIcon] = useState(null);
  const [refund_detail,setrefund_details]=useState(null)

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  useEffect(() => {
    handleGetBookedReqDetails()
  }, [auth?.AuthToken, auth?.userDetails?.user_type]);

  useEffect(() => {
    handleGetRecentActivity()
  }, []);

  useEffect(() => {
    setCityList([]);
    city_list_API()
      .then((res) => {
        // console.log("res", res?.data);
        if (res?.data?.success) {
          setCityList(res?.data?.parameters);
        }
      })

      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    cityList.map((city) => {
      if (city.id === boatBookingDetail?.marine_city) {
        setCityName(city.city);
      }
    });
  }, [cityList]);

  const handleGetBookedReqDetails = () =>{
    console.log(location?.state?.boat_booking_id,'booking id');
    setIsLoading(true);
    let payload = {
      boat_booking_id: location?.state?.boat_booking_id,
    };
    if (auth?.userDetails?.user_type === "CUSTOMER") {
      boat_booking_detail_API(auth?.AuthToken, payload)
        .then((res) => {
          console.log(res?.data, "datas");
          if (res?.data?.success) {
            setBoatBookingDetail(res?.data?.data);
          } else {
            toast.error(res?.data?.error ?? res?.data?.error, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("boat booking detail err", err);
          toast.error(err?.response?.data?.message??"Something went wrong. Please try again later", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setIsLoading(false);
        });
    } else {
      user_bookedBoat_detail_API(auth?.AuthToken, payload)
        .then((res) => {
          console.log("resp",res?.data);
          if (res?.data?.success) {
            setBoatBookingDetail(res?.data?.parameters[0]);
          } else {
            toast.error(res?.data?.error ?? res?.data?.error, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("user booked detail err", err);
          toast.error(err?.response?.data?.message??"Something went wrong. Please try again later", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setIsLoading(false);
        });
    }
  }

  const handleGetRecentActivity = () =>{
    let payload = {
      bookingId: location?.state?.boat_booking_id,
    };
    recent_activity_API(auth?.AuthToken, payload)
      .then((res) => {
        console.log(res?.data, "recent activity");
        setRecentActivity(res?.data);
      })
      .catch((err) => {
        console.log("recent activity api err=> ", err);
      });
  }

  const handle_navigation = (name) => {
    if (name === "chat") {
      navigate("/allMessage");
    } else if (name === "My Listings") {
      navigate("/myListings");
    } else {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleCreateRefund = (payload,boatOwnerId) => {
    setopenmodal(false)
    setIsLoading(true);
    create_refund(auth?.AuthToken, payload)
    .then((res) => {
        console.log(res, "create charge res?.data");
        if(res.status===200){
          toast.success(res.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          socket.client.emit("getUnreadCountNotification", boatOwnerId, (err) => {
            console.log("getunread count notification err", err);
          });
          socket.client.emit("getUnreadCountNotification", auth?.userId, (err) => {
            console.log("getunread count notification err", err);
          });
          handleGetBookedReqDetails()
          setIsLoading(false);
          handleClose()
        
        }
        else if(res.data.status===200){
          console.log(res,"res")
          toast.success("Trip cancelled Refund request submitted", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
          })
          socket.client.emit("getUnreadCountNotification", boatOwnerId, (err) => {
            console.log("getunread count notification err", err);
          });
          handleGetBookedReqDetails()
          setIsLoading(false);
          handleClose()
        } else {
          console.log(res,"res")
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
          });
          setIsLoading(false);
          handleClose()
        }
        
    })
    .catch((error) => {
        console.error("Error creating charge:", error);
        toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000
        });
        throw error;
        setIsLoading(false);
        handleClose()
    })
  };

  const handleCancelTrip = (boat_booking_id) => {
    console.log(boat_booking_id);
    let payload = {
      boat_booking_id: boat_booking_id,
    };
    cancel_trip(auth?.AuthToken, payload)
      .then((res) => {
        console.log(res?.data, "cancel trip");

        if (res?.data?.message === "Trip cancelled successfully") {
          let updateBookingDetails = {
            ...boatBookingDetail,
            trip_status: "TRIP_CANCEL",
          };
          setBoatBookingDetail(updateBookingDetails);
        }
        setOpenPopup(false);
        dispatch(popup_page(null));
      })
      .catch((err) => {
        console.log("cancel trip error", err);
        setOpenPopup(false);
        dispatch(popup_page(null));
      });
  };
  
  const handleCopyContent = (copy_icon, copy_content) => {
    setCopyIcon(copy_icon);
    // var copyText = document.getElementById("copy_content");
    // copyText.select();
    navigator.clipboard.writeText(copy_content);
    // alert("value copied");
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  const handleClose = () => {
    setopenmodal(false);
  };

  const handleStatusPopup = (status) => {
    if (status?.button === "accept") {
      if (status?.status === "cancel") {
        handleCancelTrip(dashboard?.popup_page?.booking_id);
      }
    } else {
      setopenmodal(false);
    }
  };
  const handleConfirm = (status) => {
    if (status?.button === "accept") {
      if (status?.status === "cancel") {
        handleCancelTrip(dashboard?.popup_page?.booking_id);
      }
    } else {
      setopenmodal(false);
    }
  };

  const handleCancelOpenPopup = () => {
    setIsLoading(true); 
    
    const payload = {
        transaction_id: boatBookingDetail.bookingTransactions.transaction_id
    };

    refund_details(auth?.AuthToken, payload)
        .then((res) => {
            console.log(res, "refunddetails");
            if (res.data.success===true) {
              setrefund_details(res.data); // Ensure you're setting the correct data
                // toast.info("", {
                //     position: toast.POSITION.TOP_RIGHT,
                //     autoClose: 2000,
                // })
                setopenmodal(true); // Open the modal after successfully fetching refund details
                // handleGetBookedReqDetails()
            } else {
                console.log(res, "res");
                toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 5000,
                });
            }
            setIsLoading(false)
        })
        .catch((error) => {
            console.error("Error creating charge:", error);
            toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
            });
            setIsLoading(false)
        })
};

  return (
    <>
      {isLoading ? <Loader loading={isLoading} /> : null}
      <PageHeader
        showLoginSignUp={auth?.AuthToken ? false : true}
        handle_navigation={handle_navigation}
        presentPage={"bookedReqDetails"}
        link1={"Boat Offers"}
        link2={"Scuba Courses/Programs"}
        link3={"Scuba Diving Trips"}
        link4={
          auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
            ? "My Listings"
            : null
        }
      />
      <div className={classes.myListingsContainer}>
        <div className={classes.body}>
          <div className={classes.headerContent}>
            <div className={classes.insideHeader}>
              <div className={classes.contentContainer}>
                <div className={classes.dateTitleDiv}>
                  <div>
                    <img
                      src={
                        boatBookingDetail?.profile_image ?? IMAGES.PROFILE_ICON
                      }
                      alt=""
                      className={classes.profileIcon}
                    />
                  </div>
                  <div className={classes.titleContainer}>
                    <Typography className={classes.titleName}>
                      {boatBookingDetail?.user_name}
                    </Typography>
                    <Typography className={classes.subTitle}>
                      {boatBookingDetail?.booking_boat_detail?.boat_name +
                        ", " +
                        cityName}
                    </Typography>
                    <Typography className={classes.subTitle}>
                      {moment(boatBookingDetail?.booking_date).format(
                        "DD-MMM-YYYY"
                      )}
                    </Typography>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center gap-3">
                  <div className={classes.trip_details}>
                    <div className="d-flex flex-column gap-2">
                      {boatBookingDetail?.bookingTransactions?.order_id?
                      <div className="d-flex">
                        <span className="pe-1">Order Id - </span>
                        <div className="d-flex gap-2 align-items-center">
                          <span id="copy_content" className="fw-semibold">
                            {boatBookingDetail?.bookingTransactions?.order_id}
                          </span>
                          <span
                            onClick={() =>
                              handleCopyContent(
                                "orderId",
                                boatBookingDetail?.bookingTransactions?.order_id
                              )
                            }
                          >
                            {copyIcon === "orderId" ? (
                              <Done
                                style={{
                                  fontSize: "clamp(14px,2vw,20px)",
                                }}
                              />
                            ) : (
                              <FileCopy
                                style={{
                                  fontSize: "clamp(14px,2vw,20px)",
                                }}
                              />
                            )}
                          </span>
                        </div>
                      </div>:null
                      }
                      {/* <div
                        className=""
                        style={{
                          display:
                            auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
                              ? "flex"
                              : "none",
                        }}
                      >
                        <span className="pe-1">Transaction Id - </span>
                        <div className="d-flex gap-2 align-items-center">
                          <span id="copy_content" className="fw-semibold">
                            {
                              boatBookingDetail?.bookingTransactions?.transactionRefid
                            }
                          </span>
                          <span
                            className="fs-6"
                            onClick={() => handleCopyContent(
                              "transactionId",
                              boatBookingDetail?.bookingTransactions?.transactionRefid
                              )
                            }
                          >
                            {
                              copyIcon === 'transactionId'
                              ? 
                              <Done
                                style={{
                                  fontSize: "clamp(14px,2vw,20px)",
                                }}
                              />
                              :
                              <FileCopy
                              style={{
                                fontSize: "clamp(14px,2vw,20px)",
                              }}
                            />
                            }
                          </span>
                        </div>
                      </div> */}
                      <div className="d-flex">
                        <span className="pe-1">Booking status - </span>
                        <span className="fw-semibold">
                          {boatBookingDetail?.status?.charAt(0)+boatBookingDetail?.status?.slice(1).toLowerCase()}
                          {/* status */}
                        </span>
                      </div>
                      <div className="d-flex">
                        <span className="pe-1">Payment Status - </span>
                        <span className="fw-semibold">
                          {boatBookingDetail?.bookingTransactions?
                          boatBookingDetail?.bookingTransactions?.status.charAt(0)+
                          boatBookingDetail?.bookingTransactions?.status.slice(1).toLowerCase():'Pending'
                          }
                        </span>
                      </div>
                     
                      <div
                        className=""
                        style={{
                          display:
                            auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
                              ? "flex"
                              : "none",
                        }}
                      >
                        <span className="pe-1">Price - </span>
                        <span className="fw-semibold">
                          {boatBookingDetail?.price} SAR
                        </span>
                      </div>
                      {
                      // (boatBookingDetail?.status === 'CANCELLED' &&
                      // boatBookingDetail?.bookingTransactions?.status === 'FAILED') ||
                      // boatBookingDetail?.trip_status === 'TRIP_CANCEL'
                      // ? null:
                      <div
                        className={classes.cancelDiv}
                        style={{
                          display:
                            auth?.userId !== boatBookingDetail?.boato_owner_id&&
                            boatBookingDetail?.bookingTransactions?.refund_status !== "REFUNDED"&& 
                            boatBookingDetail?.bookingTransactions?.status === "PAID"&&
                            boatBookingDetail.status !== "CANCELLED"
                              ? "flex"
                              : "none",
                        }}
                      >
                        <Button
                            variant="outlined" // Specify the variant: 'outlined', 'contained', or 'text'
                            color="primary" // Specify the color: 'primary', 'secondary', 'inherit', 'default'
                            onClick={handleCancelOpenPopup} // Add onClick handler
                        >
                            Give a refund
                        </Button>

                      </div>
                      }
                      {boatBookingDetail?.cancelBy === "BOAT_OWNER"?
                      <div className="fs-6 text-danger fw-semibold"
                      style={{
                        maxWidth: '23rem',
                        textWrap: 'wrap',
                        minWidth: '10rem'
                      }}
                      >
                        {
                          auth?.userDetails?.user_type === "BOAT_OWNER"?
                          'You cancelled the booking'
                          :"Your booking cancelled by boat owner, your payment will be refunded within 2 to 3 business days"
                        }
                      </div>:null
                      }
                    </div>
                    {/* <div className="d-flex flex-column gap-2"> */}
                    {/* <div className="d-flex flex-column text-center">
                        <span className="fw-bold">Trip Status</span>
                        <span>{boatBookingDetail?.trip_status}</span>
                      </div> */}
                    {/* <div className="d-flex flex-column text-center">
                        <span className="fw-bold">Trip date</span>
                        <span>{boatBookingDetail?.booking_date}</span>
                      </div>
                      <div className="d-flex flex-column text-center">
                        <span className="fw-bold">Trip time</span>
                        <span>
                          {moment(
                            boatBookingDetail?.start_time,
                            "HH:mm:ss"
                          ).format("hh:mm A")}
                        </span>
                      </div>
                      <div className="d-flex flex-column text-center">
                        <span className="fw-bold">Trip day</span>
                        <span>
                          {moment(boatBookingDetail?.booking_date).format(
                            "dddd"
                          )}
                        </span>
                      </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.userDetailsContainer_parentDiv}>
            <div className={classes.userDetailsContainer}>
              <div className={classes.userBoxDiv_upper}>
                <div>
                  <div className={classes.rowDiv}>
                    <Typography className={classes.title}>Trip</Typography>
                  </div>
                  {/* <div
                    className={classes.rowDivShowUserDetails}
                    style={{ marginTop: "40px" }}
                  >
                    <Typography className={classes.lableTxt}>
                      Transaction Id
                    </Typography>
                    <Typography className={classes.userInfoTxt}>
                      <span id="copy_content" className="pe-2" style={{wordBreak: 'break-all'}}>
                        {
                          boatBookingDetail?.bookingTransactions
                            ?.transactionRefid
                        }
                      </span>
                      <span
                        className="fs-6"
                        onClick={() =>
                          handleCopyContent(
                            "transactionId",
                            boatBookingDetail?.bookingTransactions
                              ?.transactionRefid
                          )
                        }
                      >
                        {copyIcon === "transactionId" ? (
                          <Done
                            style={{
                              fontSize: "clamp(14px,2vw,20px)",
                            }}
                          />
                        ) : (
                          <FileCopy
                            style={{
                              fontSize: "clamp(14px,2vw,20px)",
                            }}
                          />
                        )}
                      </span>
                    </Typography>
                  </div> */}
                  <div
                    className={classes.rowDivShowUserDetails}
                  >
                    <Typography className={classes.lableTxt}>
                      Duration
                    </Typography>
                    <Typography className={classes.userInfoTxt}>
                      {boatBookingDetail?.duration_type}
                    </Typography>
                  </div>
                  <div className={classes.rowDivShowUserDetails}>
                    <Typography className={classes.lableTxt}>Depart</Typography>
                    <Typography className={classes.userInfoTxt}>
                      {moment(boatBookingDetail?.start_time, "HH:mm:ss").format(
                        "hh:mm A"
                      )}
                    </Typography>
                  </div>
                  <div className={classes.rowDivShowUserDetails}>
                    <Typography className={classes.lableTxt}>Return</Typography>
                    <Typography className={classes.userInfoTxt}>
                      {moment(boatBookingDetail?.end_time, "HH:mm:ss").format(
                        "hh:mm A"
                      )}
                    </Typography>
                  </div>
                  <div className={classes.rowDivShowUserDetails}>
                    <Typography className={classes.lableTxt}>
                      Trip Date
                    </Typography>
                    <Typography className={classes.userInfoTxt}>
                      {moment(boatBookingDetail?.booking_date).format('DD-MMM-YYYY')}
                    </Typography>
                  </div>
                  <div className={classes.rowDivShowUserDetails}>
                    <Typography className={classes.lableTxt}>
                      Trip Day
                    </Typography>
                    <Typography className={classes.userInfoTxt}>
                      {moment(boatBookingDetail?.booking_date).format("dddd")}
                    </Typography>
                  </div>
                  <div className={classes.rowDivShowUserDetails}>
                    <Typography className={classes.lableTxt}>
                      Trip Status
                    </Typography>
                    <Typography
                      className={classes.userInfoTxt}
                      style={{
                        color:
                          boatBookingDetail?.trip_status === "TRIP_CANCEL"
                            ? "red"
                            : boatBookingDetail?.trip_status === "PENDING" || 
                              boatBookingDetail?.trip_status === "TRIP_INITIATED" ||
                              boatBookingDetail?.trip_status === "PAYMENT_INITIATED"
                            ? "#026b93"
                            : boatBookingDetail?.trip_status === "TRIP_STARTED"
                            ? "green"
                            : isLoading? 'black':"red",
                        fontWeight: "600",
                      }}
                    >
                      {boatBookingDetail?.trip_status === "TRIP_CANCEL"
                        ? "Cancelled"
                        : boatBookingDetail?.trip_status === "PENDING"
                        ? "Pending"
                        : boatBookingDetail?.trip_status === "TRIP_INITIATED"
                        ? "Trip Initiated"
                        : boatBookingDetail?.trip_status === "PAYMENT_INITIATED"
                        ? "Payment Initiated"
                        : boatBookingDetail?.trip_status === "TRIP_STARTED"
                        ? "Trip Started"
                        : isLoading? '-':"Trip Ended"}
                    </Typography>
                  </div>
                </div>
                <div>
                  <div className={classes.rowDiv}>
                    <Typography className={classes.title}>Guests</Typography>
                  </div>
                  <div
                    className={classes.rowDivShowUserDetails}
                    style={{ marginTop: "40px" }}
                  >
                    <Typography className={classes.lableTxt}>Adults</Typography>
                    <Typography className={classes.userInfoTxt}>
                      {boatBookingDetail?.adults}
                    </Typography>
                  </div>
                  <div className={classes.rowDivShowUserDetails}>
                    <Typography className={classes.lableTxt}>
                      Seniors
                    </Typography>
                    <Typography className={classes.userInfoTxt}>
                      {boatBookingDetail?.seniors}
                    </Typography>
                  </div>
                  <div className={classes.rowDivShowUserDetails}>
                    <Typography className={classes.lableTxt}>
                      Children
                    </Typography>
                    <Typography className={classes.userInfoTxt}>
                      {boatBookingDetail?.children}
                    </Typography>
                  </div>
                  <div className={classes.rowDivShowUserDetails}>
                    <Typography className={classes.lableTxt}>
                      Infants
                    </Typography>
                    <Typography className={classes.userInfoTxt}>
                      {boatBookingDetail?.infants}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={classes.userBoxDiv} style={{ marginTop: "24px" }}>
                <div className={classes.rowDiv}>
                  <Typography className={classes.title}>
                    Recent Activity
                  </Typography>
                </div>
                <div
                  className={classes.rowDivShowUserDetails}
                  style={{ marginTop: "40px" }}
                >
                  <div className="d-flex flex-column gap-4">
                    {recentActivity?.map((item) => (
                      <div className="d-flex">
                        <Typography className={classes.lableTxt_recentActivity}>
                          {moment(item?.createdAt).format("DD MMM YYYY hh:mmA")}
                        </Typography>
                        <Typography className={classes.userInfoTxt}>
                          {item?.description}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {(boatBookingDetail?.trip_status === "TRIP_END" &&
              auth?.userDetails?.user_type === "CUSTOMER") ||
            (auth?.userDetails?.user_type === "BOAT_OWNER" &&
              boatBookingDetail?.trip_status === "TRIP_END" &&
              boatBookingDetail?.booking_boat_detail?.boats_user_ratings) ? (
              <div className={classes.userDetailsSingleContainer}>
                <Typography
                  className={classes.title}
                  style={{ marginBottom: "9%", whiteSpace: "nowrap" }}
                >
                  {auth?.userDetails?.user_type === "CUSTOMER"
                    ? "Your Review"
                    : "User's Review"}
                </Typography>
                <div className={classes.starRating_div}>
                  {boatBookingDetail?.booking_boat_detail?.boats_user_ratings?
                    <StarRating rating={boatBookingDetail?.booking_boat_detail?.boats_user_ratings[0]?.rating} />:
                    <StarRating rating={0} />
                  }
                </div>
                <Typography
                  className={classes.userInfoTxt}
                  style={{
                    marginTop: "50px",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {boatBookingDetail?.booking_boat_detail?.boats_user_ratings ? (
                    <span>
                      {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. */}
                      {boatBookingDetail?.booking_boat_detail?.boats_user_ratings[0]?.review}
                    </span>
                  ) : (
                    <div
                      className={classes.cancelDiv}
                      style={{
                        display:
                          auth?.userDetails?.user_type === "CUSTOMER"
                            ? "flex"
                            : "none",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="inherit"
                        className={classes.buttonStyles}
                        onClick={() => navigate("/reviewPage")}
                      >
                        Give a feedback
                      </Button>
                    </div>
                  )}
                </Typography>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      
      {openPopup ? (
                <PopupPage
                    open={openPopup}
                    handleClosePopup={handleClosePopup}
                    handleStatus={handleStatusPopup}
                    title={`Are you sure you want to ${dashboard?.popup_page?.ride_mode} your ride`}

                />
            ) : null}
            
            {openmodal ? (
                <ConfirmationModal
                    open={openmodal} 
                    handleClose={handleClose}
                    // handleConfirm={handleConfirm}
                    title="Refund"
                    createRefund={handleCreateRefund}
                    transactionId={boatBookingDetail?.bookingTransactions?.transaction_id}
                    refund_details={refund_detail}
                    bookingDetails={boatBookingDetail}
                />
            ) : null}
      <div className="d-none d-md-block">
        <Footer />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  myListingsContainer: {
    display: "flex",
    width: "100%",
    backgroundColor: "#f6f6f6",
    // height: "100vw",
    flexDirection: "column",
  },
  body: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    paddingBottom: "114px",
  },
  headerContent: {
    display: "flex",
    width: "100%",
    // backgroundImage: `url(${IMAGES.subaDiving})`,
    backgroundColor: "lightgray",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "100%",
  },
  insideHeader: {
    display: "flex",
    width: "100%",
    // flex: 1,
    // flexDirection: "row",
    // alignContent: 'center',
    // alignItems: "center",
    // justifyContent: "space-between",
    // alignItems: "flex-end",
    backgroundColor: "rgba(169, 209, 239, 0.5)",
    padding: "5% 15%",
    alignContent: "center",
    // alignSelf: "center",
    // paddingBottom: "3%",
    [theme.breakpoints.down("992")]: {
      padding: "5% 6%",
      //   paddingBottom: "3%",
    },
    [theme.breakpoints.down("768")]: {
      padding: "5% 7%",
      // flexDirection: 'column',
      // display: 'flex'
      //   paddingBottom: "3%",
    },
  },
  contentContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    whiteSpace: "nowrap",
    // flexDirection: "column",
    // opacity: 1,
    width: "100%",
    [theme.breakpoints.down("481")]: {
      flexDirection: "column",
      gap: "20px",
      // justifyContent: 'center',
      alignItems: "flex-start",
    },
  },
  titleName: {
    fontSize: "27px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.46,
    letterSpacing: "normal",
    // color: "#424651",
    color: "black",
    textAlign: "left",
    [theme.breakpoints.down("768")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.down("480")]: {
      fontSize: "17px",
    },
  },

  title: {
    fontSize: "24px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.46,
    letterSpacing: "normal",
    // color: "#424651",
    color: "black",
    textAlign: "left",
    [theme.breakpoints.down("768")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("480")]: {
      fontSize: "15px",
    },
  },
  subTitle: {
    // marginTop: "8px",
    fontSize: "22px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    textWrap: "wrap",
    // color: "#424651",
    color: "#36454F",
    [theme.breakpoints.down("768")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("480")]: {
      fontSize: "15px",
    },
  },
  profileIcon: {
    width: "130px",
    height: "129px",
    borderRadius: "100px",
    // backgroundColor: 'gray',
    [theme.breakpoints.down("sm")]: {
      width: "90px",
      height: "89px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "60px",
      height: "59px",
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    marginLeft: "7%",
    [theme.breakpoints.down("992")]: {
      marginLeft: "4%",
    },
    [theme.breakpoints.down("480")]: {
      marginLeft: "2%",
      gap: "5px",
    },
  },
  buttonStyles: {
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    whiteSpace: "nowrap",
    lineHeight: "1.46",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#3973a5",
    borderRadius: "10px",
    [theme.breakpoints.down("768")]: {
      fontSize: "12px",
    },
  },
  dateTitleDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('480')]: {
      gap: '6px'
    }
    // width: "100%",
  },
  userDetailsContainer_parentDiv: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: "114px",
    padding: "0px 16%",
    paddingTop: "8%",
    [theme.breakpoints.down("992")]: {
      padding: "0px 10%",
      flexDirection: "column",
      paddingTop: "8%",
    },
    [theme.breakpoints.down("768")]: {
      paddingTop: "8%",
    },
    [theme.breakpoints.down("480")]: {
      paddingTop: "8%",
      padding: "0px 6%",
    },
  },
  userDetailsContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    [theme.breakpoints.down("768")]: {
      width: "100%",
    },
  },
  userDetailsSingleContainer: {
    marginLeft: "20px",
    display: "flex",
    width: "50%",
    flexDirection: "column",
    backgroundColor: "white",
    justifyContent: "center",
    // alignSelf: "center",
    // alignContent: "center",
    padding: "8%",
    alignItems: "center",
    [theme.breakpoints.down("992")]: {
      width: "100%",
      marginLeft: "0px",
      marginTop: "24px",
    },
    [theme.breakpoints.down("480")]: {
      padding: "40px",
    },
  },
  userBoxDiv_upper: {
    display: "flex",
    flexDirection: "row",
    // gap: "25px",
    backgroundColor: "white",
    justifyContent: "space-between",
    padding: "10% 10%",
    width: "100%",
    gap: '20px',
    [theme.breakpoints.down("992")]: {
      padding: "5% 10%",
      flexDirection: "column",
      gap: "25px",
    },
    [theme.breakpoints.down("480")]: {
      padding: "5% 8%",
    },
  },
  userBoxDiv: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    padding: "53px 87px",
    width: "100%",
    [theme.breakpoints.down("768")]: {
      padding: "5% 10%",
    },
    [theme.breakpoints.down("480")]: {
      padding: "5% 8%",
    },
  },
  rowDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  rowDivShowUserDetails: {
    display: "flex",
    flexDirection: "row",
    // gap: "10px",
    width: "100%",
    marginTop: "24px",
  },
  seeDetailsTxt: {
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "rgba(66, 70, 81, 0.6)",
    whiteSpace: "nowrap",
    marginLeft: "5px",
    [theme.breakpoints.down("768")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("480")]: {
      fontSize: "15px",
    },
  },
  lableTxt: {
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    width: "200px",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("768")]: {
      fontSize: "18px",
      width: "150px",
    },
    [theme.breakpoints.down("480")]: {
      fontSize: "15px",
      width: "150px",
    },
  },
  lableTxt_recentActivity: {
    fontSize: "20px",
    paddingRight: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    width: "300px",
    wordBreak: "break-word",
    // whiteSpace: "nowrap",
    [theme.breakpoints.down("768")]: {
      fontSize: "18px",
      width: "60%",
      paddingRight: "20px",
    },
    [theme.breakpoints.down("480")]: {
      fontSize: "15px",
      width: "150px",
    },
  },
  userInfoTxt: {
    fontSize: "20px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    width: "70%",
    whiteSpace: "normal",
    [theme.breakpoints.down("768")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("480")]: {
      fontSize: "15px",
    },
  },
  trip_details: {
    display: "flex",
    gap: "10%",
    fontSize: "clamp(14px,1.8vw,24px)",
  },
  // "@media (max-width: 768px)":{
  //   userDetailsContainer_parentDiv: {
  //     display: 'flex',
  //     flexDirection: 'column'
  //   },
  // },
}));
