import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, InputAdornment, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Add, RemoveCircle } from "@material-ui/icons";
import IMAGES from "../../../../Images";
import CalendarComponent from "../../../../Common/Calendar/CalendarComponent";
import { boat_register, update_boat } from "../../../../../Service/api";
import { toast } from "react-toastify";
import {
  boatServiceList,
  boatTypeList,
  boat_register_location_data,
  boat_register_step1,
  boat_register_step2,
  boat_register_step3,
  confirmTickMsg,
  single_boat_details_store,
} from "../../../../../redux/slices";
import Loader from "../../../../Loader";
import "./BoatOfferStep3.css";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, MenuItem, Select, Typography } from "@material-ui/core";
import { PageHeader } from "../../../page-header/PageHeader";
import { withStyles } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import { useSpring, animated } from "react-spring";
import Footer from "../../../../../Component/Footer/Footer";

const start_space_Validation = new RegExp(/^(?!\s).*/);
const youtubePattern = /^(https?:\/\/)?(www\.)?youtu(be\.com|\.be)\/.+$/;
const NotNumber = new RegExp(/^[^\d]*$/)

const styles = (theme) => ({
  root: {
    //outer
    marginTop: "0px",
    borderRadius: "8px",
    border: "solid 1px rgba(66, 70, 81, 0.2)",
    paddingTop: "3px",

    // Use !important to override the outline
    "& .MuiOutlinedInput-root.Mui-focused": {
      outline: "none !important",
    },

    // Styles for the input text
    "& .MuiInputBase-input": {
      fontSize: "clamp(12px, 0.9vw, 20px)",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      textAlign: "left",
      color: "#424651",
      borderBottom: "none",
      // borderBottom: "transparent",
      // backgroundColor: "red",
      padding: "5% 2%",
    },

    // Styles for the placeholder text
    "& .MuiFormLabel-root": {
      borderBottom: "none",
    },

    // Styles for the text field box (border and background)
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      borderBottom: "none",
      fontSize: "clamp(12px, 0.9vw, 20px)",
    },

    // Remove the notched outline
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
      fontSize: "clamp(12px, 0.9vw, 20px)",
      border: "none",
    },

    "& input::placeholder": {
      // fontSize: "8px",
      color: "rgba(66, 70, 81, 0.4)",
    },

    // Apply styles for small screens
    [theme.breakpoints.down("sm")]: {
      "& .MuiInputBase-input": {
        padding: "3% 2%",
        // backgroundColor: "lightcyan",
      },
      borderRadius: "15px",
    },

    // Adjust for medium screens (md)
    [theme.breakpoints.up("md")]: {
      "& .MuiInputBase-input": {
        padding: "2% 2%",
      },
      borderRadius: "15px",
    },

    // Adjust for large screens (lg)
    [theme.breakpoints.up("lg")]: {
      "& .MuiInputBase-input": {
        padding: "2% 2%",
      },
      borderRadius: "15px",
    },
  },
});

const CustomTextField = withStyles(styles)(TextField);

const hours = Array.from({ length: 12 }, (_, index) => index + 1);
const minute = Array.from({ length: 60 }, (_, index) => index + 1);
const period = Array.from({ length: 2 }, (_, index) => index + 1);

export const BoatOfferStep3 = () => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalRef = useRef(null);
  const divRef = useRef(null);
  const auth = useSelector((state) => state?.auth);
  const dashboard = useSelector((state) => state?.dashboard);
  const [greetingMessage, setGreetingMessage] = useState("");
  const [youTubeLink, setYouTubeLink] = useState("");
  const [youTubeLinkError, setYouTubeLinkError] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState([]);
  const [currentlySelectedDate, setCurrentlySelectedDate] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenIndex, setModalOpenIndex] = useState(null);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [greetingMessageError, setGreetingMessageError] = useState(false);
  const [selectedDateTimeError, setSelectedDateTimeError] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [hoveredTimeItem, setHoveredTimeItem] = useState(null);
  const [errorDublicateTime, setErrorDublicateTime] = useState("");
  // const [datePositionIndex, setDatePositionIndex] = useState("");
  const [datePositionIndex, setDatePositionIndex] = useState(null);
  const [loader, setLoader] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const [cancellationPolicy, setCancellationPolicy] = useState([
    {
      id: 1,
      policy:
        "Cancellations made 7 days or more in advance of the event date, will receive a 100% refund.",
      hour: 168,
      percentage: 100,
    },
    {
      id: 2,
      policy: "Cancellations made within 3 - 6 days will incur a 20% fee.",
      hour: 96,
      percentage: 50,
    },
    {
      id: 3,
      policy:
        "Cancellations made within 48 hours to the event will incur a 30% fee.",
      hour: 48,
      percentage: 30,
    },
  ]);
  const [cancellationPolicyError, setCancellationPolicyError] = useState(false);
  const [timeIntervelError, setTimeIntervelError] = useState("");
  let address_name = dashboard?.boat_reg_location_data?.Addresses
    ? dashboard?.boat_reg_location_data?.Addresses[0]?.Address1?.split(",")
    : null;
  const [triggerState, setTriggerState] = useState(0);
  let city_name = dashboard?.boat_reg_location_data?.Addresses
    ? dashboard?.boat_reg_location_data?.Addresses[0]?.City?.split(",")
    : null;
  //
  //
  //
  //
  const [ministry_of_transport_doc, setMinistry_of_transport_doc] =
    useState("");
  const [
    general_directorate_of_border_guard_doc,
    setGeneral_directorate_of_border_guard_doc,
  ] = useState("");
  const [
    boat_documentations_and_licenses,
    setBoat_documentations_and_licenses,
  ] = useState("");
  const [selectedImageFiles, setSelectedImageFiles] = useState([]);
  const [otherImg, setOtherImg] = useState([]);
  const [fileImg, setFileImg] = useState([]);
  const [hour, setHour] = useState({ hr: "00", index: null });
  const [minutes, setMinutes] = useState({ min: "00", index: null });
  const [format, setFormat] = useState({ format: "AM", index: null });
  const [copyTimeDateArr, setCopyTimeDateArr] = useState([]);
  //
  //
  //
  //
  //
  //

  //
  //
  //
  //
  //
  // console.log(selectedDateTime,'date time')
  useEffect(()=>{
    window.scrollTo(0,0)
  },[])
  
  useEffect(() => {
    // if(dashboard?.boat_register_step3_data){
    // if(selectedDateTime){
    handleHistory();
    setCopyTimeDateArr(
      selectedDateTime.filter(
        (item) => item.time.includes("00:00 AM") && item.time.length === 1
      )
    );
    // }
    // }
  }, [selectedDateTime]);
  console.log(dashboard?.single_boat_details,'single boat details')

  // useEffect(() => {
  //   const currentDate = selectedDateTime.find(
  //     (item) =>
  //       moment(item.date).format("DD/MM/YYY") ===
  //       moment(currentlySelectedDate).format("DD/MM/YYY")
  //   );

  //   currentDate?.time?.splice(
  //     hour.index || minutes.index || format.index,
  //     1,
  //     `${hour.hr}:${minutes.min} ${format.format}`
  //   );
  //   // setHour({hr:"00",index:null})
  //   // setMinutes({min:"00",index:null})
  //   // setFormat({format:"00",index:null})
  // }, [hour, minutes, format]);

  useEffect(() => {
    const selectedData = selectedDateTime[datePositionIndex]?.time;

    if (Array.isArray(selectedData)) {
      const sortedTimes = selectedData.map((item) => item).sort();

      const errorMessage = checkTimeIntervals(sortedTimes);
      if (errorMessage) {
        setTimeIntervelError(errorMessage);
      } else {
        setTimeIntervelError("");
        console.log("All time intervals are 1 hour.");
      }

      function checkTimeIntervals(times) {
        // Function to convert a time in "hh:mm AM/PM" format to minutes
        function timeToMinutes(time) {
          const parts = time.match(/(\d+):(\d+) ([APM]+M)/);
          let hours = parseInt(parts[1]);
          const minutes = parseInt(parts[2]);
          const ampm = parts[3];

          if (ampm === "PM" && hours !== 12) {
            hours += 12;
          } else if (ampm === "AM" && hours === 12) {
            hours = 0;
          }

          return hours * 60 + minutes;
        }

        for (let i = 1; i < times.length; i++) {
          const prevTime = timeToMinutes(times[i - 1]);
          const currTime = timeToMinutes(times[i]);
          const timeDifference = currTime - prevTime;

          if (timeDifference < 60 && timeDifference > -60) {
            // Check if the difference is less than 60 minutes
            return `Time interval between ${times[i - 1]} and ${
              times[i]
            } is less than 1 hour.`;
          }
        }

        return null;
      }
    }
  }, [selectedDateTime, datePositionIndex]);

  useEffect(() => {
    if (divRef.current) {
      const divHeight = divRef.current.offsetHeight;
    }

    function hasDuplicates(array) {
      return (
        new Set(array).size !== array.length ||
        array.find((time) => time.slice(0, 2) === "00"
        )
      );
    }

    function checkForDuplicates(data) {
      const duplicateItems = [];
      data.forEach((item, index) => {
        const isDuplicate = hasDuplicates(item.time);
        if (isDuplicate) {
          duplicateItems.push(item);
        }
      });
      setDuplicates(duplicateItems); // Update the state with duplicate items
    }

    checkForDuplicates(selectedDateTime);
  }, [divRef, selectedDateTime]);
  // console.log(dashboard?.single_boat_details)

  useEffect(() => {
    console.log("boat step3 data");
    if (dashboard?.boat_register_step3_data?.greeting_message) {
      setGreetingMessage(dashboard?.boat_register_step3_data?.greeting_message);
    }
    if (dashboard?.boat_register_step3_data?.youtube_link) {
      setYouTubeLink(dashboard?.boat_register_step3_data?.youtube_link);
    }
    if (dashboard?.boat_register_step3_data?.boats_timeslot.length) {
      // Simulate API call or data processing
      const fetchData = async () => {
        try {
          console.log("selecteddatetime data");
          // Simulate data transformation
          const selectedDateTime =
            dashboard?.boat_register_step3_data?.boats_timeslot.map((item) => ({
              date: moment(item?.date).toDate(),
              time: item?.time?.map((time) => time),
            }));

          setSelectedDateTime(selectedDateTime);
          setCurrentlySelectedDate(selectedDateTime[0]?.date);
        } catch (error) {
          console.error("Error fetching or processing data:", error);
        }
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    if(dashboard?.single_boat_details){
      setCancellationPolicy(
          dashboard?.single_boat_details?.boats_cancellation_policy?.map((item,i)=>(
          {
            id: i+1,
            policy: item?.policy_statement,
            hour: item?.hours,
            percentage: item?.percentage
          }
        ))
      )
    }
    if (
      dashboard?.single_boat_details?.greeting_message &&
      !dashboard?.boat_register_step3_data?.greeting_message
    ) {
      setGreetingMessage(dashboard?.single_boat_details?.greeting_message);
    }
    if (
      dashboard?.single_boat_details?.boats_timeslot &&
      !dashboard?.boat_register_step3_data?.boats_timeslot.length
    ) {
      // Simulate API call or data processing
      const fetchData = async () => {
        // Simulate data transformation
        const selectedDateTime =
          dashboard?.single_boat_details?.boats_timeslot.map((item) => ({
            date: moment(item?.date).toDate(), //format("DD/MM/YYYY")
            time: [convertTime(item?.start_time)],
          }));

        const result = selectedDateTime.reduce((acc, { date, time }) => {
          const found = acc.find(
            (a) =>
              moment(a.date).format("DD.MM.YYYY") ===
              moment(date).format("DD.MM.YYYY")
          );
          if (found) {
            found.time = [...new Set([...found.time, ...time])];
          } else {
            acc.push({ date, time });
          }
          return acc;
        }, []);

        setSelectedDateTime(result);
        setCurrentlySelectedDate(selectedDateTime[0]?.date);
      };
      fetchData();
    }
    if (
      dashboard?.single_boat_details?.youtybe_link &&
      !dashboard?.boat_register_step3_data?.youtube_link
    ) {
      setYouTubeLink(dashboard?.single_boat_details?.youtybe_link);
    }
    // if(dashboard?.single_boat_details?.boats_cancellation_policy.length){
    //   setCancellationPolicy([])
    //   dashboard?.single_boat_details?.boats_cancellation_policy.forEach((item,i)=>{
    //     // cancellationPolicy.push({id:cancellationPolicy.length+1,policy:item.policy_statement})
    //   })
    // }
  }, [
    dashboard?.single_boat_details?.boats_timeslot,
    dashboard?.single_boat_details?.greeting_message,
    dashboard?.single_boat_details?.youtybe_link,
    // dashboard?.single_boat_details?.boats_cancellation_policy
  ]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModalOpen(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (selectedDateTime.length > 0) {
      const index = selectedDateTime.findIndex((item) =>
        isSameDay(item.date, currentlySelectedDate)
      );
      setDatePositionIndex(index !== -1 ? index : null);
    } else {
      setDatePositionIndex(null);
    }
  }, [currentlySelectedDate, selectedDateTime]);
  // console.log(dashboard?.boat_register_step2_data?.upload_images_of_your_boat,'selectedimg')

  useEffect(() => {
    // Function to check if a string is a valid base64 string
    function isBase64(str) {
      try {
        return btoa(atob(str)) == str;
      } catch (err) {
        return false;
      }
    }

    if (dashboard?.boat_register_step1_data) {
      if (
        isBase64(dashboard?.boat_register_step1_data?.ministry_of_transport_doc)
      ) {
        let ministry_doc = convertBase64ToFile(
          dashboard?.boat_register_step1_data?.ministry_of_transport_doc,
          dashboard?.boat_register_step1_data?.ministry_of_transport_doc_name,
          dashboard?.boat_register_step1_data?.ministry_of_transport_doc_type
        );
        setMinistry_of_transport_doc(ministry_doc);
      } else {
        setMinistry_of_transport_doc(
          dashboard?.boat_register_step1_data?.ministry_of_transport_doc
        );
      }

      if (
        isBase64(
          dashboard?.boat_register_step1_data
            ?.general_directorate_of_border_guard_doc
        )
      ) {
        let general_direc_doc = convertBase64ToFile(
          dashboard?.boat_register_step1_data
            ?.general_directorate_of_border_guard_doc,
          dashboard?.boat_register_step1_data
            ?.general_directorate_of_border_guard_doc_name,
          dashboard?.boat_register_step1_data
            ?.general_directorate_of_border_guard_doc_type
        );
        setGeneral_directorate_of_border_guard_doc(general_direc_doc);
      } else {
        setGeneral_directorate_of_border_guard_doc(
          dashboard?.boat_register_step1_data
            ?.general_directorate_of_border_guard_doc
        );
      }

      if (
        isBase64(
          dashboard?.boat_register_step1_data?.boat_documentations_and_licenses
        )
      ) {
        let doc_licenses = convertBase64ToFile(
          dashboard?.boat_register_step1_data?.boat_documentations_and_licenses,
          dashboard?.boat_register_step1_data
            ?.boat_documentations_and_licenses_name,
          dashboard?.boat_register_step1_data
            ?.boat_documentations_and_licenses_type
        );
        setBoat_documentations_and_licenses(doc_licenses);
      } else {
        setBoat_documentations_and_licenses(
          dashboard?.boat_register_step1_data?.boat_documentations_and_licenses
        );
      }
    }
    if (
      dashboard?.boat_register_step2_data?.upload_images_of_your_boat?.length
    ) {
      const files = [];
      for (
        let i = 0;
        i <
        dashboard?.boat_register_step2_data?.upload_images_of_your_boat.length;
        i++
      ) {
        const base64String =
          dashboard?.boat_register_step2_data?.upload_images_of_your_boat[i];
        const fileName =
          dashboard?.boat_register_step2_data?.upload_images_of_your_boat_name[
            i
          ];
        const fileType =
          dashboard?.boat_register_step2_data?.upload_images_of_your_boat_type[
            i
          ];

        // Check if the data is a valid base64 string
        if (isBase64(base64String)) {
          const file = convertBase64ToFile(base64String, fileName, fileType);
          files.push(file);
          fileImg.push({ file: file, index: i });
        } else {
          // If it's not a base64 string, add the data directly to the array
          files.push(base64String?.path);
          otherImg.push(base64String?.path);
          // otherImg.push({file:base64String?.path,index:i});
        }
      }

      setSelectedImageFiles(files);
      console.log(files, "files");
    }
  }, [
    dashboard?.boat_register_step1_data,
    dashboard?.boat_register_step2_data,
  ]);
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //

  const isSameDay = (dateA, dateB) => {
    let DateA;
    let DateB;
    typeof dateA === "string" ? (DateA = new Date(dateA)) : (DateA = dateA);
    typeof dateB === "string" ? (DateB = new Date(dateB)) : (DateB = dateB);
    return (
      DateA?.getDate() === DateB?.getDate() &&
      DateA?.getMonth() === DateB?.getMonth() &&
      DateA?.getFullYear() === DateB?.getFullYear()
    );
  };

  const handleDateSelect = (date) => {
    setCurrentlySelectedDate(date);
  };

  const copyTimeData = (copy_index) => {
    const firstTimeData = selectedDateTime[copy_index].time;
    const updatedData = selectedDateTime.map((entry, index) => {
      if (copyTimeDateArr.includes(entry)) {
        return { ...entry, time: [...firstTimeData] };
      }
      return entry;
    });

    setSelectedDateTime(updatedData);
  };

  const handleRemoveDate = (targetDate) => {
    setTimeIntervelError("");
    var indexToRemove = -1;
    for (var i = 0; i < selectedDateTime.length; i++) {
      if (selectedDateTime[i].date.getTime() === targetDate.getTime()) {
        indexToRemove = i;
        break;
      }
    }
    if (indexToRemove !== -1) {
      setSelectedDateTime((prevState) => {
        const updatedSelectedDateTime = [...prevState];
        updatedSelectedDateTime.splice(indexToRemove, 1);

        if (updatedSelectedDateTime.length) {
          setCurrentlySelectedDate(
            updatedSelectedDateTime[updatedSelectedDateTime.length - 1].date
          );
        } else {
          setCurrentlySelectedDate(null);
        }
        return updatedSelectedDateTime;
      });
    }
  };

  const addExtraTime = (targetDate) => {
    setSelectedDateTime((prevState) => {
      return prevState.map((item) => {
        if (item.date.getTime() === targetDate.getTime()) {
          return {
            ...item,
            time: [...item.time, "00:00 AM"],
          };
        }
        return item;
      });
    });
  };

  const removeTimeField = (dateToRemove, timeIndexToRemove) => {
    const updatedSelectedDateTime = [...selectedDateTime];

    for (let i = 0; i < updatedSelectedDateTime.length; i++) {
      const entry = { ...updatedSelectedDateTime[i] };
      if (entry.date.toString() === dateToRemove.toString()) {
        const updatedTime = [...entry.time];
        if (timeIndexToRemove >= 0 && timeIndexToRemove < updatedTime.length) {
          updatedTime.splice(timeIndexToRemove, 1);
          entry.time = updatedTime;
        }
        updatedSelectedDateTime[i] = entry;
        break;
      }
    }
    return updatedSelectedDateTime;
  };
  // console.log(dashboard?.single_boat_details,'single_boat_details')

  const handleRemoveDateField = (dateIndex) => {
    console.log("working");
    let updatedcopyTimeDateArr = copyTimeDateArr.filter(
      (date, i) => i !== dateIndex
    );
    setCopyTimeDateArr(updatedcopyTimeDateArr);
  };

  const handleRemoveTimeField = (dateToRemove, timeIndexToRemove) => {
    const updatedSelectedDateTime = removeTimeField(
      dateToRemove,
      timeIndexToRemove
    );
    setSelectedDateTime(updatedSelectedDateTime);
    setModalOpen(false);
    setModalOpenIndex("");
  };

  const addCancellationPolicyField = () => {
    setCancellationPolicy((prevPolicy) => [
      ...prevPolicy,
      { id: prevPolicy.length + 1, policy: "" },
    ]);
  };

  const removeCancellationPolicyField = (index) => {
    setCancellationPolicy((prevPolicy) => {
      const updatedPolicies = [...prevPolicy];
      updatedPolicies.splice(index, 1);
      return updatedPolicies;
    });
  };

  const handleHourChange = (index, timeIndex, value, timeInput) => {
    handleTimeChange(index, timeIndex, value, timeInput);
  };

  // Define the common hover function
  const handleHover = (e) => {
    e.target.style.color = "#FFFFFF"; // Change text color on hover
    e.target.style.backgroundColor = "#424651"; // Change background color on hover
  };

  // Define the common hover exit function
  const handleHoverExit = (e) => {
    e.target.style.color = "#424651"; // Revert text color on hover exit
    e.target.style.backgroundColor = "transparent"; // Revert background color on hover exit
  };

  const convertBase64ToFile = (base64String, fileName, fileType) => {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: fileType });
    const file = new File([blob], fileName);

    return file;
  };

  const handleTimeChange = (index, timeIndex, value, timeInput) => {
    setSelectedDateTime((prevSelectedDateTime) => {
      const updatedSelectedDateTime = [...prevSelectedDateTime];

      if (
        updatedSelectedDateTime[index] &&
        updatedSelectedDateTime[index].time
      ) {
        updatedSelectedDateTime[index] = {
          ...updatedSelectedDateTime[index],
          time: [...updatedSelectedDateTime[index].time],
        };

        const [time, period] =
          updatedSelectedDateTime[index].time[timeIndex].split(" "); // Split the time value into time and period

        const [hourString, minuteString] = time.split(":"); // Split the time into hours and minutes
        const hours = parseInt(hourString, 10).toString().padStart(2, "0"); // Convert hours string to an integer
        const minutes = parseInt(minuteString, 10).toString().padStart(2, "0");

        // console.log("Hours:", hours);
        // console.log("Minutes:", minutes);
        // console.log("Period:", period);
        if (timeInput === "hour") {
          updatedSelectedDateTime[index].time[timeIndex] = `${value
            .toString()
            .padStart(2, "0")}:${minutes ? minutes : "00"} ${
            period ? period : "AM"
          }`;
        } else if (timeInput === "minutes") {
          updatedSelectedDateTime[index].time[timeIndex] = `${
            hours ? hours : "00"
          }:${value.toString().padStart(2, "0")} ${period ? period : "AM"}`;
        } else {
          updatedSelectedDateTime[index].time[timeIndex] = `${
            hours ? hours : "00"
          }:${minutes ? minutes : "00"} ${value}`;
        }
      }

      return updatedSelectedDateTime;
    });
  };
  // console.log(auth?.AuthToken)

  const handleSubmit = () => {
    const policyArray = cancellationPolicy?.map((item) => item.policy);
    if (
      !greetingMessage ||
      selectedDateTime.length === 0 ||
      selectedDateTime.some((item) => item.time.length === 0) ||
      duplicates?.length > 0 ||
      cancellationPolicy?.length === 0 ||
      policyArray.includes("") ||
      selectedDateTime?.find((item) => item?.time[0].slice(0, 2) === "00")
    ) {
      if (!greetingMessage) {
        setGreetingMessageError(true);
        toast.error("Please enter greeting message.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      } else {
        setGreetingMessageError(false);
      }

      if (duplicates?.length > 0) {
        toast.error("Please remove duplicate time", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }

      if (
        selectedDateTime.length === 0 ||
        selectedDateTime.every((item) => item.time.length === 0)
      ) {
        setSelectedDateTimeError(true);
        toast.error("Please select Date & Time.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      } else {
        setSelectedDateTimeError(false);
      }

      if (selectedDateTime.find((item) => item?.time[0].slice(0, 2) === "00")) {
        console.log("new err working");
        toast.error("Please select correct time for date", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }

      if (cancellationPolicy?.length === 0) {
        toast.error("Please enter a cancellation policy", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setCancellationPolicyError(true);
      } else if (policyArray.includes("")) {
        toast.error("Please enter a cancellation policy", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setCancellationPolicyError(true);
      } else {
        setCancellationPolicyError(false);
      }
    }

    //
    //
    //
    //
    //
    //
    //
    else {
      setSelectedDateTimeError(false);
      setLoader(true);
      let payload = new FormData();
      if (auth) {
        if (!dashboard?.single_boat_details) {
          console.log("register payload");
          payload.append(
            "ministry_transport_document",
            ministry_of_transport_doc
          );
          payload.append(
            "border_guard_document",
            general_directorate_of_border_guard_doc
          );
          payload.append(
            "boat_license_document",
            boat_documentations_and_licenses
          );
          payload.append(
            "boat_name",
            dashboard?.boat_register_step2_data?.boat_name
          );
          payload.append(
            "boat_type",
            dashboard?.boat_register_step2_data?.boat_type
          );
          payload.append(
            "boat_year",
            dashboard?.boat_register_step2_data?.boat_year
          );
          payload.append(
            "boat_length",
            dashboard?.boat_register_step2_data?.boat_length
          );
          payload.append(
            "length_type",
            dashboard?.boat_register_step2_data?.length_type?.toLowerCase()
          );
          payload.append(
            "boat_max_capacity",
            dashboard?.boat_register_step2_data?.boat_max_capacity
          );
          // payload.append(
          //   "marine_name",
          //   dashboard?.boat_register_step2_data?.marine_name
          // );
          payload.append(
            "marine_address",
            dashboard?.boat_register_step2_data?.marine_address
            // ? address_name[0] + " " + city_name[0]
            // : dashboard?.boat_reg_location_data?.PostCode[0]?.cityName +
            //     " " +
            //     dashboard?.boat_reg_location_data?.PostCode[0]?.regionName
          );
          payload.append(
            "latitude",
            dashboard?.boat_register_step2_data?.latitude
          );
          payload.append(
            "longtitude",
            dashboard?.boat_register_step2_data?.longitude
          );
          payload.append(
            "price_per_hour",
            dashboard?.boat_register_step2_data?.boat_price_per_hour
          );

          if (
            dashboard?.boat_register_step2_data?.profile_image_index === null
          ) {
            payload.append("front_image", selectedImageFiles[0]);
          }

          if (
            dashboard?.boat_register_step2_data?.backgroung_image_index === null
          ) {
            payload.append("background_image", selectedImageFiles[0]);
          }

          selectedImageFiles?.map((boatImgItem, boatImgIndex) => {
            console.log(boatImgItem, "img item");
            if (
              dashboard?.boat_register_step2_data?.profile_image_index ===
              boatImgIndex
            ) {
              payload.append("front_image", boatImgItem);
            }
            if (
              dashboard?.boat_register_step2_data?.backgroung_image_index ===
              boatImgIndex
            ) {
              payload.append("background_image", boatImgItem);
            } else {
              payload.append("image", boatImgItem);
            }
          });

          selectedDateTime?.map((dateItem, dateIndex) => {
            dateItem?.time?.map((timeItem, timeIndex) => {
              payload.append(
                `timeslot[]`,
                `${moment(dateItem?.date).format("DD.MM.YYYY")},${timeItem}`
              );
            });
          });

          dashboard?.boat_register_step2_data?.boat_services_selected?.map(
            (serviceItem, serviceIndex) => {
              payload.append(`boat_service[]`, serviceItem?.label);
            }
          );

          if (dashboard?.boat_register_step2_data?.show_custom_service) {
            dashboard?.boat_register_step2_data?.customize_boat_services?.map(
              (serviceItem, serviceIndex) => {
                payload.append(`boat_service[]`, serviceItem?.label);
              }
            );
          }

          payload.append("price_currency", "SAR");
          payload.append(
            "marine_pincode",
            dashboard?.boat_reg_location_data?.Addresses
              ? JSON.stringify(
                  dashboard?.boat_reg_location_data?.Addresses[0]?.PostCode
                )
              : JSON.stringify(
                  dashboard?.boat_reg_location_data?.PostCode[0]?.postCode
                )
          );
          payload.append("marine_state", null);
          payload.append(
            "marine_city",
            dashboard?.boat_register_step2_data?.marine_city
          );
          payload.append("is_active", "1");
          payload.append("available_hours", "10");
          payload.append("greeting_message", greetingMessage || "");
          payload.append("youtube_link", youTubeLink);
          cancellationPolicy?.map((cancelPolicyItem, cancelPolicyIndex) => {
            payload.append(
              `cancellationPolicy[]`,
              `${cancelPolicyItem?.policy}|${cancelPolicyItem.hour}|${cancelPolicyItem.percentage}`
            );
          });
          boat_register(auth?.AuthToken, payload)
            .then((res) => {
              console.log("boat_register res=>", res?.data);
              if (res?.data?.message === "boat successfully registered") {
                dispatch(boat_register_step1(null));
                dispatch(boat_register_step2(null));
                dispatch(boat_register_step3(null));
                dispatch(boatTypeList(null));
                dispatch(boatServiceList(null));
                dispatch(boat_register_location_data(null));
                dispatch(single_boat_details_store(null));
                toast.dismiss();
                toast.success("Boat successfully registered", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 20000,
                });

                navigate("/confirmation");
                setLoader(false);
              } else {
                console.log("api error");
                toast.error(res?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 20000,
                });
                setLoader(false);
              }
            })
            .catch((err) => {
              console.log("boat_register err", err);
              toast.dismiss();
              toast.error(err?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 20000,
              });
              setLoader(false);
            });
        } else {
          console.log("update payload");

          payload.append(
            "ministry_transport_document",
            ministry_of_transport_doc
          );

          payload.append(
            "border_guard_document",
            general_directorate_of_border_guard_doc
          );

          payload.append(
            "boat_license_document",
            boat_documentations_and_licenses
          );

          payload.append(
            "boat_name",
            dashboard?.boat_register_step2_data?.boat_name
          );

          payload.append(
            "boat_type",
            dashboard?.boat_register_step2_data?.boat_type
          );

          payload.append(
            "boat_year",
            dashboard?.boat_register_step2_data?.boat_year
          );

          payload.append(
            "boat_length",
            dashboard?.boat_register_step2_data?.boat_length
          );

          payload.append(
            "length_type",
            dashboard?.boat_register_step2_data?.length_type?.toLowerCase()
          );

          payload.append(
            "boat_max_capacity",
            dashboard?.boat_register_step2_data?.boat_max_capacity
          );

          payload.append(
            "price_per_hour",
            dashboard?.boat_register_step2_data?.boat_price_per_hour
          );

          // payload.append(
          //   "marine_name",
          //   dashboard?.boat_register_step2_data?.marine_name
          // );

          payload.append(
            "marine_address",
            dashboard?.boat_register_step2_data?.marine_address
          );

          payload.append(
            "marine_city",
            dashboard?.boat_register_step2_data?.marine_city
          );

          payload.append(
            "latitude",
            dashboard?.boat_register_step2_data?.latitude
          );

          payload.append(
            "longtitude",
            dashboard?.boat_register_step2_data?.longitude
            // 0.132123
          );

          if (
            dashboard?.boat_register_step2_data?.backgroung_image_index ===
            "API"
          ) {
            payload.append(
              "background_image",
              dashboard?.single_boat_details?.background_image
            );
          }

          if (
            dashboard?.boat_register_step2_data?.profile_image_index === "API"
          ) {
            payload.append(
              "front_image",
              dashboard?.single_boat_details?.front_image
            );
          }

          selectedImageFiles?.map((boatImgItem, boatImgIndex) => {
            console.log(boatImgItem, "image files");
            if (
              dashboard?.boat_register_step2_data?.profile_image_index !==
                "API" ||
              dashboard?.boat_register_step2_data?.backgroung_image_index !==
                "API"
              // selectedImageFiles?.length
            ) {
              if (
                dashboard?.boat_register_step2_data?.backgroung_image_index ===
                boatImgIndex
              ) {
                payload.append("background_image", boatImgItem);
              }
              if (
                dashboard?.boat_register_step2_data?.profile_image_index ===
                boatImgIndex
              ) {
                payload.append("front_image", boatImgItem);
              }
              // else {
              //   console.log('image upload')
              //   payload.append("image", boatImgItem);
              // }
            }
            // if (
            //   dashboard?.boat_register_step2_data?.background_image_index !==
            //   "API"
            // ) {
            //   if (
            //     dashboard?.boat_register_step2_data?.background_image_index ===
            //     boatImgIndex
            //   ) {
            //     payload.append("background_image", boatImgItem);
            //   }
            // }
          });

          if (fileImg?.length) {
            fileImg?.map((item) => {
              if (
                dashboard?.boat_register_step2_data?.background_image_index !==
                  item?.index &&
                dashboard?.boat_register_step2_data?.profile_image_index !==
                  item?.index
              ) {
                console.log(item?.file, "file img");
                payload.append("image", item?.file);
              }
            });
          }

          if (otherImg?.length) {
            otherImg?.map((item) => {
              console.log("url img", item);
              payload.append("other_images", item);
            });
          }

          selectedDateTime?.map((dateItem, dateIndex) => {
            dateItem?.time?.map((timeItem, timeIndex) => {
              payload.append(
                `timeslot[]`,
                `${moment(dateItem?.date).format("DD.MM.YYYY")},${timeItem}`
              );
            });
          });

          if (dashboard?.boat_register_step2_data?.show_custom_service) {
            dashboard?.boat_register_step2_data?.customize_boat_services?.map(
              (serviceItem, serviceIndex) => {
                payload.append(`boat_service[]`, serviceItem?.label);
              }
            );
          }

          dashboard?.boat_register_step2_data?.boat_services_selected?.map(
            (serviceItem, serviceIndex) => {
              payload.append(`boat_service[]`, serviceItem?.label);
            }
          );

          payload.append(
            "price_currency",
            dashboard?.single_boat_details?.price_currency
          );

          payload.append("boat_id", dashboard?.single_boat_details?.boat_id);
          payload.append("is_active", "1");
          payload.append("available_hours", "10");
          payload.append("greeting_message", greetingMessage || "");
          payload.append("youtube_link", youTubeLink);
          cancellationPolicy?.map((cancelPolicyItem, cancelPolicyIndex) => {
            payload.append(
              `cancellationPolicy[]`,
              `${cancelPolicyItem?.policy}|${cancelPolicyItem.hour}|${cancelPolicyItem.percentage}`
            );
          });
          update_boat(auth?.AuthToken, payload)
            .then((res) => {
              console.log("boat_update res=>", res?.data);
              if (res?.data?.success) {
                dispatch(boat_register_step1(null));
                dispatch(boat_register_step2(null));
                dispatch(boat_register_step3(null));
                dispatch(boatTypeList(null));
                dispatch(boatServiceList(null));
                dispatch(boat_register_location_data(null));
                dispatch(single_boat_details_store(null));
                dispatch(
                  confirmTickMsg({
                    title: "Boat Updated Successfully!",
                    buttonName: "back to home",
                  })
                );
                toast.dismiss();
                toast.success("boat successfully updated", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 20000,
                });

                navigate("/confirmation");
                setLoader(false);
              } else {
                toast.error(res?.data?.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 20000,
                });
                setLoader(false);
              }
            })
            .catch((err) => {
              console.log("boat_update err", err);
              toast.dismiss();
              toast.error(err?.message, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 20000,
              });
              setLoader(false);
            });
        }

        // print con_sole
        for (const [key, value] of payload.entries()) {
          console.log(key, ":", `'${value}'`);
        }

        //API call
        // setLoader(false);
        // if (!dashboard?.single_boat_details) {
        //   console.log('register api')
        //   boat_register(auth?.AuthToken, payload)
        //     .then((res) => {
        //       // console.log("boat_register res=>", res?.data);
        //       if (res?.data?.message === "boat successfully registered") {
        //         dispatch(boat_register_step1(null));
        //         dispatch(boat_register_step2(null));
        //         dispatch(boatTypeList(null));
        //         dispatch(boatServiceList(null));
        //         dispatch(boat_register_location_data(null));
        //         dispatch(single_boat_details_store(null));
        //         toast.dismiss();
        //         toast.success("Boat successfully registered", {
        //           position: toast.POSITION.TOP_RIGHT,
        //           autoClose: 20000,
        //         });

        //         navigate("/confirmation");
        //         setLoader(false);
        //       } else {
        //         console.log("api error")
        //         toast.error(res?.data?.message, {
        //           position: toast.POSITION.TOP_RIGHT,
        //           autoClose: 20000,
        //         });
        //         setLoader(false);
        //       }
        //     })
        //     .catch((err) => {
        //       console.log("boat_register err", err);
        //       toast.dismiss();
        //       toast.error("Something went wrong. Please try again later.", {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 20000,
        //       });
        //       setLoader(false);
        //     });
        // } else if (dashboard?.single_boat_details) {
        //   console.log('register api')
        //   update_boat(auth?.AuthToken, payload)
        //     .then((res) => {
        //       // console.log("boat_register res=>", res?.data);
        //       if (res?.data?.message === "boat successfully updated") {
        //         dispatch(boat_register_step1(null));
        //         dispatch(boat_register_step2(null));
        //         dispatch(boatTypeList(null));
        //         dispatch(boatServiceList(null));
        //         dispatch(boat_register_location_data(null));
        //         dispatch(single_boat_details_store(null));
        //         toast.dismiss();
        //         toast.success("boat successfully updated", {
        //           position: toast.POSITION.TOP_RIGHT,
        //           autoClose: 20000,
        //         });

        //         navigate("/confirmation");
        //         setLoader(false);
        //       } else {
        //         toast.error(res?.data?.message, {
        //           position: toast.POSITION.TOP_RIGHT,
        //           autoClose: 20000,
        //         });
        //         setLoader(false);
        //       }
        //     })
        //     .catch((err) => {
        //       console.log("boat_register err", err);
        //       toast.dismiss();
        //       toast.error("Something went wrong. Please try again later.", {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 20000,
        //       });
        //       setLoader(false);
        //     });
        // } else {
        //   setLoader(false);
        //   toast.dismiss();
        //   toast.success("API update call", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 20000,
        //   });
        //   // payload.append("boat_id", dashboard?.single_boat_details?.boat_id);
        //   // update_boat(auth?.AuthToken, payload)
        //   //   .then((res) => {
        //   //     // console.log("update boat res=>", res?.data);
        //   //     if (res?.data?.message === "boat successfully updated") {
        //   //       dispatch(boat_register_step1(null));
        //   //       dispatch(boat_register_step2(null));
        //   //       dispatch(boatTypeList(null));
        //   //       dispatch(boatServiceList(null));
        //   //       dispatch(single_boat_details_store(null));

        //   //       toast.dismiss();
        //   //       toast.success("Boat successfully updated", {
        //   //         position: toast.POSITION.TOP_RIGHT,
        //   //         autoClose: 2000,
        //   //       });

        //   //       navigate("/confirmation");
        //   //       setLoader(false);
        //   //     } else {
        //   //       toast.dismiss();
        //   //       toast.error(res?.data?.message, {
        //   //         position: toast.POSITION.TOP_RIGHT,
        //   //         autoClose: 20000,
        //   //       });
        //   //       setLoader(false);
        //   //     }
        //   //   })
        //   //   .catch((err) => {
        //   //     console.log("update boat err", err);
        //   //     toast.error("Something went wrong. Please try again later.", {
        //   //       position: toast.POSITION.TOP_RIGHT,
        //   //       autoClose: 2000,
        //   //     });
        //   //     setLoader(false);
        //   //   });
        // }
      }

      setGreetingMessageError(false);
    }
  };
  // console.log(dashboard?.single_boat_details)

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      "https://www.youtube.com/watch?v=M7cr9U-jtzY&t=4338s"
    );
    setIsLinkCopied(true);
    // setTimeout(() => {
    //   setIsLinkCopied(false);
    // }, 2000);
    const youtubeLink = "https://www.youtube.com/watch?v=M7cr9U-jtzY&t=4338s";

    // Open the YouTube link in a new tab without focusing on it
    const newTab = window.open(youtubeLink, "_blank");
    newTab.opener = null; // Prevent the newly opened tab from focusing on the parent tab
  };

  const handleHeaderCallBack = (name) => {
    if (name === "Home") {
      if (auth?.tokenDecodeData?.user_type === "BOAT_OWNER") {
        navigate("/boatOwnerDashBoard");
      } else {
        navigate("/rental");
      }
    } else if (name === "Log In") {
      navigate("/logIn");
    } else if (name === "Sign Up") {
      navigate("/signUP");
    } else if (name === "My Listings") {
      navigate("/myListings");
    } else if (name === "For Boat Rentals" || name === "For Boat Owners") {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    } else if (name === "/searchBoat") {
      navigate("/searchBoat");
    }
  };

  function convertTime(time24hr) {
    console.log(time24hr, "time24hr");
    // console.log(time24hr,'time')const [hours, minutes] = time24hr.split(":");
    const [hours, minutes, seconds] = time24hr.split(":");
    console.log(hours, minutes, "24hr");
    let period = "AM";
    let adjustedHours = parseInt(hours, 10);

    if (adjustedHours >= 12) {
      period = "PM";
      if (adjustedHours > 12) {
        adjustedHours -= 12;
      }
    }

    const time12hr = `${
      adjustedHours <= 9 ? `0${adjustedHours}` : adjustedHours
    }:${minutes} ${period}`;
    console.log(time12hr, "12hr");
    return time12hr;
  }

  const [duplicate_data_present, setDuplicate_data_present] = useState(false);

  useEffect(() => {
    setDuplicate_data_present(false);
    duplicates.forEach((item) => {
      const itemDate = item.date; // Assuming 'date' is a Date object in your data

      if (
        moment(itemDate).format("DD/MM/YYYY") ===
        moment(selectedDateTime).format("DD/MM/YYYY")
      ) {
        setDuplicate_data_present(true); // Set the flag to true
      }
    });
  }, []);

  const updateHour = async (value, timeIndex_arug) => {
    setSelectedDateTime((prevSelectedDateTime) => {
      return prevSelectedDateTime.map((item, index) => {
        if (
          moment(item?.date).format("DD/MM/YYYY") ===
          moment(currentlySelectedDate).format("DD/MM/YYYY")
        ) {
          return {
            ...item,
            time: item?.time.map((timeItem, timeIndex) => {
              if (timeIndex_arug === timeIndex) {
                const updatedTimeItem = `${value}${timeItem.slice(2)}`;
                return updatedTimeItem;
              } else {
                return timeItem;
              }
            }),
          };
        } else {
          return item;
        }
      });
    });
  };

  const updateMinutes = (value, timeIndex_arug) => {
    setSelectedDateTime((prevSelectedDateTime) => {
      return prevSelectedDateTime.map((item, index) => {
        if (
          moment(item?.date).format("DD/MM/YYYY") ===
          moment(currentlySelectedDate).format("DD/MM/YYYY")
        ) {
          return {
            ...item,
            time: item?.time.map((timeItem, timeIndex) => {
              if (timeIndex_arug === timeIndex) {
                const updatedTimeItem = `${timeItem.slice(
                  0,
                  3
                )}${value}${timeItem.slice(5)}`;

                return updatedTimeItem;
              } else {
                return timeItem;
              }
            }),
          };
        } else {
          return item;
        }
      });
    });
  };

  const updateAMorPM = (value, timeIndex_arug) => {
    setSelectedDateTime((prevSelectedDateTime) => {
      return prevSelectedDateTime.map((item, index) => {
        if (
          moment(item?.date).format("DD/MM/YYYY") ===
          moment(currentlySelectedDate).format("DD/MM/YYYY")
        ) {
          return {
            ...item,
            time: item?.time.map((timeItem, timeIndex) => {
              if (timeIndex_arug === timeIndex) {
                const updatedTimeItem = `${timeItem.slice(0, 6)}${value}`;
                return updatedTimeItem;
              } else {
                return timeItem;
              }
            }),
          };
        } else {
          return item;
        }
      });
    });
  };

  const handleHistory = (value, type) => {
    console.log("history");
    dispatch(
      boat_register_step3({
        greeting_message:
          type === "greetingMessage" ? value : null || greetingMessage,
        youtube_link: type === "youtubeLink" ? value : null || youTubeLink,
        boats_timeslot: selectedDateTime,
      })
    );
  };

  return (
    <>
      <div className="show-header-outSide-banner">
        <PageHeader
          showLoginSignUp={auth?.AuthToken ? false : true}
          handle_navigation={handleHeaderCallBack}
          presentPage={""}
          link1={"Boat Offers"}
          // link2={"My Listings"}
          link2={"List a Boat Offer"}
          link3={
            auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
              ? "My Listings"
              : null
          }
        />
      </div>
      <div
        className="show-header-inside-banner"
        style={{ backgroundColor: "#f6f6f6" }}
      >
        <PageHeader
          showLoginSignUp={auth?.AuthToken ? false : true}
          handle_navigation={handleHeaderCallBack}
          presentPage={""}
          link1={"Boat Offers"}
          // link2={"My Listings"}
          link2={"List a Boat Offer"}
          link3={
            auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
              ? "My Listings"
              : null
          }
        />
      </div>
      <div className={class_name.page_body_container}>
        {loader ? <Loader loading={loader} content={true} /> : null}

        <Typography className={class_name.Show_off_your_boat_in_few_clicks}>
          Show off your boat in few clicks!
        </Typography>

        <div className={class_name.form_container_box}>
          <Typography className={class_name.step_3_txt}>Step 3</Typography>
          <Divider
            variant="fullWidth"
            style={{
              width: "100%",
              backgroundColor: "rgba(66, 70, 81, 0.9)",
              height: "1px",
              border: "solid .5px rgba(66, 70, 81, 0.2)",
            }}
          />

          {/* Tell your customers */}
          <Typography className={class_name.write_greeting_msg}>
            Write greeting message to your customers
          </Typography>

          <CustomTextField
            multiline
            // maxRows={5}
            rows={5}
            variant="outlined"
            margin="normal"
            fullWidth
            id="customers"
            name="customers"
            placeholder="Tell your customers what makes your boat trips interesting!"
            value={greetingMessage}
            onChange={(event) => {
              toast.dismiss();
              if (event.target.value?.length <= 200) {
                if (start_space_Validation.test(event.target.value)) {
                  setGreetingMessage(event.target.value);
                  handleHistory(event.target.value, "greetingMessage");
                } else {
                  toast.error("spaces at the beginning are not permitted.", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                  });
                }
              } else {
                toast.error("Only 200 characters or less are allowed.", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 2000,
                });
              }
            }}
            InputProps={{
              disableUnderline: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />

          {greetingMessage ? (
            <Typography className={class_name.show_txt_length}>
              {greetingMessage?.length}
              {"/ 200"}
            </Typography>
          ) : null}
          {!greetingMessage && greetingMessageError ? (
            <Typography className={class_name.err_msg_style}>
              Please enter greeting message.
            </Typography>
          ) : null}

          {/* Calendar           *** &&& ***        Date Time Selection */}
          <Typography
            className={class_name.write_greeting_msg}
            style={{ marginTop: "5%", marginBottom: 0 }}
          >
            Boat Availability
          </Typography>

          <Grid item xs={12} sm={6}>
            <div className={class_name.calendar_and_timeSlot}>
              <div style={{}} className={class_name.show_calendar_box}>
                <CalendarComponent
                  setSelectedDateTime={setSelectedDateTime}
                  selectedDateTime={selectedDateTime}
                  onDateSelect={handleDateSelect}
                  errorShow={errorDublicateTime[0]?.date}
                  handleShowMonth={currentlySelectedDate}
                  duplicates={duplicates}
                />

                {selectedDateTime?.length <= 0 && selectedDateTimeError ? (
                  <div>
                    <Typography className={class_name.err_msg_style}>
                      Please selecte Date & Time.
                    </Typography>
                  </div>
                ) : null}
              </div>

              <div
                className={class_name.show_calendar_selected_DateAndTime_box}
                style={{}}
              >
                <Typography
                  className={class_name.write_greeting_msg}
                  style={{
                    marginTop: "0",
                    marginBottom: "1%",
                  }}
                >
                  Day
                </Typography>
                <CustomTextField
                  // disabled={true}
                  type={"text"}
                  margin="normal"
                  fullWidth
                  id="selected_date"
                  name="selected_date"
                  placeholder="selected date"
                  // label="Day"
                  value={
                    currentlySelectedDate
                      ? moment(currentlySelectedDate).format("DD.MM.YYYY")
                      : "None"
                  }
                  onChange={(event) => {}}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      color: "red",
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      borderRadius: "15px",
                      paddingLeft: "15px",
                      width: "100%",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                      color: "red",
                    },
                    // startAdornment: (
                    //   <InputAdornment position="start">
                    //     <IconButton style={{ cursor: "auto" }}>
                    //       <img
                    //         src={IMAGES.DATE}
                    //         alt="lock"
                    //         style={{
                    //           width: "15px",
                    //           height: "15px",
                    //         }}
                    //       />
                    //     </IconButton>
                    //   </InputAdornment>
                    // ),
                    endAdornment: currentlySelectedDate ? (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            handleRemoveDate(currentlySelectedDate)
                          }
                        >
                          <RemoveCircle />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                  inputProps={{
                    style: {
                      fontSize: "clamp(14px, 2.5vw, 20px)",
                    },
                    className: class_name.day_txt_style,
                  }}
                  disabled={true}
                />
                <Typography
                  className={class_name.write_greeting_msg}
                  style={{
                    marginTop: "1.5%",
                    marginBottom: "0%",
                  }}
                >
                  Availability time
                </Typography>
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <div className="flex-style">
                    {selectedDateTime?.map((item, index) => {
                      const isCurrentlySelectedDate =
                        moment(item.date).format("DD/MM/YYY") ===
                        moment(currentlySelectedDate).format("DD/MM/YYY");

                      if (isCurrentlySelectedDate) {
                        return item?.time?.map((timeItem, timeIndex) => {
                          const timeItemId = timeIndex.toString();
                          const [time, period] = timeItem.split(" ");
                          const [hourString, minuteString] = time.split(":");

                          const handleItemClick = () => {
                            const isItemSelected =
                              modalOpen && modalOpenIndex === timeItemId;
                            setModalOpen(!isItemSelected);
                            setHoveredTimeItem(null);
                            setModalOpenIndex(timeItemId);
                          };

                          return (
                            <div>
                              <animated.div
                                style={{
                                  margin: "1% 5% -10% 0",
                                }}
                              >
                                <div
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      // border: "1px solid rgba(66, 70, 81, 0.2)",
                                      // borderRadius: "10px",
                                    }}
                                  >
                                    <div
                                      style={{}}
                                      className={class_name.show_time_boxs}
                                      onClick={() => {
                                        // handleItemClick();
                                      }}
                                    >
                                      {/* <IconButton
                                        style={{
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        <AccessTime
                                          style={{
                                            fontSize: "clamp(10px, 3vw, 25px)",
                                          }}
                                        />
                                      </IconButton>
                                      <Typography
                                        style={{
                                          color: "#424651",
                                          width: "100px",
                                          fontSize: "clamp(10px, 1.5vw, 16px)",
                                          textAlign: "center",
                                        }}

                                      >
                                        {timeItem ? timeItem : "00:00"}
                                      </Typography> */}
                                      <div className="d-flex gap-1">
                                        {/* Hours */}
                                        <TextField
                                          select
                                          size="small"
                                          variant="outlined"
                                          value={hourString}
                                          className={class_name.time_box}
                                          // style={{fontSize: "clamp(1px, 2vw, 16px)","&MuiSelect-select":{fontSize: '10px'}}}
                                          onChange={(e) => {
                                            // setHour({
                                            //   hr: e.target.value,
                                            //   index: timeIndex,
                                            // });

                                            updateHour(
                                              e.target.value,
                                              timeIndex
                                            );
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          // MenuProps={{
                                          //   style: { maxHeight: 300 },
                                          //   anchorOrigin: {
                                          //     vertical: "bottom",
                                          //     horizontal: "left",
                                          //   },
                                          //   getContentAnchorEl: null,
                                          // }}
                                          SelectProps={{
                                            MenuProps: {
                                              style: { maxHeight: 300 },
                                              anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center",
                                              },
                                              transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center",
                                              },
                                              anchorPosition: "bottom",
                                              getContentAnchorEl: null,
                                            },
                                          }}
                                        >
                                          {Array.from(
                                            { length: 13 },
                                            (_, index) => (
                                              <MenuItem
                                                key={index}
                                                value={index
                                                  .toString()
                                                  .padStart(2, "0")}
                                              >
                                                {index
                                                  .toString()
                                                  .padStart(2, "0")}
                                              </MenuItem>
                                            )
                                          )}
                                        </TextField>
                                        {
                                          <span className="fw-bold fs-4 text-secondary">
                                            :
                                          </span>
                                        }
                                        {/* Minutes */}
                                        <TextField
                                          size="small"
                                          select
                                          // label="MM"
                                          placeholder="MM"
                                          variant="outlined"
                                          value={minuteString}
                                          className={class_name.time_box}
                                          onChange={(e) => {
                                            // setMinutes({
                                            //   min: e.target.value,
                                            //   index: timeIndex,
                                            // })
                                            updateMinutes(
                                              e.target.value,
                                              timeIndex
                                            );
                                          }}
                                          // MenuProps={{
                                          //   style: { maxHeight: 300 },
                                          //   anchorOrigin: {
                                          //     vertical: "bottom",
                                          //     horizontal: "left",
                                          //   },
                                          //   getContentAnchorEl: null,
                                          // }}
                                          SelectProps={{
                                            MenuProps: {
                                              style: { maxHeight: 300 },
                                              anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center",
                                              },
                                              transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center",
                                              },
                                              anchorPosition: "bottom",
                                              getContentAnchorEl: null,
                                            },
                                          }}
                                        >
                                          {Array.from(
                                            { length: 61 },
                                            (_, index) => (
                                              <MenuItem
                                                key={index}
                                                value={index
                                                  .toString()
                                                  .padStart(2, "0")}
                                              >
                                                {index
                                                  .toString()
                                                  .padStart(2, "0")}
                                              </MenuItem>
                                            )
                                          )}
                                        </TextField>
                                        {/* AM / PM */}
                                        <TextField
                                          size="small"
                                          select
                                          // label="00"
                                          variant="outlined"
                                          value={period}
                                          className={class_name.time_box}
                                          onChange={(e) => {
                                            // setFormat({
                                            //   format: e.target.value,
                                            //   index: timeIndex,
                                            // })
                                            updateAMorPM(
                                              e.target.value,
                                              timeIndex
                                            );
                                          }}
                                          // MenuProps={{
                                          //   style: { maxHeight: 300 },
                                          //   anchorOrigin: {
                                          //     vertical: "bottom",
                                          //     horizontal: "left",
                                          //   },
                                          //   getContentAnchorEl: null,
                                          // }}
                                          SelectProps={{
                                            MenuProps: {
                                              style: { maxHeight: 300 },
                                              anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center",
                                              },
                                              transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center",
                                              },
                                              anchorPosition: "bottom",
                                              getContentAnchorEl: null,
                                            },
                                          }}
                                        >
                                          <MenuItem value={"AM"}>AM</MenuItem>
                                          <MenuItem value={"PM"}>PM</MenuItem>
                                        </TextField>
                                      </div>

                                      {item?.time?.length > 1 ? (
                                        <IconButton
                                          style={{
                                            margin: "0px",
                                            padding: "0px",
                                          }}
                                          onClick={() => {
                                            handleRemoveTimeField(
                                              item?.date,
                                              timeIndex
                                            );
                                            setModalOpen(false);
                                            setModalOpenIndex("");
                                          }}
                                        >
                                          <RemoveCircle
                                            style={{
                                              fontSize:
                                                "clamp(20px, 3vw, 25px)",
                                            }}
                                          />
                                        </IconButton>
                                      ) : null}
                                    </div>
                                  </div>

                                  {modalOpen &&
                                  modalOpenIndex === timeItemId ? (
                                    <>
                                      <Grid
                                        ref={modalRef}
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          width: "100%",
                                          border: "1px solid black",
                                          borderRadius: "10px",
                                          boxShadow:
                                            "0px 2px 4px rgba(0, 0, 0, 0.25)",
                                          padding: "5px",
                                          backgroundColor: "white",
                                          zIndex: "9999",
                                          position: "absolute",
                                        }}
                                      >
                                        <Grid
                                          item
                                          xs={4}
                                          style={{
                                            borderRight: "1px solid black",
                                            width: "33%",
                                            height: "200px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          {hours.map((hourItem, hourIndex) => (
                                            <div
                                              key={hourIndex + "%"}
                                              value={hourItem}
                                              onClick={() => {
                                                handleHourChange(
                                                  index,
                                                  timeIndex,
                                                  hourItem,
                                                  "hour"
                                                );
                                              }}
                                              style={{
                                                ...timeTxtStyle,
                                                backgroundColor:
                                                  hourString ===
                                                  hourItem
                                                    .toString()
                                                    .padStart(2, "0")
                                                    ? "#3973A5"
                                                    : "white",
                                                color:
                                                  hourString ===
                                                  hourItem
                                                    .toString()
                                                    .padStart(2, "0")
                                                    ? "white"
                                                    : "black",
                                              }}
                                            >
                                              {hourItem
                                                .toString()
                                                .padStart(2, "0")}
                                            </div>
                                          ))}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={4}
                                          style={{
                                            borderRight: "1px solid black",
                                            width: "33%",
                                            height: "200px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          {minute.map(
                                            (minutesItem, minutesIndex) => {
                                              return (
                                                <div
                                                  key={minutesIndex + "%"}
                                                  value={minutesItem}
                                                  onClick={() => {
                                                    handleHourChange(
                                                      index,
                                                      timeIndex,
                                                      minutesItem,
                                                      "minutes"
                                                    );
                                                  }}
                                                  style={{
                                                    ...timeTxtStyle,
                                                    backgroundColor:
                                                      minuteString ===
                                                      minutesItem
                                                        .toString()
                                                        .padStart(2, "0")
                                                        ? "#3973A5"
                                                        : "white",
                                                    color:
                                                      minuteString ===
                                                      minutesItem
                                                        .toString()
                                                        .padStart(2, "0")
                                                        ? "white"
                                                        : "black",
                                                  }}
                                                >
                                                  {minutesItem
                                                    .toString()
                                                    .padStart(2, "0")}
                                                </div>
                                              );
                                            }
                                          )}
                                        </Grid>
                                        <Grid
                                          item
                                          xs={4}
                                          style={{
                                            // borderRight: "1px solid black",
                                            width: "30%",
                                            height: "200px",
                                            overflowY: "auto",
                                          }}
                                        >
                                          <div
                                            onClick={() => {
                                              handleHourChange(
                                                index,
                                                timeIndex,
                                                "AM",
                                                " "
                                              );
                                            }}
                                            style={{
                                              ...timeTxtStyle,
                                              backgroundColor:
                                                period ===
                                                "AM".toString().padStart(2, "0")
                                                  ? "#3973A5"
                                                  : "white",
                                              color:
                                                period ===
                                                "AM".toString().padStart(2, "0")
                                                  ? "white"
                                                  : "black",
                                            }}
                                            // onMouseEnter={
                                            //   period ===
                                            //   "AM".toString().padStart(2, "0")
                                            //     ? undefined
                                            //     : handleHover
                                            // }
                                            // onMouseLeave={
                                            //   period ===
                                            //   "AM".toString().padStart(2, "0")
                                            //     ? undefined
                                            //     : handleHoverExit
                                            // }
                                          >
                                            {"AM"}
                                          </div>
                                          <div
                                            onClick={() => {
                                              handleHourChange(
                                                index,
                                                timeIndex,
                                                "PM",
                                                " "
                                              );
                                            }}
                                            style={{
                                              ...timeTxtStyle,
                                              backgroundColor:
                                                period ===
                                                "PM".toString().padStart(2, "0")
                                                  ? "#3973A5"
                                                  : "white",
                                              color:
                                                period ===
                                                "PM".toString().padStart(2, "0")
                                                  ? "white"
                                                  : "black",
                                            }}
                                            // onMouseEnter={
                                            //   period ===
                                            //   "PM".toString().padStart(2, "0")
                                            //     ? undefined
                                            //     : handleHover
                                            // }
                                            // onMouseLeave={
                                            //   period ===
                                            //   "PM".toString().padStart(2, "0")
                                            //     ? undefined
                                            //     : handleHoverExit
                                            // }
                                          >
                                            {"PM"}
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </>
                                  ) : null}
                                </div>
                              </animated.div>
                            </div>
                          );
                        });
                      }

                      return null;
                    })}
                  </div>
                </div>

                {/* add button */}
                {currentlySelectedDate ? (
                  <div
                    style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                      alignContent: "flex-end",
                      alignItems: "flex-end",
                      alignSelf: "flex-end",
                    }}
                  >
                    <IconButton
                      style={{
                        border: "solid 1px rgba(66, 70, 81, 0.2)",
                        padding: "5px",
                        borderRadius: "50%",
                        // backgroundColor: 'red',
                        display: "flex",
                      }}
                      onClick={() => {
                        toast.dismiss();
                        // selectedDateTime[datePositionIndex]?.time[
                        //   selectedDateTime[datePositionIndex]?.time?.length - 1
                        // ].slice(0, 2) !== "00"
                        let zeroIndex = selectedDateTime[
                          datePositionIndex
                        ]?.time?.findIndex(
                          (item) => item?.slice(0, 2) === "00"
                        );
                        zeroIndex === -1 &&
                        selectedDateTime[datePositionIndex].time?.length < 24
                          ? addExtraTime(currentlySelectedDate)
                          : toast.error(
                              selectedDateTime[datePositionIndex].time?.length <
                                24
                                ? "Please set correct time"
                                : "You can set only Maximum 24 slots",
                              {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                              }
                            );
                      }}
                    >
                      <Add />
                    </IconButton>
                  </div>
                ) : null}

                {/* copy time to all day */}
                {selectedDateTime[datePositionIndex]?.time?.length > 0 ? (
                  <div
                    style={{
                      width: "auto",
                      display: "flex",
                      flexDirection: "column",
                      // alignItems: "flex-start",
                      // alignContent: "flex-start",
                      // alignSelf: "flex-start",
                    }}
                    // className="row col-lg-12"
                  >
                    <div>
                      <Typography
                        style={{ cursor: "pointer", marginBottom: "3px" }}
                        onClick={() => {
                          // toast.dismiss();
                          if(
                            timeIntervelError ||
                            selectedDateTime[datePositionIndex]?.time?.find((item)=> item?.slice(0,2) === '00')
                          ){
                            toast.error("Please clear the errors", {
                              position: toast.POSITION.TOP_RIGHT,
                              autoClose: 2000,
                            });
                          } else if (selectedDateTime?.length > 1) {
                            copyTimeData(datePositionIndex);

                            toast.success(
                              "Successfully copied the time to all dates",
                              {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 2000,
                              }
                            );
                          } else {
                            toast.error("Please select more dates", {
                              position: toast.POSITION.TOP_RIGHT,
                              autoClose: 2000,
                            });
                          }
                        }}
                        className={class_name.copyTimeDate_txt}
                      >
                        Copy Time to all date
                      </Typography>
                      {/* err msg */}
                      {selectedDateTime[datePositionIndex]?.time?.find((item)=> item?.slice(0,2) === '00')
                      // duplicates?.length > 0 
                      ? (
                        <Typography className={class_name.err_msg_style}>
                          Please remove duplicate entries or select correct time
                        </Typography>
                      ) : null}
                      {timeIntervelError ? (
                        <Typography className={class_name.err_msg_style}>
                          {timeIntervelError}
                        </Typography>
                      ) : null}
                      <div className="w-100">
                        <div className="row">
                          {copyTimeDateArr.map((date, i) => (
                            <div
                              className="col-6"
                              style={{ justifyItems: "center" }}
                            >
                              <div
                                className=" 
                              mb-2 
                              d-flex 
                              justify-content-around
                              "
                              >
                                {/* >>>>>>> issues/notfixing */}
                                <div
                                  className="
                                d-flex 
                                align-items-center  
                                h-100
                                rounded-1 
                                border 
                                border-1 
                                border-secondary 
                                px-2 py-1
                                gap-1
                              "
                                >
                                  <span
                                    className=""
                                    style={{
                                      fontSize: "clamp(14px, 2vw, 16px)",
                                    }}
                                  >
                                    {moment(date?.date).format("DD.MM.YYYY")}
                                  </span>
                                  <span className="pb-1 pb-md-0">
                                    <IconButton
                                      style={{
                                        margin: "0px",
                                        padding: "0px",
                                      }}
                                      onClick={() => {
                                        handleRemoveDate(date?.date);
                                        // handleRemoveDateField(i);
                                      }}
                                    >
                                      <RemoveCircle
                                        style={{
                                          fontSize: "clamp(20px, 3vw, 25px)",
                                        }}
                                      />
                                    </IconButton>
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Grid>

          {/* Share a YouTube link  */}
          <Grid item xs={12} sm={6} style={{ marginTop: "4%" }}>
            <Typography className={class_name.write_greeting_msg}>
              Share a YouTube link for a short boat demo
            </Typography>
            <div className={class_name.root}>
              <CustomTextField
                variant="outlined"
                margin="normal"
                multiline={true}
                fullWidth
                id="YouTube"
                name="YouTube"
                placeholder="Share a YouTube link for a short boat demo"
                value={youTubeLink}
                onChange={(event) => {
                  if (start_space_Validation.test(event.target.value)) {
                    if (youtubePattern.test(event.target.value)) {
                      setYouTubeLink(event.target.value);
                      handleHistory(event.target.value, "youtubeLink");
                      setYouTubeLinkError(false);
                    } else {
                      setYouTubeLink("");
                      setYouTubeLinkError(true);
                    }
                  }
                }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    padding: "1%",
                  },

                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <img
                          alt="LINK"
                          src={IMAGES.LINK}
                          className={class_name.copy_pin_img}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <>
                      {youTubeLink ? (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setYouTubeLink("")}
                            style={{ padding: "0", margin: "0" }}
                          >
                            <RemoveCircle
                              className={class_name.removeCircleIcon}
                            />
                          </IconButton>
                        </InputAdornment>
                      ) : null}
                      <InputAdornment position="end">
                        {youTubeLink && (
                          <img
                            alt="Tick"
                            src={IMAGES.TICK}
                            style={{
                              width: 20,
                              height: 20,
                              alignSelf: "center",
                            }}
                          />
                        )}
                      </InputAdornment>
                    </>
                  ),
                }}
                inputProps={{
                  style: {},
                }}
                disabled={youTubeLink ? true : false}
              />
            </div>
            {youTubeLinkError ? (
              <Typography className={class_name.err_msg_style}>
                I'm sorry, but it seems you have pasted an incorrect YouTube
                link. Please provide a valid YouTube link
              </Typography>
            ) : null}
          </Grid>

          {/* Cancellation Policy */}
          <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: cancellationPolicyError
                  ? "space-between"
                  : "flex-start",
                marginTop: "8%",
                marginBottom: "1%",
              }}
            >
              <Typography
                className={class_name.write_greeting_msg}
                style={{ marginTop: 0, marginBottom: 0 }}
              >
                Cancellation Policy
              </Typography>
            </div>
            {cancellationPolicy?.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    border: "1px solid lightgray",
                    padding: "15px 20px",
                    marginBottom: "20px",
                    borderRadius: "5px",
                  }}
                  onMouseEnter={() => setHoverIndex(index)}
                  onMouseLeave={() => setHoverIndex(null)}
                >
                  <CustomTextField
                    margin="normal"
                    fullWidth
                    id="CancellationPolicy"
                    name="CancellationPolicy"
                    placeholder="Cancellation Policy"
                    multiline={true}
                    value={item?.policy}
                    onChange={(event) => {
                      const updatedPolicy = event.target.value;
                      setCancellationPolicy((prevPolicy) => {
                        const updatedPolicies = [...prevPolicy];
                        updatedPolicies[index] = {
                          ...updatedPolicies[index],
                          policy: updatedPolicy,
                        };
                        return updatedPolicies;
                      });
                    }}
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        backgroundColor: "white",
                        borderRadius: "5px",
                      },
                      startAdornment: (
                        <>
                          <Typography>{index + 1}</Typography>
                          <div
                            style={{
                              backgroundColor: "black",
                              width: "1px",
                              height: "100%",
                            }}
                          />
                        </>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => removeCancellationPolicyField(index)}
                          >
                            <RemoveCircle />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: textFieldStyles,
                    }}
                    style={{
                      borderRadius: "15px",
                      border: "solid 1px rgba(66, 70, 81, 0.2)",
                      padding: "10px",
                    }}
                  />
                  <div className="d-flex gap-3">
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      id="CancellationPolicy"
                      name="CancellationPolicy"
                      placeholder="Cancellation Policy"
                      multiline={true}
                      value={item?.hour}
                      onChange={(event) => {
                        const updatedHr = event.target.value
                        if(/^\d*$/.test(updatedHr)){
                        setCancellationPolicy((prevPolicy) => {
                          const updatedPolicies = [...prevPolicy];
                          updatedPolicies[index] = {
                            ...updatedPolicies[index],
                            hour: updatedHr,
                          };
                          return updatedPolicies;
                        });
                      }
                      }}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          backgroundColor: "white",
                          borderRadius: "5px",
                        },
                        startAdornment: (
                          <>
                            <Typography
                              style={{ fontSize: "clamp(12px, 0.9vw, 20px)" }}
                            >
                              Hours:
                            </Typography>
                            {/* <div
                              style={{
                                backgroundColor: "black",
                                width: "1px",
                                height: "100%",
                              }}
                            /> */}
                          </>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        style: {
                          fontSize: "clamp(12px, 0.9vw, 20px)",
                          whiteSpace: "nowrap",
                        },
                      }}
                      style={{
                        borderRadius: "15px",
                        border: "solid 1px rgba(66, 70, 81, 0.2)",
                        padding: "10px",
                      }}
                    />
                    <CustomTextField
                      margin="normal"
                      fullWidth
                      id="CancellationPolicy"
                      name="CancellationPolicy"
                      placeholder="Cancellation Policy"
                      multiline={true}
                      value={item?.percentage}
                      onChange={(event) => {
                        const updatedPercentage = event.target.value;
                        if(/^\d*$/.test(updatedPercentage)){
                        setCancellationPolicy((prevPolicy) => {
                          const updatedPolicies = [...prevPolicy];
                          updatedPolicies[index] = {
                            ...updatedPolicies[index],
                            percentage: updatedPercentage,
                          };
                          return updatedPolicies;
                        });
                      }
                      }}
                      variant="standard"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          backgroundColor: "white",
                          borderRadius: "5px",
                        },
                        startAdornment: (
                          <>
                            <Typography
                              style={{ fontSize: "clamp(10px, 0.9vw, 20px)" }}
                            >
                              Percentage:
                            </Typography>
                            {/* <div
                              style={{
                                backgroundColor: "black",
                                width: "1px",
                                height: "100%",
                              }}
                            /> */}
                          </>
                        ),
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        style: {
                          fontSize: "clamp(12px, 0.9vw, 20px)",
                          whiteSpace: "nowrap",
                        },
                      }}
                      style={{
                        borderRadius: "15px",
                        border: "solid 1px rgba(66, 70, 81, 0.2)",
                        padding: "10px",
                      }}
                    />
                  </div>
                </div>
              );
            })}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {cancellationPolicy?.at(-1)?.policy === "" ? (
                <Typography className={class_name.err_msg_style}>
                  Please enter Policy
                </Typography>
              ) : null}
              <IconButton
                style={{
                  border: "solid 1px rgba(66, 70, 81, 0.2)",
                  // width: "100%",
                  display: "flex",
                }}
                onClick={() => {
                  if (
                    cancellationPolicy[cancellationPolicy?.length - 1]
                      ?.policy !== "" &&
                    cancellationPolicy.length < 5
                  ) {
                    addCancellationPolicyField();
                  } else {
                    toast.dismiss();
                    toast.info(
                      cancellationPolicy[cancellationPolicy?.length - 1]
                        ?.policy !== ""
                        ? "You can set only Maximum 5 cancellation policy"
                        : "Please enter the policy",
                      {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                      }
                    );
                  }
                }}
              >
                <Add />
              </IconButton>
            </div>
          </Grid>

          <div className={class_name.btnDiv}>
            <div
              className={class_name?.save_and_continue_btn}
              onClick={() => {
                handleSubmit();
              }}
              style={{
                backgroundColor:
                  greetingMessage !== "" &&
                  // youTubeLink !== "" &&
                  selectedDateTime?.length > 0
                    ? "#026b93"
                    : "rgba(151, 151, 151, 1)",
              }}
            >
              <Typography
                className={`${class_name.save_and_continue_txt} hoverEffect`}
              >
                Submit Your Offer
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-style-hide">
        <Footer />
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  page_body_container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#f6f6f6",
    paddingBottom: "3%",

    [theme.breakpoints.down("sm")]: {
      overflowY: "scroll",
      WebkitOverflowScrolling: "touch",
    },
  },
  Show_off_your_boat_in_few_clicks: {
    marginTop: "1.1%",
    fontSize: "clamp(22.5px, 5vw, 45px)",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.51,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#424651",
  },

  form_container_box: {
    backgroundColor: "#fff",
    alignSelf: "center",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    border: "solid 0.5px rgba(66, 70, 81, 0.1)",
    paddingLeft: "5%",
    paddingRight: "5%",
    borderRadius: "2px",
    width: "90%",
    marginTop: "15px",
    [theme.breakpoints.up("lg")]: {
      marginTop: "40px",
      width: "80%",
    },

    [theme.breakpoints.up("1700")]: {
      marginTop: "30px",
      width: "70%",
      // backgroundColor: "red",
    },
  },
  step_3_txt: {
    fontSize: "clamp(14px, 5vw, 35px)",
    fontWeight: "bolder",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.51,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
    padding: "20px 0px",
  },

  write_greeting_msg: {
    fontSize: "clamp(12px, 3vw, 20px)",
    fontWeight: "500",
    textAlign: "left",
    color: "#424651",
    marginTop: "8%",
    marginBottom: "1%",
  },

  start_at_txt: {
    fontSize: "clamp(12px, 1.2vw, 22px)",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "left",
    color: "#424651",
  },

  btnDiv: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "6% 0px",
  },

  save_and_continue_btn: {
    cursor: "pointer",
    borderRadius: "15px",
    border: "solid 1px #026b93",
    padding: "2% 7%",
    display: "flex",
    justifyContent: "flex-start",
    alignContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "flex-start",
    transition: "background-color 0.3s",
    backgroundColor: "#026b93", // Add a smooth transition for the background color
    "&:hover": {
      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.24)",
      // Change the background color on hover
      "& $save_and_continue_txt": {},
    },
  },

  save_and_continue_txt: {
    fontSize: "clamp(8px, 2vw, 24px)", // Adjust the range as needed
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 0.96,
    letterSpacing: "normal",
    textAlign: "center",
    color: "white",
  },

  err_msg_style: {
    fontSize: "clamp(10px, 1vw, 20px)",
    color: "red",
    textAlign: "left",
  },
  calendar_and_timeSlot: {
    display: "flex",
    flexDirection: "row",
    // padding: "1%",
    width: "100%",
    // backgroundColor: "red",
  },
  copy_pin_img: {
    width: "clamp(15px, 3vw, 31px)", // Adjust the range as needed
    height: "clamp(15px, 3.5vh, 31px)",
  },

  show_calendar_box: {
    padding: "3% 0",
    width: "50%",
  },
  show_calendar_selected_DateAndTime_box: {
    padding: "5% 0 5% 3%",
    width: "50%",
  },

  show_txt_length: {
    fontSize: "clamp(10px, 1vw, 14px)",
    color: "rgba(66, 70, 81, 0.5)",
    textAlign: "right",
  },

  day_txt_style: {
    // fontSize: "clamp(10px, 5vw, 20px)",
    fontSize: "26px",
    // fontFamily: "Poppins",
    color: "#424651",
    borderBottom: "none",
    backgroundColor: "#fff",
    // backgroundColor: "red",
    borderRadius: "15px",
    paddingTop: "10px",
    paddingBottom: "10px",
    // paddingLeft: "10px",
    // color: "red",
  },
  show_time_boxs: {
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignContent: "center",
    alignItems: "center",
    cursor: "pointer",
    margin: "10px",
    // border: "solid 1px rgba(66, 70, 81, 0.1)",

    [theme.breakpoints.down("sm")]: {
      padding: "5% 3.5%",
      margin: "10px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "10% 3.5%",
      margin: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "10% 3.5%",
      margin: "10px",
    },
    [theme.breakpoints.down("764")]: {
      padding: "2% 3.5%",
      margin: "10px",
    },
  },
  copyTimeDate_txt: {
    fontSize: "clamp(12px, 2vw, 16px)",
    color: "#424651",
    "&:hover": {
      color: "#66BB6A", // Change the color to your desired hover color
    },
  },

  time_box: {
    "& .MuiOutlinedInput-input": {
      fontSize: "clamp(14px, 2vw, 16px)",
      padding: "10px 18px 10px 10px",
      [theme.breakpoints.down("457")]: {
        padding: "5px 18px 5px 10px",
      },
    },
    "& .MuiSelect-iconOutlined": {
      right: -4,
      // top:0,
      // bottom: 0,
      // alignContent: 'center',
      // alignSelf: 'center',
      [theme.breakpoints.down("457")]: {
        top: 2,
      },
    },
    "& MuiInputBase-root": {
      display: "flex",
      alignItems: "center",
    },
  },

  select: {
    // padding: '0px',
    // "& .MuiSelect-select":{
    //   padding: '10px',
    //   paddingRight: '30px'
    // }
    // "& .MuiOutlinedInput-notchedOutline": { border: 0 }
  },

  removeCircleIcon: {
    display: "none", // Initially hide the icon
  },
  root: {
    "&:hover $removeCircleIcon": {
      display: "block", // Show the icon when the parent div is hovered
    },
  },

  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    page_body_container: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#f6f6f6",
      paddingBottom: "15%",
    },

    form_container_box: {
      backgroundColor: "#fff",
      alignSelf: "center",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
      paddingLeft: "5%",
      paddingRight: "5%",
      borderRadius: "15px",
      width: "90%",
      marginTop: "15px",
      border: "solid 1px rgba(66, 70, 81, 0.1)",
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
        width: "90%",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: "30px",
        width: "80%",
      },
      [theme.breakpoints.up("lg")]: {
        marginTop: "40px",
        width: "70%",
      },
    },

    save_and_continue_btn: {
      borderRadius: "0px",
      position: "fixed",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: "rgba(151, 151, 151, 1)",
      border: "solid 1px rgba(151, 151, 151, 1)",
      padding: "0px",
      width: "100%",
      height: "56px",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      alignSelf: "center",
    },
    save_and_continue_txt: {
      fontSize: "clamp(16px, 5vw, 20px)", // Adjust the range as needed
      fontWeight: "400",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.96,
      letterSpacing: "normal",
      textAlign: "center",
      color: "white",
    },
    calendar_and_timeSlot: {
      display: "flex",
      flexDirection: "column",
      padding: 0,
      // padding-top: 15px;
      // width: 100%;
      // alignSelf: "center",
      // justifyContent: "center",
      // alignItems: "center",
    },
    show_calendar_box: {
      padding: "5% 0% 2%",
      width: "100%",
    },

    show_calendar_selected_DateAndTime_box: {
      padding: "5% 0% 2%",
      width: "100%",
    },

    copy_pin_img: {
      width: "clamp(20px, 3.5vw, 31px)", // Adjust the range as needed
      height: "clamp(20px, 3.5vh, 31px)",
      // backgroundColor: "lightblue",
    },
  },
}));

//Styling CSS

const timeTxtStyle = {
  cursor: "pointer",
  letterSpacing: 0.5,
  padding: 5,
  fontSize: 14,
};

const textFieldStyles = {
  // fontSize: "clamp(12px, 0.9vw, 20px)",
  // borderRadius: "15px",
  // borderWidth: ".1px",
  // borderColor: "rgba(66, 70, 81, 0.2)",
};
