import React, { useState } from "react";
import "./BookingHistory.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
// import { HeaderContent } from "../../Common/map/HeaderContent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../../Component/Footer/Footer";
import { MenuItem, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { PageHeader } from "../../Dash/page-header/PageHeader";
import IMAGES from "../../Images";
import { boat_booking_list_API, booking_status_detail_API, cancel_trip, previous_booking_status_detail_API } from "../../../Service/api";
import { useEffect } from "react";
import moment from "moment";
import Loader from "../../Loader";
import PopupPage from "../../Common/PopupPage/PopupPage";
import { popup_page } from "../../../redux/slices";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useWindowDimensions from "../../../UI kit/useWindowDimensions";
import { current } from "@reduxjs/toolkit";

const styles = (theme) =>({
  root: {
    //outer
    marginTop: "0px",
    borderRadius: "8px",
    // paddingTop: "3px",

    // Styles for the input text
    "& .MuiInputBase-input": {
      fontSize: "clamp(12px, 0.9vw, 20px)",
      // textAlign: "left",
      color: "#424651",
      borderBottom: "none",
      // padding: '7%',
      // paddingLeft: '9%',
      // [theme.breakpoints.down("992")]: {
      //   padding: '2.7%',
      //   paddingLeft: '4.7%',
      // },
      // [theme.breakpoints.down("480")]: {
      //   padding: '4.2%',
      //   paddingLeft: '6.2%',
      // },
    },
    // "& .MuiInputBase-readOnly": {
    //   border: 'none'
    // },

    // Styles for the placeholder text
    "& .MuiFormLabel-root": {
      borderBottom: "none",
      border: "none",
    },

    // Styles for the text field box (border and background)
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px",
      borderBottom: "none",
      fontSize: "clamp(12px, 0.9vw, 20px)",
    },

    // Remove the notched outline
    "& .MuiOutlinedInput-notchedOutline": {
      borderWidth: "0px",
      fontSize: "clamp(12px, 0.9vw, 20px)",
      border: "none",
    },

    "& .MuiFormLabel-filled": {
      transform: 'translateY(-1rem)',
    },

    // "& .MuiInputLabel-root": {
    //   height: '45%',
    //   display: 'flex',
    //   alignItems: 'center',
    //   [theme.breakpoints.down('992')]: {
    //     height: '50%',
    //   },
    //   [theme.breakpoints.down('480')]: {
    //     height: '40%',
    //   },
    // },
    "& .MuiSelect-select": {
      padding: '15px',
      [theme.breakpoints.down("992")]: {
        padding: '15px'
      },
      [theme.breakpoints.down("480")]: {
        padding: '15px',
      },
    },
    // "& .MuiInputLabel-shrink": {
    //   margin: "0 auto",
    //   position: "absolute",
    //   right: "0",
    //   left: "0",
    //   top: "-3px",
    //   width: "150px", // Need to give it a width so the positioning will work
    //   background: "white" // Add a white background as below we remove the legend that had the background so text is not meshing with the border
    //   // display: "none" //if you want to hide it completly
    // },

    // Apply styles for small screens
    [theme.breakpoints.down("sm")]: {
      "& .MuiInputBase-input": {},
      // "& .MuiFormLabel-root": {
      //   borderBottom: "none",
      //   fontSize: "12px",
      // },
      // borderRadius: "8px",
    },

    // Adjust for medium screens (md)
    [theme.breakpoints.up("md")]: {
      "& .MuiInputBase-input": {},
      // borderRadius: "15px",
    },

    // Adjust for large screens (lg)
    [theme.breakpoints.up("lg")]: {
      "& .MuiInputBase-input": {},
      // borderRadius: "15px",  
    },
  },
})

const CustomTextField = withStyles(styles)(TextField);

export const BookingHistory = () => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch()
  const auth = useSelector((state) => state?.auth);
  const dashboard = useSelector((state) => state?.dashboard);
  const navigate = useNavigate();
  const class_name = useStyles({ min: 10, max: 60, unit: "px" });
  const [isLoading,setIsLoading] = useState(false)
  const [bookingStatus, setBookingStatus] = useState("Current");
  const [dropdown,setDropdown] = useState(false)
  const [boatBookingDetails,setBoatBookingDetails] = useState([])
  const [openPopup, setOpenPopup] = useState(false)
  const [selectStartDate, setSelectStartDate] = useState('')
  const [selectEndDate, setSelectEndDate] = useState('')
  const [selectBookingStatus, setSelectBookingStatus] = useState('')
  const [selectPaymentStatus, setSelectPaymentStatus] = useState('')
  const [bookingStatusDetail, setBookingStatusDetail] = useState([])

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // if(
    //   !selectStartDate && !selectEndDate &&
    //   !selectPaymentStatus && !selectBookingStatus
    //   ){
        setIsLoading(true);
        let payload = {
          type: 
          bookingStatus === "Current"? 
          "CURRENT_BOOKING":"PREVIOUS_BOOKING",
          start_date: selectStartDate? moment(selectStartDate).format("YYYY-MM-DD"):'',
          end_date: selectEndDate? moment(selectEndDate).format("YYYY-MM-DD"):'',
          booking_status: selectBookingStatus,
          payment_status: selectPaymentStatus
        };
        console.log(payload,'booking history payload')
        boat_booking_list_API(auth?.AuthToken, payload)
          .then((res) => {
            console.log("res?.data", res?.data);
            if (res?.data?.message === 'success') {
              setBoatBookingDetails(res?.data?.parameters);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log("boat booking data -=-=-=-=-=->>>>>  Error : ", err);
            toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setIsLoading(false);
          });
    // }
  }, [
    auth?.AuthToken,
    bookingStatus
  ]);
  console.log(selectEndDate,'end date')


  useEffect(()=>{
      if(
      !selectStartDate && !selectEndDate &&
      !selectPaymentStatus && !selectBookingStatus
      ){
        setIsLoading(true);
        let payload = {
          type: 
          bookingStatus === "Current"? 
          "CURRENT_BOOKING":"PREVIOUS_BOOKING",
        };
        console.log(payload,'booking history payload')
        boat_booking_list_API(auth?.AuthToken, payload)
          .then((res) => {
            console.log("res?.data", res?.data);
            if (res?.data?.message === 'success') {
              setBoatBookingDetails(res?.data?.parameters);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log("boat booking data -=-=-=-=-=->>>>>  Error : ", err);
            toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setIsLoading(false);
          });
      }
  },[
    selectStartDate,
    selectEndDate,
    selectPaymentStatus,
    selectBookingStatus
  ])

  useEffect(()=>{
    if(bookingStatus === 'Current'){
      booking_status_detail_API()
      .then((res)=>{
        console.log(res?.data,'booking status detail')
        setBookingStatusDetail(res?.data)
      })
      .catch((err)=>{
        console.log(err,'booking status detail')
      })
    } else {
      previous_booking_status_detail_API()
      .then((res)=>{
        console.log(res?.data,'booking status detail')
        setBookingStatusDetail(res?.data)
      })
      .catch((err)=>{
        console.log(err,'booking status detail')
      })
    }
  },[bookingStatus])
  // console.log(boatBookingDetails[0]?.boat_owner_id)
  // console.log(openPopup,'open popup')

  const handleClosePopup = () =>{
    setOpenPopup(false)
  }

  const handle_navigation = (name) => {
    if (name === "chat") {
      navigate("/allMessage");
    } else if(name === 'My Listings'){
      navigate('/myListings')
    } else if(name === "Home"){
      if(auth?.userDetails?.user_type === "BOAT_OWNER"){
        navigate('/boatOwnerDashBoard')
      } else {
        navigate('/rental')
      }
    }
    else{
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const handleCancelTrip = (boat_booking_id) =>{
    console.log(boat_booking_id)
    let payload = {
      boat_booking_id: boat_booking_id
    }
    cancel_trip(auth?.AuthToken,payload)
    .then((res)=>{
      console.log(res?.data,'cancel trip')

      if(res?.data?.message === 'Trip cancelled successfully'){
        let updateBookingDetails = boatBookingDetails?.map((item)=>{
          if(item?.boat_booking_id === boat_booking_id){
            return {...item,trip_status: 'TRIP_CANCEL'}
          }
          return item
        })
        setBoatBookingDetails(updateBookingDetails)
      }
      setOpenPopup(false)
      dispatch(popup_page(null))
    })
    .catch((err)=>{
      console.log('cancel trip error',err)
      setOpenPopup(false)
      dispatch(popup_page(null))
    })
  }

  const handleStatusPopup = (status) =>{
    if(status?.button === 'accept'){
      if(status?.status === 'cancel'){
        handleCancelTrip(dashboard?.popup_page?.booking_id)
      } 
    } else {
      setOpenPopup(false)
    }
  }

  const handleCancelOpenPopup = (trip_status,start_time,end_time,booking_date,booking_id) =>{
    if(
      trip_status === "TRIP_CANCEL" ||
      trip_status === "TRIP_END"
      // trip_status === "PENDING"
      ){
        setOpenPopup(false)
      } else {
        setOpenPopup(true)
    dispatch(
      popup_page({
        ride_mode: 'cancel',
        ride_start_time: start_time,
        ride_end_time: end_time,
        ride_date: booking_date,
        booking_id: booking_id,
      })
    )
      }
  }

  const handleSubmitFilter = () =>{
    if(moment(selectStartDate)?.isAfter(moment(selectEndDate))){
      toast.error('Please select start date below than end date',{
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000
      })
    } 
    else if(moment(selectStartDate)?.isAfter(moment(selectEndDate))){
      toast.error('Please select start date below than end date',{
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000
      })
    }
    else if(
      selectStartDate && selectEndDate ||
      !selectStartDate && !selectEndDate &&
      (selectBookingStatus || selectPaymentStatus)
      ){
        setIsLoading(true)
        let payload = {
          type: 
          bookingStatus === "Current"? 
          "CURRENT_BOOKING":"PREVIOUS_BOOKING",
            start_date: selectStartDate? moment(selectStartDate).format("YYYY-MM-DD"):'',
            end_date: selectEndDate? moment(selectEndDate).format("YYYY-MM-DD"):'',
            booking_status: selectBookingStatus,
            payment_status: selectPaymentStatus
        }
        console.log(payload,'payload')
        boat_booking_list_API(auth?.AuthToken, payload)
          .then((res) => {
            console.log(res?.data);
            if (res?.data?.success) {
              setBoatBookingDetails(res?.data?.parameters);
            } else {
              toast.error(res?.data?.message ?? res?.data?.error, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
              });
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log("User booked boat list error: ", err);
            toast.error(err?.response?.data?.message??"Something went wrong. Please try again later.", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });
            setIsLoading(false);
          });
      } else {
        toast.error(`Please Select ${selectStartDate? "End Date": selectEndDate? "Start Date":"some filter Options"}`,{
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000
        })
      }
  }

  const handleClearFilter = () =>{
    setSelectStartDate('')
    setSelectEndDate('')
    setSelectBookingStatus('')
    setSelectPaymentStatus('')
  }

  return (
    <>
    <div style={{height: '100%',width: '100%'}}>
      <div className="booking-history-container">
      {isLoading ? <Loader loading={isLoading} /> : null}
        {/* <HeaderContent
          contentname1={"Home"}
          contentname2={"For Boat Owners"}
          contentname3={"For Boat Rentals"}
          contentname4={"Booking History"}
          handleBack={handleHeaderCallBack}
          search={"/searchBoat"}
          showLoginSignUp={auth?.AuthToken ? false : true}
          presentPage={"Booking History"}
        /> */}
          <PageHeader
            showLoginSignUp={auth?.AuthToken ? false : true}
            handle_navigation={handle_navigation}
            presentPage={"Home"}
            link1={"Boat Offers"}
            link2={"Scuba Courses/Programs"}
            link3={"Scuba Diving Trips"}
            link4={auth?.tokenDecodeData?.user_type === "BOAT_OWNER"? "My Listings":null}
          />
        <div
          className="container"
          // main-container
          // style={{ width: "100%", height: "100%", marginTop: "3%" }}
        >
          <Typography className="page-title">Booking History</Typography>
          <Typography className="info-text">
            you can easily view and manage all of your past and current boat
            bookings.
          </Typography>

          <div 
          className="gap-3 mt-2 align-items-center pb-3" 
          style={{display: isSmallScreen? "flex": 'none'}}
          onClick={()=>setDropdown(!dropdown)}
          >
            <Typography className="fs-6">{bookingStatus + " Booking"}</Typography>
            <img
                src={IMAGES.DOWN}
                alt="up"
                style={{}}
                className="down_arrow"
                // onClick={()=>setDropdown(!dropdown)}
              />
          </div>
          <div className="down_arrow_dropdown pb-4"
          style={{display: dropdown && isSmallScreen? 'flex':'none'}}
          >
              <Typography
                  className="tab-text"
                  onClick={() => {
                    setBookingStatus("Current");
                    setDropdown(false)
                  }}
                  style={{
                    color: bookingStatus === "Current" ? "#3973a5" : "black",
                  }}
                >
                  Current Booking
                </Typography>
                <Typography
                  className="tab-text"
                  onClick={() => {
                    setBookingStatus("Previous");
                    setDropdown(false)
                  }}
                  style={{
                    color: bookingStatus === "Previous" ? "#3973a5" : "black",
                  }}
                >
                  Previous Booking
                </Typography>
          </div>

          <div className="select-tab pb-4">
            <div
              className="inside-select-tab"
              onClick={() => {
                setBookingStatus("Current");
              }}
              style={{
                backgroundColor:
                  bookingStatus === "Current" ? "#3973a5" : "white",
              }}
            >
              <Typography
                className="tab-text"
                style={{
                  color: bookingStatus === "Current" ? "white" : "#424651",
                }}
              >
                Current Booking
              </Typography>
            </div>
            <div
              className="inside-select-tab"
              onClick={() => {
                setBookingStatus("Previous");
              }}
              style={{
                backgroundColor:
                  bookingStatus === "Previous" ? "#3973a5" : "white",
              }}
            >
              <Typography
                className="tab-text"
                style={{
                  color: bookingStatus === "Previous" ? "white" : "#424651",
                }}
              >
                Previous Booking
              </Typography>
            </div>
          </div>
          <div className={class_name.filter_div}>
              <div className="row gy-3 position-relative">
                <div className='col-lg-3 col-md-6'>
                  <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                    <MobileDatePicker
                    label={selectStartDate? 'Start Date':"Start Date"}
                    sx={{ 
                      "& fieldset": { 
                        border: 'none' 
                      },
                      "& .Mui-readOnly": {
                        padding: '8px',
                        paddingRight: '15px',
                        color: 'black'
                      }, 
                    }}
                    // className={{root:class_name.date_picker}}
                    // disablePast={true}
                    // minDate={new Date('01-01-2022')}
                    format="DD-MM-YYYY"
                    // placeholder="date"
                    value={selectStartDate}
                    onChange={(datevalue) => {
                      setSelectStartDate(datevalue?.$d);
                    }}
                    slotProps={{
                      field:{
                        // format: false,
                        // label: "date"
                        InputProps: {
                          endAdornment: (
                            <img src={IMAGES.DOWN_ARROW_2} style={{ width: "10px", height: "10px" }}/>
                          ),
                        },
                      },
                      textField: {
                        InputProps:{
                          style:{
                            fontSize: '13px',
                            ...textFieldStyles,
                            marginBottom: '8px',
                            borderRadius: width >= 480?"15px":'8px',
                            // padding: '10px'
                            // paddingRight: '13px'
                          },
                        },
                        InputLabelProps: {
                          style: {
                            transform: selectStartDate
                              ? "translateY(-1.2rem)"
                              : "translateY(-1.2rem)",
                            fontSize: "clamp(12px, 0.9vw, 20px)",
                            color: "black",
                            // paddingLeft: "3%",
                          },
                        },
                        error: false,
                        style:{
                          // className: class_name.date_picker,
                          width: '100%',
                          // padding: '10px'
                        }
                      }
                    }}
                    />
                  </LocalizationProvider>
                  {/* <span style={{
                    position: 'absolute',
                    // zIndex: '999',
                    left: '26px',
                    top: '26px',
                    backgroundColor: 'white',
                    paddingRight: '47px',
                    fontSize: "clamp(12px, 0.9vw, 20px)",
                    display: selectStartDate? 'none':'flex'
                  }}>
                    Start Date
                  </span> */}
                </div>
                <div className='col-lg-3 col-md-6'>
                  <LocalizationProvider dateAdapter={AdapterDayjs} fullWidth>
                    <MobileDatePicker 
                    
                    label={selectEndDate? 'End Date':"End Date"}
                    sx={{ "& fieldset": { border: 'none' },"& .Mui-readOnly": {padding: '8px',paddingRight: '15px'}, }}
                    // className={{root:class_name.date_picker}}
                    // disablePast={true}
                    format="DD-MM-YYYY"
                    // placeholder="date"
                    value={selectEndDate}
                    onChange={(datevalue) => {
                      setSelectEndDate(datevalue?.$d);
                    }}
                    slotProps={{
                      field:{
                        // format: false,
                        // label: "date"
                        InputProps: {
                          endAdornment: (
                            <img src={IMAGES.DOWN_ARROW_2} style={{ width: "10px", height: "10px" }}/>
                          )
                        },
                      },
                      textField: {
                        InputProps:{
                          style:{
                            fontSize: '13px',
                            ...textFieldStyles,
                            marginBottom: '8px',
                            borderRadius: width >= 480?"15px":'8px',
                            // padding: '10px'
                            // paddingRight: '13px'
                          }
                        },
                        InputLabelProps: {
                          style: {
                            transform: selectEndDate
                              ? "translateY(-1.2rem)"
                              : "translateY(-1.2rem)",
                            fontSize: "clamp(12px, 0.9vw, 20px)",
                            color: "black",
                            // paddingLeft: "3%",
                          },
                        },
                        error: false,
                        style:{
                          // className: class_name.date_picker,
                          width: '100%',
                          // padding: '10px'
                        }
                      }
                    }}
                    />
                  </LocalizationProvider>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <CustomTextField
                    label={"Booking Status"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="boat_type"
                    name="boat_type"
                    // placeholder="Trip / Watersport Types"
                    value={selectBookingStatus}
                    onChange={(e) => {
                      setSelectBookingStatus(e.target.value);
                    }}
                    // onTouchStart={()=>setOpenModal(false)}
                    select
                    InputProps={{
                      style: {
                        ...textFieldStyles,
                        borderRadius: width >= 480?"15px":'8px',
                      },
                    }}
                    InputLabelProps={{
                      // className: class_name.inputlabel_style,
                      style: {
                        transform: selectBookingStatus
                          ? "translateY(-1.2rem)"
                          : null,
                        fontSize: "clamp(12px, 0.9vw, 20px)",
                        color: "black",
                        // paddingLeft: "3%",
                      },
                    }}
                  >
                    {bookingStatusDetail?.map((item, index) => (
                      <MenuItem 
                      key={index} 
                      value={item?.status}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: "5px 10px",
                      }}
                      >
                             {
                        item?.status?.charAt(0)+
                        item?.status?.slice(1).toLowerCase()
                        }
                      </MenuItem>
                    ))}
                  </CustomTextField>
                </div>
                <div className='col-lg-3 col-md-6'>
                  <CustomTextField
                    label={"Payment Status"}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="boat_type"
                    name="boat_type"
                    // placeholder="Trip / Watersport Types"
                    value={selectPaymentStatus}
                    onChange={(e) => {
                        setSelectPaymentStatus(e.target.value);
                    }}
                    // onTouchStart={()=>setOpenModal(false)}
                    select
                    InputProps={{
                      style: {
                        ...textFieldStyles,
                        borderRadius: width >= 480?"15px":'8px',
                      },
                    }}
                    InputLabelProps={{
                      // className: class_name.inputlabel_style,
                      style: {
                        transform: selectPaymentStatus
                          ? "translateY(-1.2rem)"
                          : null,
                        fontSize: "clamp(12px, 0.9vw, 20px)",
                        color: "black",
                        // paddingLeft: "3%",
                      },
                    }}
                  >
                    <MenuItem 
                      // key={index} 
                      value={"PAID"}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: "5px 10px",
                      }}
                      >
                        Paid
                      </MenuItem>
                      <MenuItem 
                      // key={index} 
                      value={"PENDING"}
                      onChange={()=>{

                      }}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: "5px 10px",
                      }}
                      >
                        Pending
                      </MenuItem>
                      <MenuItem 
                      // key={index} 
                      value={"FAILED"}
                      onChange={()=>{

                      }}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        padding: "5px 10px",
                      }}
                      >
                        Failed
                      </MenuItem>
                  </CustomTextField>
                </div>
              </div>
              <div className="d-flex w-100 justify-content-center align-items-center gap-5 mt-3">
                <div>
                  <span 
                  className={class_name.filter_btn}
                  onClick={()=>handleSubmitFilter()}
                  >
                    Submit
                  </span>
                </div>
                <div>
                  <span 
                  className={class_name.filter_btn}
                  onClick={()=>handleClearFilter()}
                  >
                    Clear Filter
                  </span>
                </div>
              </div>
            </div>
          <div>
            {
            boatBookingDetails.length && !isLoading?
            boatBookingDetails.map((item,index)=>(
              <div 
              className="booking-row" 
              key={index} 
              >
                {/* <div className="d-flex justify-content-between"> */}
                  <div 
                  className="d-flex"
                  onClick={()=>{
                    navigate('/bookedReqDetails',{state:{boat_booking_id:item?.boat_booking_id}})
                  }}
                  >
                    <Typography className="booking-date">{moment(item?.booking_date).format("DD MMM")}</Typography>
                    <div className="boat-name-content">
                      <Typography className="booking-date">{item?.booking_boat_detail?.boat_name}</Typography>
                      <Typography className="booking-name-txt">{item?.booking_boat_detail?.marine_name}</Typography>
                    </div>
                  </div>
                  <div 
                  style={{
                    display: item?.boat_owner_id === auth?.userId? 'flex':'none'
                  }}
                  >
                    <span 
                    className="cancel_btn"
                    style={{
                      backgroundColor: 
                      item?.trip_status === "TRIP_CANCEL"?
                      'transparent':'darkred',
                      color: 
                      item?.trip_status === "TRIP_CANCEL"?
                      'red':'white',
                    }}
                    onClick={()=>{
                      handleCancelOpenPopup(
                        item?.trip_status,
                        item?.start_time,
                        item?.end_time,
                        item?.booking_date,
                        item?.boat_booking_id
                        )
                    }
                      }
                    >
                      {
                        item?.trip_status === "TRIP_CANCEL"?
                        "Cancelled":"Cancel Trip"
                      }
                    </span>
                  </div>
                {/* </div> */}
              </div>
            )):
            <div className={class_name.noDataContent} style={{display: isLoading? 'none':'flex'}}>
            <img
              alt="no result"
              src={IMAGES.NO_RESULT}
              className={class_name.noResultImg}
            />
            <Typography className={class_name.sryTxt}>
              Sorry, no results found.
            </Typography>
          </div>
          }
        </div>
        </div>
        {openPopup?
        <PopupPage
        open={openPopup}
        handleClosePopup={handleClosePopup}
        handleStatus={handleStatusPopup}
        title={`Are you sure you want to ${dashboard?.popup_page?.ride_mode} your ride`}
        />:null
      }
        <div className="d-none d-md-block">
          <Footer />
        </div>
      </div>
    </div>
    </>
  );
};

{
  /* <Col>
<div className="d-flex justify-content-center">oaudfvubsdv</div>
</Col>
</Row>
<Row className="justify-content-center">
<Col>GJ</Col> */
}

const useStyles = makeStyles((theme) => ({
  noDataContent: {
    display: "flex",
    // minHeight: '100%',
    flexDirection: "column",
    marginTop: "100px",
    alignItems: "center",
    paddingBottom: '5%',
  },
  noResultImg: {
    width: "clamp(80px, 35vw, 139px)", // Adjust the range as needed
    height: "clamp(80px, 35vh, 139px)", // Adjust the range as needed
    [theme.breakpoints.down('767')]: {
      width: "clamp(30px, 35vw, 80px)", // Adjust the range as needed
      height: "clamp(30px, 35vh, 80px)", // Adjust the range as needed
    }
  },
  sryTxt: {
    fontSize: "clamp(14px, 3vw, 48px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 0.87)",
  },
  filter_div: {
    paddingTop: '5%',
  },

  filter_btn: {
    backgroundColor: '#026b93',
    padding: '7px 20px',
    color: 'white',
    whiteSpace: 'nowrap',
    borderRadius: '2px',
    fontWeight: '600',
    cursor: 'pointer'
  },

}))

const textFieldStyles = {
  // borderRadius: "15px",
  backgroundColor: "white",
  border: "solid 1px rgba(66, 70, 81, 0.2)",
  // padding: '10px'
  // padding: '0%'
};
