import React, { useEffect, useRef, useState } from "react";
import Footer from "../../../Component/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { PageHeader } from "../page-header/PageHeader";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import IMAGES from "../../Images";
import { Chat } from "./Chat";
import { chat_person_list_API } from "../../../Service/api";
import Loader from "../../Loader";
import { openChat } from "../../../redux/slices";
import { API } from "../../../Service/constants";
import moment from "moment";
import { formatTimeAgo } from "../../../UI kit/calculate Time/formatTimeAgo";
import socket from "../../../Service/SocketIo";

const message_list = new Array(21).fill(null);

export const AllMessage = () => {
  const class_name = useStyles({ min: 10, max: 30, unit: "px" });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state?.auth);
  const dash = useSelector((state) => state?.dashboard);
  const [isLoading, setIsLoading] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [loadChatPersonAPI, setLoadChatPersonAPI] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const PageHeaderRef = useRef(null);
  const [unReadCountArr, setUnReadCountArr] = useState(null);
  const [chatStatus, setChatStatus] = useState(null)
  const [onlineStatus, setOnlineStatus] = useState(false)

  // // API call
  useEffect(() => {
    setIsLoading(true);
    let payload = {
      type: auth?.userDetails?.user_type,
    };
    // console.log("payload", payload);
    chat_person_list_API(auth?.AuthToken, payload)
      .then((res) => {
        console.log("chat_person_list res", res?.data);
        if (res?.data?.success) {
          setChatList(res?.data?.data);
        } else {
          toast.error(res?.message??"Something went wrong. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("chat_person_list err", err);
        console.log(auth?.AuthToken)
        setIsLoading(false);
        toast.error(err?.message??"Something went wrong. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      });
  }, []);

  // socket
  useEffect(() => {
    socket.connect(auth?.AuthToken);

    return () => {
      // socket.disconnect();
    };
    // socket.client.on("connect", () => {
    //   console.log("connected");
    // });

    // return () => {
    //   // User leaves room
    //   socket.client.disconnect();

    //   socket.client.off("disconnect", () => {
    //     console.log("disconnected");
    //   });
    // };
  }, [window.location.search]);

  window.onbeforeunload = function (e){
    e.preventDefault()
    socket?.client?.emit("online_status", auth?.userId, false, (err) => {
      console.log("online status socket result", err);
    });
  }

  console.log(auth?.AuthToken, 'token')

    //online status
    useEffect(() => {
      // This function will be called when the component mounts
      console.log('Component mounted');
      socket.client.emit('online_status',auth?.userId,true,(err)=>{
        console.log('online status socket result',err)
      })

      // if(auth?.userDetails?.user_type === 'BOAT_OWNER'){
      //   socket.client.emit("getUnreadChatRoomsCount", auth?.userId,auth?.userDetails?.user_type, (err) => {
      //     console.log("getunread chat room count err", err);
      //   });
      // }

      // if(auth?.userDetails?.user_type === 'CUSTOMER'){
      //   socket.client.emit("getUnreadChatRoomsCountforcus", auth?.userId, (err) => {
      //     console.log("getunread chat room count err", err);
      //   });
      // }
  
      // This function will be called when the component unmounts
      return () => {
        console.log('Component will unmount',socket);
        socket.client.emit('online_status',auth?.userId,false,(err)=>{
          console.log('online status socket err',err)
        })
        // socket.disconnect()
      };
    },[]); 

  //socket.on
  useEffect(() => {

    socket.client.on("unreadCount", (count) => {
      console.log(count,'unread count')
      setUnReadCountArr(count);
    });

    socket.client.on("chat_status_sent", (status) => {
      console.log(status, "chat status online");
      setChatStatus(status)
    });

    socket.client.on("update online", (status) => {
      console.log(status,'update online')
      if (status?.userId === auth?.userId) {
        setOnlineStatus(status?.isOnline);
      }
    })
  }, []);

  useEffect(()=>{
    let updateChatList = chatList?.map((prev, i) => {
      if (prev?.user_id === chatStatus?.userId && chatStatus?.boatOwnerId === prev?.boat_owner_id) {
        return {
          ...prev,
          status: chatStatus?.status
        };
      }
      return {...prev};
    });
    console.log(updateChatList,'updatechatlist')
    setChatList(updateChatList)
  },[chatStatus])

  console.log(dash?.Opened_chat_data,'chatlist',chatList,chatStatus)

  useEffect(() => {
    console.log(unReadCountArr,'unread count arr')
    handleChangeUnreadCount()
  }, [unReadCountArr]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleChangeUnreadCount = () =>{
    console.log(unReadCountArr,'unread count arr')
    let unreadLastMsg;
    if (unReadCountArr?.lastMessage?.length > 28 && !unReadCountArr?.lastMessage?.startsWith('https')) {
      unreadLastMsg = unReadCountArr?.lastMessage.substring(0, 28) + " ...";
    } else {
      unreadLastMsg = unReadCountArr?.lastMessage;
    }
    let updateChatList = chatList?.map((prev, i) => {
      if (prev?.chat_id === unReadCountArr?.chatRoomId) {
        // console.log(unReadCountArr?.unreadCountBoatOwner,unReadCountArr?.unreadCountUser,'unread count')
        return {
          ...prev,
          unread_count_user: unReadCountArr?.unreadCountUser,
          unread_count_boat_owner: unReadCountArr?.unreadCountBoatOwner,
          last_message_user: unreadLastMsg,
          // updatedAt: unReadCountArr?.lastMessagetime,
          chat_room_history: [{...prev?.chat_room_history[0],createdAt: unReadCountArr?.lastMessagetime}]
        };
      }
      return prev;
    });
    
    // let indexToDelete = updateChatList.findIndex(
    //   (prev) => prev?.chat_id === unReadCountArr?.chatRoomId
    // );

    updateChatList.sort((a,b)=> moment(b?.chat_room_history[0]?.createdAt)-moment(a?.chat_room_history[0]?.createdAt))
    setChatList(updateChatList);
  }

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };
  // alert(pageHeaderHeight)

  const handle_navigation = (name) => {
    toast.dismiss();
    if (name === "Home") {
      if(auth?.userDetails?.user_type === "BOAT_OWNER"){
        navigate('/boatOwnerDashBoard')
      } else {
        navigate('/rental')
      }
    } else if (name === "My Listings") {
      navigate("/myListings");
    } else {
      toast.info("Under Development", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };
  console.log(chatList,'chat list',unReadCountArr)

  //render_message_list
  const render_message_list = (item, index) => {
    // console.log(item,'item')
    let unreadLastMsg;
    if (item?.last_message_user?.length > 28 && !item?.last_message_user?.startsWith('https')) {
      unreadLastMsg = item?.last_message_user.substring(0, 28) + " ...";
    } else {
      unreadLastMsg = item?.last_message_user;
    }
    // console.log(item?.last_message_user?.includes('mp4'),'last msg')
    let unReadCount =
      // auth?.userDetails?.user_type === "BOAT_OWNER"
      auth?.userId === item?.boat_owner_id
        ? item?.unread_count_user
        : item?.unread_count_boat_owner;
        console.log(unReadCount,'unread count')
    return (
      <div
        key={index}
        className={class_name.msg_list_detail_box}
        // style={{backgroundColor: "lightpink"}}
        onClick={() => {
          if (windowWidth > 767) {
            handleOpenChat(item);
          } else {
            handleOpenChat(item);
            navigate("/chat");
          }
        }}
      >
        <div 
        className={class_name.profile_icon_style}
        style={{
          backgroundImage: `url(${
            item?.boat_user?.profile_image
            ? `
            
            ${
              item?.boat_user?.profile_image //${API.baseUrls[API.currentEnv]}
            }`
            : item?.user_boat?.profile_image
            ? `
            
            ${
              item?.user_boat?.profile_image //${API.baseUrls[API.currentEnv]}
            }`
            : IMAGES.PROFILE_ICON
          })`
        }}
        >
          
        </div>
        {/* <img
          alt="profile"
          src={
            item?.boat_user?.profile_image
              ? `
              
              ${
                item?.boat_user?.profile_image //${API.baseUrls[API.currentEnv]}
              }`
              : item?.user_boat?.profile_image
              ? `
              
              ${
                item?.user_boat?.profile_image //${API.baseUrls[API.currentEnv]}
              }`
              : IMAGES.PROFILE_ICON
          }
        /> */}
        <div className={class_name.column_spaceBtn}>
          <div
            className={class_name.row_spaceBtwn}
            style={{ alignItems: "flex-start" }}
          >
            <Typography className={class_name.chat_name_txt}>
              {item?.user_boat
                ? `${item?.user_boat?.first_name} ${
                    item?.user_boat?.last_name
                  }`
                : `${item?.boat_user?.first_name} ${
                    item?.boat_user?.last_name
                  }`}
            </Typography>
            <Typography className={class_name.chat_time_txt}>
              {formatTimeAgo(item?.chat_room_history[0]?.createdAt)}
              {/* {moment(item?.chat_room_history[0]?.createdAt)?.format('DD-MM-YYYY h:mm A')} */}
            </Typography>
          </div>
          <div
            className={class_name.row_spaceBtwn}
            style={{ alignItems: "flex-start" }}
          >
            <Typography className={class_name.chat_index_msg_txt}>
              <span
                style={{
                  fontWeight: unReadCount ? "bold" : "normal",
                }}
              >
                {unreadLastMsg?.endsWith("mp4")
                  ? "Video"
                  : unreadLastMsg?.startsWith("https")?
                  'Image':
                  unreadLastMsg ?? "Thank you for your registeration"}
              </span>
            </Typography>
            <>
              {unReadCount > 0 && (
                <span className={class_name.unread_count}>{unReadCount}</span>
              )}
            </>
          </div>
        </div>
      </div>
    );
  };

  const handleOpenChat = (item) => {
    dispatch(openChat(item));
    toast.dismiss();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          backgroundColor: "#f6f6f6",
          justifyContent: "space-between",
        }}
      >
        {isLoading ? <Loader loading={isLoading} /> : null}
        <div className={class_name.parent_div}>
          <PageHeader
            ref={PageHeaderRef}
            showLoginSignUp={auth?.AuthToken ? false : true}
            handle_navigation={handle_navigation}
            // presentPage={"Home"}
            link1={"Boat Offers"}
            link2={"Scuba Courses/Programs"}
            link3={"Scuba Diving Trips"}
            link4={
              auth?.tokenDecodeData?.user_type === "BOAT_OWNER"
                ? "My Listings"
                : null
            }
          />
          <div
            style={{
              // marginTop: "2%",
              border: "solid 0.5px rgba(66, 70, 81, 0.3)",
            }}
          />
          <div className={class_name.body_container}>
            {/* <div
              style={{
                display: "flex",
                width: "100%",
                //   height: "80vh",
                //   // minHeight: "70vh",
                //   overflowY: "scroll",
                //   // height: "auto",
                //   // backgroundColor: "lightgoldenrodyellow",
              }}
            > */}
            {/* ======================================    all msg list   ====================================================== */}

            <div className={class_name.chat_list_box}>
              <div className={class_name.row_spaceBtwn}>
                <Typography className={class_name.title_txt}>
                  All Messages
                </Typography>
                {/* <img
                  src={IMAGES.FILTER_ICON}
                  alt="filter"
                  className={class_name.filter_icon}
                /> */}
              </div>
              <div style={{ height: "100%", overflowX: "hidden" }}>
                <div className={class_name.show_msg_lists}>
                  {chatList?.length ? (
                    chatList?.map((item, index) => {
                      return render_message_list(item, index);
                    })
                  ) : (
                    <div className="d-flex justify-content-center align-items-center text-center pt-5">
                      <span
                        className={class_name.sryTxt}
                        style={{
                          display: isLoading ? "none" : "block",
                        }}
                      >
                        No Message Found
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* ======================================    open chat   ====================================================== */}
            {dash?.Opened_chat_data ? (
              <div className={class_name.open_chat}>
                <Chat setLoadChatPersonAPI={setLoadChatPersonAPI} />
              </div>
            ) : (
              <div
                // className="d-flex justify-content-center align-items-center"
                className={class_name.open_chat}
                style={{
                  // width: '60%',
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span className={class_name.sryTxt}>Chat Messages</span>
              </div>
            )}
          </div>
        </div>

        {/* <div className="footer-style-hide">
          <Footer />
        </div> */}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme, pageHeaderHeight, windowHeight) => ({
  parent_div: {
    // display: "flex",
    width: "100%",
    // height: '100vh',
    height: "100%",
    overflow: "hidden",
    // height: "auto",
    flexDirection: "column",
    // backgroundColor: "lightgreen",
  },
  body_container: {
    padding: "0% 0% 0% 2%",
    // height: "fit-content",
    // height: "auto",
    display: "flex",
    width: "100%",
    height: "88vh",
    // flex: '1 1 100%'
    overflowY: "hidden",
    // position: 'relative'
    // backgroundColor: "pink",
  },
  chat_list_box: {
    display: "flex",
    flexDirection: "column",
    // paddingBottom: '10%',
    paddingTop: "5%",
    width: "37%",
    // overflowY: 'auto',
    // bottom: '0',
    // height: 'auto',
    // height: "85vh",
    // position: 'relative',
    borderRight: "solid 0.5px rgba(112, 112, 112, 0.6)",
    // backgroundColor: "lightpink",
    // marginBottom: '100px'
  },

  open_chat: {
    display: "flex",
    width: "63%",
    height: "auto",
    [theme.breakpoints.down("767")]:{
      display: 'none'
    }
    // height: '86vh',
    // flex: '1 1 100%',
    // height: `${windowHeight-pageHeaderHeight}px`
    // overflowX: "hidden",
    // backgroundColor: "lightgreen",
  },

  row_spaceBtwn: {
    display: "flex",
    width: "100%",
    // height: '100%',
    justifyContent: "space-between",
    // backgroundColor: "lightpink",
    alignItems: "center",
    paddingRight: "2%",
    paddingBottom: "5%",
  },
  title_txt: {
    fontSize: "clamp(16px, 4vw, 38px)",
    fontWeight: "bold",
    color: "#36454F",
  },
  filter_icon: {
    width: "clamp(20px, 4.9vw, 32px)",
    height: "clamp(20px, 4.9vw, 32px)",
  },

  sryTxt: {
    fontSize: "clamp(14px, 3vw, 48px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 0.87)",
  },

  show_msg_lists: {
    display: "flex",
    // height: '50%',
    flexDirection: "column",
  },

  msg_list_detail_box: {
    // backgroundColor: "red",
    padding: "5% 1.5%",
    display: "flex",
    justifyContent: "space-between",
    // gap: '7%',
    alignItems: "center",
    borderTop: "solid 0.3px rgba(112, 112, 112, 0.19)",
  },

  profile_icon_style: {
    // width: "clamp(35px, 4.9vw, 60px)",
    // height: "clamp(35px, 4.9vw, 60px)",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: "50%",
    // objectPosition: 'center',
    // objectFit: 'cover',
    border: "solid 1px rgba(66, 70, 81, 0.9)",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '72.5px',
    height: '54px',
    marginRight: '7%'
  },

  column_spaceBtn: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-between",
    // marginLeft: "7%",
  },

  chat_name_txt: {
    fontSize: "clamp(12px, 1.8vw, 24px)",
    fontWeight: "600",
    color: "rgba(66, 70, 81, 1.87)",
  },

  chat_time_txt: {
    fontSize: "clamp(10px, 1.5vw, 16px)",
    fontWeight: "300",
    color: "rgba(66, 70, 81, 0.4)",
  },

  chat_index_msg_txt: {
    fontSize: "clamp(10px, 1.5vw, 20px)",
    color: "rgba(66, 70, 81, 1.87)",
  },

  unread_count: {
    backgroundColor: "#026b93",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    color: "white",
  },
  //
  //
  //
  //
  //
  //
  //
  //
  //
  //  ==============================    max-width: 767
  //
  //
  //
  //
  //
  //
  //
  //
  //
  "@media (max-width: 767px)": {
    body_container: {
      padding: "2% 0% 0% 2%",
    },
    chat_list_box: {
      width: "100%",
      borderRight: "none",
    },
    // open_chat: {
    //   display: "none",
    // },
    // open_chat_parent_div: {
    //   display: "none",
    // },
    chat_name_txt: {
      fontSize: "clamp(18px, 1.8vw, 24px)",
      fontWeight: "600",
      color: "rgba(66, 70, 81, 1.87)",
    },

    chat_time_txt: {
      fontSize: "clamp(16px, 1.5vw, 16px)",
      fontWeight: "300",
      color: "rgba(66, 70, 81, 0.4)",
    },

    chat_index_msg_txt: {
      fontSize: "clamp(14px, 1.5vw, 20px)",
      color: "rgba(66, 70, 81, 1.87)",
    },
    profile_icon_style: {
      // width: "clamp(45px, 4.9vw, 60px)",
      // height: "clamp(45px, 4.9vw, 60px)",
      width: '60px',
      height: '50px'
    },
  },
}));
